import React from 'react';
import '../../../assets/css/Settings.css'
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import addBtn from '../../../assets/images/AddUp.png'
import minusBtn from '../../../assets/images/Button +@2x.png'
import Checkout from '../../../assets/helpers/checkout'

class AddStorage extends React.Component {
    constructor() {
        super()
        this.state = {
            userId: "",
            CompanyList: [],
            companyOptions: [],
            totalGB: 0,
            CompanyId: null,
            gbPrice: 50
        }
    }

    componentDidMount() {
        let CompanyId = this.props.UserInfo.CompanyId
        let userId = this.props.UserInfo.UserID
        this.setState({
            CompanyId : CompanyId,
            userId : userId
        },()=>{
            this.getCompanyDetails(CompanyId, userId)
        })
    }

    getCompanyDetails = (CompanyId, userId) => {
        this.setState({
            isLoading: true
        }, () => {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let companiesListOptions = []
            let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role
            if (role === Constants.ContentManager || role === Constants.ComposerUser) {
                let obj = {
                    value: CompanyId,
                    label: CompanyName
                }
                companiesListOptions.push(obj)
                this.setState({
                    CompanyList: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                    companyId: CompanyId,
                    userId: userId,
                    companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                    isLoading: false
                })
            } else {
                Axios.get(Constants.BASE_URL + `api/Settings/getAllCompaniesOfUser/${userId}/${CompanyId}`).then((result) => {
                    if (result.data.status) {
                        result.data.info.forEach(element => {
                            let obj = {
                                value: element.Id,
                                label: element.Name
                            }
                            companiesListOptions.push(obj)
                        });
                        this.setState({
                            CompanyList: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                            companyId: CompanyId,
                            userId: userId,
                            companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                            isLoading: false
                        })
                    } else {
                        this.setState({
                            CompanyList: [],
                            companyId: CompanyId,
                            userId: userId,
                            companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                            isLoading: false

                        })
                    }
                })
            }
        })
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    decreaseValue = (key) => {
        let finalValue = this.state[key] - 1
        this.setState({
            [key]: finalValue >= 0 ? finalValue : 0
        })
    }

    appendValue = (key) => {
        let finalValue = this.state[key] + 1
        this.setState({
            [key]: finalValue >= 0 ? finalValue : 0
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="pl-5 pr-5 AddStorage settingsEmailConfig userRegistration settingsUsersList buyLicenses">
                    <div className="row">
                        <div className="col-12">
                            <label className="companyDetails">
                                <span className="selectdiv"><p className="p-0 m-0 companyLable">Company</p>
                                    <select onChange={(e) => this.getEmailConfig(e.target.value)} value={this.state.companyId} placeholder="Select Company">
                                        {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                            return <option key={company.value} id={company.value} value={company.value}>{company.label}</option>
                                        })
                                        )
                                            : null}
                                    </select>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="row userTitle">
                        <div className="col-12">
                            <p className="m-0 userlistlabel">Upgrade Storage</p>
                            <p className="m-0 userlistSublabel">Upgrade your storage plan</p>
                        </div>
                    </div>
                    <div className="row priceingDiv">
                        <div className="col-12 w-100 ">
                            <table className="table w-100">
                                <thead className="text-center">
                                    <tr>
                                        <th className="text-left"></th>
                                        <th className="text-center">Storage</th>
                                        <th className="text-left">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="firstCol">GB</td>
                                        <td>
                                            <label>
                                                <span onClick={() => this.decreaseValue("totalGB")} > <img src={minusBtn} alt="addBtn" /> </span>
                                                <span> <input min={0} type="number" value={this.state.totalGB} onChange={(e) => this.changeValue("totalGB", e.target.value)} />  </span>
                                                <span onClick={() => this.appendValue("totalGB")}> <img src={addBtn} alt="addBtn" /> </span>
                                            </label>
                                        </td>
                                        <td>{this.state.gbPrice}/-</td>
                                    </tr>

                                    {/* <tr className="firstCol">
                                        <td colSpan="1">Duration</td>
                                        <td colSpan="2" className="selectdiv">
                                            <select onChange={(e) => this.changeDuration(e.target.value)} value={this.state.selectedDuration}>
                                                <option value={3}>3 Months</option>
                                                <option value={6}>6 Months</option>
                                                <option value={12}>1 year</option>
                                                <option value={24}>2 Years</option>
                                            </select>
                                        </td>
                                    </tr> */}
                                </tbody>
                                <tfoot>
                                    <tr >
                                        <td className="firstCol" colSpan="1">Total Price</td>
                                        <td colSpan="2" className="text-right">
                                            <p>{this.state.totalGB * this.state.gbPrice} /-</p>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        {this.state.totalGB > 0 ? (
                            <div className="col-12">
                                <Checkout {...this.props} totalGB={this.state.totalGB} companyId={this.state.CompanyId} checkoutType="buyStorage" amount={this.state.totalGB * this.state.gbPrice} BTNtitle="Buy Storage" />
                            </div>
                        ) : null}

                    </div>

                </div>
            </div>
        )
    }
}

export default AddStorage