import React from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import * as Constants from '../../../assets/helpers/constants'
import BaethoLogo from '../../../assets/images/baetho_Logo copy@2x.png'
import LoaderComp from '../../Loader'
import Swal from 'sweetalert2'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            rememberme: false,
            isLoading: false
        }
    }

    changeValue = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }

    submitForm = (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true
        }, () => {
            if (this.state.username.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'UserName is Mandatory',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isLoading: false
                })
                return false
            }

            if (this.state.password.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Password is Mandatory',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isLoading: false
                })
                return false
            }

            let data = {
                username: this.state.username,
                password: this.state.password
            }

            Axios.post(Constants.BASE_URL + "api/Login", data).then((result) => {
                if (result.data.status) {
                    localStorage['userDetails'] = JSON.stringify(result.data.info)
                    localStorage["rememberme"] = this.state.rememberme
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Loggedin Successfully',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.inactivityTime();
                        this.props.history.push('/Contents');
                    })
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Invalid Credentials',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                }
            });
        })
    }

    handleChange = (e) => {
        this.setState({
            rememberme: e.target.checked
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className='text-center baethologo'>
                    <img src={BaethoLogo} alt="BaethoLogo" />
                </div>

                <div className="signin signinLoginPage pl-5">

                    {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                    <p className="loginTitle mb-0">Login</p>
                    <p className="loginsubTitle mb-0">Welcome back! Please login to your account</p>
                    <form className="signin_form" onSubmit={this.submitForm}>
                        <div className="row">
                            <div className="col-12">
                                <input type="email" className="inputfields" placeholder="Email Id" onChange={(e) => this.changeValue("username", e)} required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="password" className="inputfields" placeholder="Password" onChange={(e) => this.changeValue("password", e)} required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 pr-0">
                                <input type="checkbox" id="rememberme" name="rememberme" onChange={e => this.handleChange(e)} />
                                <label htmlFor="rememberme"> Remember me</label>
                            </div>
                            <div className="col-6">
                                <Link to="/Recovery" className="links">
                                    <label>Forget Password</label>
                                </Link>
                            </div>
                        </div>
                        <div className="row submitButtonSection">
                            <div className="col-6">
                                <button type="submit" className="btn LoginButton">Login</button>
                            </div>
                        </div>
                        <p className="formFooter">Term of use. Privacy policy</p>
                    </form>
                </div>
            </div>
        );
    }
}

export default Login