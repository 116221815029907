import React from 'react'
import Axios from 'axios'
import { Button } from 'react-bootstrap'
import * as Constants from '../../assets/helpers/constants'
import Swal from 'sweetalert2'

class PostDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            postId: props.match.params.id,
            isEditable: false,
            isCommentSection: false,
            isdeletable: false,
            postDetails: {},
            tags: "",
            categories: "",
            commentDetails: "",
            comments: [],
            userName: "",
            userId: "",
            Solution_Id: 0,
            isAdmin: false,
            isOwner: false
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            const postId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
            let admin = false
            if (this.props.UserInfo.Role === 'Admin') {
                admin = true
            }
            this.setState({
                userName: this.props.UserInfo.Name,
                userId: this.props.UserInfo.UserID,
                isAdmin: admin,
                postId: postId
            }, () => {
                this.getComments();
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getComments = () => {
        Axios.get(Constants.BASE_URL + `api/ForumDashboard/PostDetails/${this.state.postId}`).then((result) => {
            if (result.data.length > 0) {
                const postId = result.data[0]
                const solutionId = result.data[0].Solution_Id
                let tagDetails = JSON.parse(result.data[0].Tags)
                let tags = ""
                tagDetails.forEach(element => {
                    tags = tags + element.label + ","
                });

                let categories = ""
                let categoryDetails = JSON.parse(result.data[0].Category)
                categoryDetails.forEach(element => {
                    categories = tags + element.label + ","
                });
                this.setState({
                    postDetails: postId,
                    Solution_Id: solutionId,
                    tags: tags,
                    categories: categories
                })

            }
        })
        Axios.get(Constants.BASE_URL + `api/ForumDashboard/getComments/${this.state.postId}`).then((result) => {
            this.setState({
                comments: result.data,
            })
        })
    }

    editPost = () => {
        this.setState({
            isEditable: true
        })
    }

    addComment = () => {
        this.setState({
            isCommentSection: true
        })
    }

    setComments = (e) => {
        this.setState({
            commentDetails: e.target.value
        })
    }

    submitComment = () => {
        let data = {
            UserId: this.state.userId,
            PostId: this.state.postId,
            Content: this.state.commentDetails
        }

        Axios.post(Constants.BASE_URL + "api/ForumDashboard/insertComment", data).then((result) => {
            if (result.status) {
                this.getComments()
            }
        });

        const commentId = this.state.comments.length > 0 ? (Number)(this.state.comments[0].Comment_Id) + 1 : 1
        this.setState((prevState, props) => ({
            comments: [{
                Content: this.state.commentDetails,
                Created_On: Date().toLocaleString(),
                Name: this.state.userName,
                Comment_Id: commentId
            }, ...prevState.comments],
            isCommentSection: false,
            commentDetails: ""
        }));
    }

    deletePost = () => {
        let location = this.props.location.pathname.split("/")
        let path = location[2]
        let finallocation = location.splice(0, 3).join("/")
        Axios.delete(Constants.BASE_URL + `api/ForumDashboard/deletePost/${this.state.postId}`).then((result) => {
            if (result.data.status) {
                if (path === "MyPosts") {
                    this.props.getMyPosts()
                } else {
                    this.props.getAllPosts()
                }
                this.props.history.push(finallocation)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong. Please try again',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        });
    }

    deleteComment = (id) => {
        Axios.delete(Constants.BASE_URL + `api/ForumDashboard/deleteComment/${id}`).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Comment Deleted successfully',
                    showConfirmButton: false,
                    timer: 1000
                })
                // alert("Comment Deleted successfully")
                this.getComments()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong. Please try again',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        });;
    }

    setPreferedSolution = (commentId) => {
        let data = {
            commentId: commentId,
            postID: this.state.postId
        }

        Axios.put(Constants.BASE_URL + `api/ForumDashboard/setPreferedSolution`, data).then((result) => {
            if (result.status) {
                this.getComments()
            }
        });
    }

    render() {
        let commentSection = ""
        if (this.state.isCommentSection) {
            commentSection = (
                <div className="newCommnetBox">
                    <textarea type="text" className="w-100" onChange={(e) => this.setComments(e)} placeholder='Add New Comment'></textarea>
                    <Button variant="success" onClick={this.submitComment} size="sm">Submit Comment</Button>
                </div>
            );
        }

        return (
            <div className="text-left">
                <div className="row postDetailsDiv" >
                    <div className="col">
                        <p className="topic">{this.state.postDetails.Topic}</p>
                        <p className="detailsInfo">{this.state.postDetails.Content}</p>
                        <p className="detailsInfo"><label>Tags : <span>{this.state.tags}</span></label></p>
                        <p className="detailsInfo"><label>Categories : <span>{this.state.categories}</span></label></p>
                        <p className="detailsInfo">Created By : {this.state.postDetails.Name ? this.state.postDetails.Name : " "}</p>
                        <p className="detailsInfo">Comments :</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 commentBoxDiv">
                        {
                            this.state.comments && this.state.comments.filter(comment => comment.Comment_Id === this.state.Solution_Id).map((comment, index) => {
                                return (<div key={index} className="row commentBox">
                                    <div className="col-12 prefSolution">
                                        <p className="preferredSol"><span>Preferred</span></p>
                                        <p className="commentContent">{comment.Content}</p>
                                        <p className="commentedBy">Comment by: {comment.UserName}</p>
                                    </div>
                                </div>)
                            })
                        }
                        {this.state.comments.length > 0 ? this.state.comments.map((comment, index) => {

                            return comment.Comment_Id !== this.state.Solution_Id ? (<div key={index} className="row commentBox">
                                <div className="col-12">
                                    <p>
                                        {(this.state.isAdmin) ? (<i class="fa fa-check setpreferredSol" aria-hidden="true" onClick={() => this.setPreferedSolution(comment.Comment_Id)} title="Set as prefered solution"></i>) : null}
                                        {this.state.isAdmin ? (<i class="fa fa-times deletePost" aria-hidden="true" onClick={() => this.deleteComment(comment.Comment_Id)} title="Delete Comment"></i>) : null}
                                    </p>
                                    <p className="commentContent">{comment.Content}</p>
                                    <p className="commentedBy">Comment by: {comment.UserName}</p>
                                </div>
                            </div>) : null
                        }) : null}
                    </div>
                </div>
                {commentSection}
                <div className="row actionButtons">
                    <div className="col-6 text-center">
                        <Button variant="danger actionBtns" onClick={this.deletePost} size="sm">Delete Post</Button>
                    </div>
                    <div className="col-6 text-center">
                        <Button variant="success actionBtns" onClick={this.addComment} size="sm">Add Comment</Button>
                    </div>
                </div>




            </div>);
    }
}

export default PostDetails