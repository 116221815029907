import React from 'react'
import DataTable from 'react-data-table-component';
import Axios from 'axios'
import * as Constants from '../../assets/helpers/constants'
import Swal from 'sweetalert2'

class AllPosts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            postsList: props.allPostsList,
            pathName: null,
            columns: [
                {
                    name: 'Created By',
                    grow: 2,
                    center: 1,
                    cell: (row, index) => <div data-tag="allowRowEvents" className="devicesSlNo text-center">{row.UserName}</div>,
                    sortable: false,
                },
                {
                    name: 'Topic',
                    grow: 2,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="osIcon text-center">{row.Topic}</div>,
                    sortable: true
                },
                {
                    name: 'Tags ',
                    grow: 2,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="devicesName">{(JSON.parse(row.Tags)).map((value, index) => {
                        return <label key={index}>{value.label} ,</label>
                    })}</div>,
                    selector: 'Tags',
                    sortable: true
                },
                {
                    name: 'Category',
                    grow: 2,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="devicesStatus text-center">{(JSON.parse(row.Category)).map((value, index) => {
                        return <label key={index}>{value.label} ,</label>
                    })}</div>,
                    selector: 'Category',
                    sortable: true
                },
                {
                    name: 'Description',
                    grow: 3,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="devicesExpName text-center">{row.Content}</div>,
                    selector: 'Content',
                    sortable: false
                },
                {
                    name: 'Action',
                    grow: 1,
                    center: 1,
                    cell: (row) => <button type="button" className="viewButton" onClick={() => this.handleAction(row.Post_Id)}>View</button>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                },
            ],
            isAdmin: false
        }
    }

    componentDidMount() {
        if (localStorage.getItem("userDetails") !== null) {
            let isAdmin = false
            let userDetails = JSON.parse(localStorage.getItem("userDetails"))
            if (userDetails[0].Role === 'Admin') {
                isAdmin = true
            }
            this.props.getAllPosts()
            this.setState({
                isAdmin: isAdmin,
                pathName: this.props.location.pathname
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    deletePost = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            // text: "You won't be able to revert this!",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                Axios.delete(Constants.BASE_URL + `api/ForumDashboard/deletePost/${id}`).then((result) => {
                    if (result.data.status) {
                        Swal.fire(
                            'Deleted!',
                            'Post Deleted Sucessfully',
                            'success'
                        )
                        this.props.getAllPosts()
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Something went wrong. Please try again',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    }
                });
               
            }
        })

    }

    handleAction = (id) => {
        this.props.history.push(this.state.pathName + "/Details/" + id)
    }

    render() {

        return (
            <div className="allposts">
                <div className="row AllpostsTitle">
                    <div className="col-6">
                        <p className="pageTitle">All Posts </p>
                    </div>
                    <div className="col-6">

                    </div>
                </div>


                <input className="searchBar" onChange={(e) => { this.props.searchAllPostsText(e) }} placeholder="Search keyword" />
                {this.props.allPostsList.length !== 0 ?
                    <DataTable
                        columns={this.state.columns}
                        data={this.props.allPostsList}
                        progressPending={this.props.allPostsList.length !== 0 ? false : true}
                        pagination={true}
                    /> : null
                }
            </div>)
    }
}

export default AllPosts;