import React from 'react'
import { Link } from 'react-router-dom'
import * as Constants from '../../../../assets/helpers/constants'
import LoaderComp from '../../../Loader'
import Swal from 'sweetalert2'
import Axios from 'axios';

import BackIcon from '../../../../assets/images/Untitled-1-10.png'
import VideoIcon from '../../../../assets/images/Group 452.png'

export default class Tutorials extends React.Component {
    constructor() {
        super()
        this.state = {
            tutorials: [],
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getTutorials()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getTutorials = () => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/MarketPlace/getTutorials`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        tutorials: result.data.info,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Error in fetching details.Please try later",
                            showConfirmButton: false,
                            timer: 3000
                        })
                    })
                }
            })
        })
    }

    secondsToDhms = (seconds) => {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor(seconds % (3600 * 24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
        var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
        return dDisplay + hDisplay + mDisplay;
    }


    render() {
        return (
            <div className="tutorialsDashboard">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="row pl-3">
                    <div className="col-1">
                        <Link to={Constants.MarketPlace} onClick={() => this.props.setLink(null)}><img src={BackIcon} alt="BackIcon" className="backIcon" /><p className="backBtnText">Back</p></Link>
                    </div>
                </div>
                <div className="row tutorialsContentDiv pl-3">
                    {this.state.tutorials.length > 0
                        ? (this.state.tutorials.map((tutorial, index) => {
                            let currentDate = new Date()
                            let createdDate = new Date(tutorial.Created_On)
                            let time = Math.ceil(Math.abs(currentDate - createdDate) / (1000))
                            return (
                                <div className="col-3 mt-4" key={index} title={tutorial.Title}>
                                    <a href={tutorial.Link} target="_blank" rel="noopener noreferrer">
                                        <div className="videos">
                                            <img className="mainImg" src={tutorial.Thumbnail} alt="ProjectIcons" />
                                            <img className="videoOverlay" src={VideoIcon} alt="VideoIcon" />
                                        </div>
                                        <div className="inforamtionDiv mt-2">
                                            <p className="title p-0 m-0">{tutorial.Title}</p>
                                            <p className="p-0 m-0">
                                                <span className="Views">{tutorial.Views} Views |</span>
                                                <span className="Duration"> {this.secondsToDhms(time)} ago</span>
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            )
                        }))
                        : null
                    }
                </div>
            </div>
        )
    }
}