// export const BASE_URL = 'http://localhost:8080/' // Local Server 
// export const BASE_URL = 'http://122.248.229.117:8080/' // Test Server URl
export const BASE_URL = 'https://contentbaetho.com/' // Production Server URl

export const BASE_MEDIA_FOLDER = '/assets/media'
export const InitialPath = '/'
export const Login = '/Login'
export const Recovery = '/Recovery'
export const ResetPassword = '/ResetPassword'
export const PartnerRegistration = '/PartnerRegistration'
export const Contents = '/Contents'
export const MainDashboard = '/Contents/DashBoard'

// Experience Dashboard
export const ExperienceDashboard = '/Contents/ExperienceDashboard'
export const AddExperience = '/Contents/ExperienceDashboard/AddExperience'
export const DeviceList = '/Contents/ExperienceDashboard/DeviceList'
export const UpdateExperience = '/Contents/ExperienceDashboard/UpdateExperience'
export const MediaManagement = '/Contents/MediaManagement'

// License Dashboard
export const LicenseDashboard = '/Contents/LicenseDashboard'
export const LicenseDetails = '/Contents/LicenseDashboard/LicenceDetails'
export const BuyLicenses = '/Contents/LicenseDashboard/BuyLicenses'

// Device Management
export const DeviceDashboard = '/Contents/DeviceDashboard'
export const IndividualDeviceDetails = '/Contents/DeviceDashboard/DeviceDetails'

// Settings Dashboard
export const SettingsDashboard = '/Contents/SettingsDashboard'
export const SettingsUserManagement = '/Contents/SettingsDashboard/UserManagement'
export const SettingsEmailConfig = '/Contents/SettingsDashboard/EmailConfig'
export const SettingsUsageReports = '/Contents/SettingsDashboard/UsageReports'
export const SettingsSoftwareRelease = '/Contents/SettingsDashboard/SoftwareRelease'
export const SettingsUploadTutorials = '/Contents/SettingsDashboard/UploadTutorials'
export const SettingsAddStorage = '/Contents/SettingsDashboard/AddStorage'
export const SettingsChangePassword = '/Contents/SettingsDashboard/ChangePassword'

// File Manager
export const FileManager = '/Contents/FileManager'
export const PreviewFile = '/Contents/FileManager/PreviewFile'
export const UploadFiles = '/Contents/FileManager/FileUploads'

// Market Place 
export const MarketPlace = '/Contents/MarketPlace'
export const DownloadComposer = '/Contents/MarketPlace/DownloadComposer'
export const Discover = '/Contents/MarketPlace/Discover'
export const MyProjects = '/Contents/MarketPlace/MyProjects'
export const People = '/Contents/MarketPlace/People'
export const Tutorials = '/Contents/MarketPlace/Tutorials'
export const UploadNew = '/Contents/MarketPlace/UploadNew'
export const MarketPlaceUserDetails = '/Contents/MarketPlace/UserDetails'
export const DownloadComposerView = '/Contents/MarketPlace/DownloadComposer'


/// Admin Dashboard Constants
export const AdminDashboard = '/Admin/DashBoard'
export const AdminContents = '/Admin'

export const AdminUserManagementPanel = '/Admin/DashBoard/UserManagement'
export const AdminUserCreation = '/Admin/DashBoard/CreateUser'
export const AdminCompanyDetails = '/Admin/DashBoard/CompanyDetails'
export const AdminCompanysUsersList = '/Admin/DashBoard/CompanyDetails/UsersList'
export const AdminCompanysLicenseIssue = '/Admin/DashBoard/CompanyDetails/IssueLicenses'
export const AdminCompanysSoftwareRelease = '/Admin/DashBoard/CompanyDetails/ReleaseSoftwares'

export const AdminUsageReportsPanel = '/Admin/DashBoard/Reports'
export const AdminSoftwareReleasePanel = '/Admin/DashBoard/SoftwareRelease'

// Admin license Dashboard
export const AdminLicenseDashboard = '/Admin/LicenseDashboard'

// Forum Dashboard Constants
export const Forum = '/Forum'
export const AllPosts = '/Forum/AllPosts'
export const MyPosts = '/Forum/MyPosts'
export const CreatePost = '/Forum/CreatePost'

/// Internal Variables
export const Admin = 'Admin'
export const ContentManager = 'ContentManager'
export const Reseller = 'Reseller'
export const ComposerUser = 'ComposerUser'

// RazorPay key
export const Key = 'rzp_test_29V5QKQjJEuTpb'
