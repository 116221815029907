import React from 'react';
import '../../../assets/css/Settings.css'
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'

class EmailConfig extends React.Component {
    constructor() {
        super()
        this.state = {
            email: "",
            smtpHost: "",
            smtpPort: "",
            password: "",
            isNewConfig: false,
            configId: null,
            userId: "",
            CompanyList: [],
            companyOptions: []
        }
    }

    componentDidMount() {
        let CompanyId = this.props.UserInfo.CompanyId
        let userId = this.props.UserInfo.UserID
        this.getEmailConfig(CompanyId)
        this.getCompanyDetails(CompanyId, userId)
    }

    getCompanyDetails = (CompanyId, userId) => {
        this.setState({
            isLoading: true
        }, () => {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let companiesListOptions = []
            let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role
            if (role === Constants.ContentManager || role === Constants.ComposerUser) {
                let obj = {
                    value: CompanyId,
                    label: CompanyName
                }
                companiesListOptions.push(obj)
                this.setState({
                    CompanyList: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                    companyId: CompanyId,
                    userId: userId,
                    companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                    isLoading: false
                })
            } else {
                Axios.get(Constants.BASE_URL + `api/Settings/getAllCompaniesOfUser/${userId}/${CompanyId}`).then((result) => {
                    if (result.data.status) {
                        result.data.info.forEach(element => {
                            let obj = {
                                value: element.Id,
                                label: element.Name
                            }
                            companiesListOptions.push(obj)
                        });
                        this.setState({
                            CompanyList: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                            companyId: CompanyId,
                            userId: userId,
                            companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                            isLoading: false
                        })
                    } else {
                        this.setState({
                            CompanyList: [],
                            companyId: CompanyId,
                            userId: userId,
                            companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                            isLoading: false

                        })
                    }
                })
            }
        })
    }

    getEmailConfig(CompanyId) {
        Axios.get(Constants.BASE_URL + `api/Settings/getEmailConfigurations/${CompanyId}`).then((result) => {
            if (result.data.info.length === 0) {
                this.setState({
                    isNewConfig: true,
                    companyId: CompanyId,
                    email: "",
                    smtpHost: "",
                    smtpPort: "",
                    password: "",
                })
            } else {
                var data = result.data.info[0]
                this.setState({
                    email: data.Username,
                    smtpHost: data.SMTP_Host,
                    smtpPort: data.SMTP_Port,
                    password: data.Password,
                    isNewConfig: false,
                    configId: data.Id,
                    companyId: CompanyId,
                })
            }
        })
    }

    submitEmailConfig = (event) => {
        event.preventDefault();

        if (this.state.email === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please enter email id",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }
        if (this.state.password === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please enter Password",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }
        if (this.state.smtpHost === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please enter SMTP Host",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }
        if (this.state.smtpPort === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please enter SMTP Port",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        let data = {
            email: this.state.email,
            smtpHost: this.state.smtpHost,
            smtpPort: this.state.smtpPort,
            password: this.state.password,
            companyId: this.state.companyId,
            isNewConfig: this.state.isNewConfig,
            userId: this.state.userId,
            configId: this.state.configId,
        }
        Axios.post(Constants.BASE_URL + `api/Settings/submitEmailConfigurations`, data).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Email Configuration updated sucessfully",
                    showConfirmButton: false,
                    timer: 1000
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Someting went wrong. Please try again !!",
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        })
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="pl-5 pr-5 settingsEmailConfig userRegistration settingsUsersList">
                    <div className="row">
                        <div className="col-12">
                            <label className="companyDetails">
                                <span className="selectdiv"><p className="p-0 m-0 companyLable">Company</p>
                                    <select onChange={(e) => this.getEmailConfig(e.target.value)} value={this.state.companyId} placeholder="Select Company">
                                        {/* <option id="Select Company" disabled value="SelectCompany" > Select Company </option> */}
                                        {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                            return <option key={company.value} id={company.value} value={company.value}>{company.label}</option>
                                        })
                                        )
                                            : null}
                                    </select>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="row userTitle">
                        <div className="col-12">
                            <p className="m-0 userlistlabel">Email Configuration</p>
                            <p className="m-0 userlistSublabel">Manage email settings</p>
                        </div>
                    </div>

                    <div className="row">
                        <form className="w-100" id="emailConfigForm" onSubmit={this.submitEmailConfig}>
                            <div className="col-12">
                                <input type="email" placeholder="Email" value={this.state.email} onChange={(e) => this.changeValue("email", e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <input type="password" placeholder="Password" value={this.state.password} onChange={(e) => this.changeValue("password", e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <input type="text" placeholder="SMTP host" value={this.state.smtpHost} onChange={(e) => this.changeValue("smtpHost", e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <input type="text" placeholder="SMTP Port" value={this.state.smtpPort} onChange={(e) => this.changeValue("smtpPort", e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <button type="submit" className="addUserSubmitBtn" >Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmailConfig