import React from 'react';
import Axios from 'axios';
import * as Constants from '../../assets/helpers/constants'
import Swal from 'sweetalert2'

class Checkout extends React.Component {

    buyStorage = () => {
        let props = this.props
        let name = JSON.parse(localStorage.getItem("userDetails"))[0].Name
        let email = JSON.parse(localStorage.getItem("userDetails"))[0].Email
        // let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID

        let options = {
            "key": Constants.Key,
            "amount": this.props.amount * 100,
            "name": name,
            "description": "Storage Purchase",
            "image": "https://media.baetho.com/Images/emaillogo.png",
            "handler": function (response) {
                let data = {
                    companyId: props.companyId,
                    capacity: props.totalGB
                    // createdBy: userID,
                    // paymentId: response.razorpay_payment_id,
                    // totalPayment: props.amount,
                }
                Axios.post(Constants.BASE_URL + `api/MediaManagement/upgradeStorage`, data).then((result) => {
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Successfully Updated the Storage",
                            showConfirmButton: false,
                            timer: 1000
                        })
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Something went wrong.Please try later!',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    }
                    props.history.push(Constants.SettingsAddStorage)
                });
            },
            "prefill": {
                "name": name,
                "email": email
            },
            "notes": {
                "address": "Storage Purchase : <br />  Total GB Purchased :  " + this.props.totalGB + " <br/> Total amount : " + this.props.amount * 100
            },
            "theme": {
                "color": "#88c44b"
            }
        };

        let rzp = new window.Razorpay(options);
        rzp.on('payment.failed', function (response) {
            console.log("failed")
        });
        rzp.open();
    }

    buyLicenses = () => {
        let props = this.props
        let options = {
            "key": Constants.Key,
            "amount": this.props.amount * 100,
            "name": this.props.name,
            "description": "Licence Purchase",
            "image": "https://media.baetho.com/Images/emaillogo.png",
            "handler": function (response) {
                let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
                let data = {
                    composerLicenses: props.composerLicences,
                    playerLicenses: props.playerLicences,
                    companyId: props.companyId,
                    createdBy: userID,
                    duration: props.duration,
                    paymentId: response.razorpay_payment_id,
                    totalPayment: props.amount,
                    isTransferable: props.isTransferable,
                    isInteractive: props.isInteractive
                }
                Axios.post(Constants.BASE_URL + `api/AdminDashboard/issueCompanyLicenses`, data).then((result) => {
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: result.data.info,
                            showConfirmButton: false,
                            timer: 1000
                        })
                        props.getLicences()
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Something went wrong.Licences cannot be created.',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        props.getLicences()

                    }
                });
            },
            "prefill": {
                "name": this.props.name,
                "email": this.props.email
            },
            "notes": {
                "address": "Licence Purchase  : <br />  Player Licences :  " + this.props.playerLicences + " <br /> Composer Licences : " + this.props.composerLicences + "<br/> Total amount : " + this.props.amount * 100
            },
            "theme": {
                "color": "#88c44b"
            }
        };

        let rzp = new window.Razorpay(options);
        rzp.on('payment.failed', function (response) {
            console.log("failed")
        });
        rzp.open();
    }

    openCheckout = () => {
        let props = this.props
        let options = {
            "key": Constants.Key,
            "amount": this.props.amount * 100,
            "name": this.props.name,
            "description": "Licence Purchase",
            "image": "https://media.baetho.com/Images/emaillogo.png",
            "handler": function (response) {
                let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
                let data = {
                    licenseDetails: props.licenceDetails,
                    userId: userID,
                    duration: props.duration,
                    paymentId: response.razorpay_payment_id,
                    totalPayment: props.amount,
                    totalPlayerLicences: props.playerLicences,
                    totalComposerLicences: props.composerLicences
                }
                Axios.post(Constants.BASE_URL + `api/LicenseManagement/renewLicense`, data).then((result) => {
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Renewed Sucessfully',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        props.getLicences()
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Unable to do Transaction. Please try after sometime',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    }
                });
            },
            "prefill": {
                "name": this.props.name,
                "email": this.props.email
            },
            "notes": {
                "address": "Licence Purchase  : <br />  Player Licences :  " + this.props.playerLicences + " <br /> Composer Licences : " + this.props.composerLicences + "<br/> Total amount : " + this.props.amount * 100
            },
            "theme": {
                "color": "#88c44b"
            }
        };

        let rzp = new window.Razorpay(options);
        rzp.open();
    }

    render() {
        return (
            <div id="mydiv">
                <div>
                    {
                        this.props.checkoutType === "buyLicense" ?
                            <button className="proceedBtn" type="button" onClick={this.buyLicenses}>{this.props.BTNtitle}</button> :
                            this.props.checkoutType === "buyStorage" ? <button className="proceedBtn" type="button" onClick={this.buyStorage}>{this.props.BTNtitle}</button> :
                                <button className="proceedBtn" type="button" onClick={this.openCheckout}>{this.props.BTNtitle}</button>
                    }
                </div>
            </div>
        )
    }
}

export default Checkout
