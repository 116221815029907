import React from 'react';
import { Link } from "react-router-dom";
import * as Constants from '../../../assets/helpers/constants'
import '../../../assets/css/LeftSidePanel.css'
import logoImg from '../../../assets/images/logo.png';
import LogoutButton from '../../../assets/images/LogoutDown.png'

import EnquiriesUp from '../../../assets/images/enquiries(1).png'
import EnquiriesDown from '../../../assets/images/enquiries.png'
import ExpUp from '../../../assets/images/users(1).png'
import ExpDown from '../../../assets/images/users.png'
import LicenseUp from '../../../assets/images/appointments(1).png'
import LicenseDown from '../../../assets/images/appointments.png'
import FileUp from '../../../assets/images/Group 443(1).png'
import FileDown from '../../../assets/images/Group 443.png'
import MarketPlaceUp from '../../../assets/images/Group 442(1).png'
import MarketPlaceDown from '../../../assets/images/Group 442.png'


import MenuDown from '../../../assets/images/MenuDown.png'
import MenuUp from '../../../assets/images/MenuUp.png'
import Swal from 'sweetalert2'

class LeftSidePanel extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoggedIn: false,
            activeLink: 'dashboard'
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.setState({
                isLoggedIn: true,
            })
        }
    }

    logOut = () => {
        Swal.fire({
            title: "Are you sure you want to logout",
            // text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('userDetails')
                localStorage.removeItem('rememberme')
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Logged Out Successfully',
                    showConfirmButton: false,
                    timer: 3000
                })
                this.props.history.push('/')
            }
        })
    }

    setActive = (id) => {
        this.props.checkPath(id)
    }

    render() {
        return (
            <div className="leftSidePanel text-center">
                <img src={logoImg} alt="logo" className="logoImg" />
                <div className="navBar">
                    <div className="navIcons">
                        <ul tabIndex="-1">
                            <li tabIndex="-1">
                                <Link tabIndex="-1" to={Constants.MainDashboard} className={this.props.activeLink === 'dashboard' ? "disabled-link" : null}>
                                    {this.props.activeLink === 'dashboard' ? <img tabIndex="-1" src={MenuUp} className="active" alt="EnquiriesUp" /> : <img tabIndex="-1" src={MenuDown} alt="EnquiriesDown" onClick={(e) => { this.setActive("dashboard") }} />}
                                </Link>
                            </li>
                            <li tabIndex="-1">
                                <Link tabIndex="-1" to={Constants.ExperienceDashboard} className={this.props.activeLink === 'experience' ? "disabled-link" : null}>
                                    {this.props.activeLink === 'experience' ? <img tabIndex="-1" src={ExpUp} className="active" alt="EnquiriesUp" /> : <img tabIndex="-1" src={ExpDown} alt="EnquiriesDown" onClick={(e) => { this.setActive("experience") }} />}
                                </Link>
                            </li>
                            {this.props.UserInfo.Role !== "ComposerUser" ? (
                                <li tabIndex="-1">
                                    <Link tabIndex="-1" to={Constants.LicenseDashboard} className={this.props.activeLink === 'license' ? "disabled-link" : null}>
                                        {this.props.activeLink === 'license' ? <img tabIndex="-1" src={LicenseUp} className="active" alt="EnquiriesUp" /> : <img tabIndex="-1" src={LicenseDown} alt="EnquiriesDown" onClick={(e) => { this.setActive("license") }} />}
                                    </Link>
                                </li>)
                                : null}
                            {this.props.UserInfo.Role !== "ComposerUser" ? (
                                <li tabIndex="-1">
                                    <Link tabIndex="-1" to={Constants.DeviceDashboard} className={this.props.activeLink === 'device' ? "disabled-link" : null}>
                                        {this.props.activeLink === 'device' ? <img tabIndex="-1" src={EnquiriesUp} className="active" alt="EnquiriesUp" /> : <img tabIndex="-1" src={EnquiriesDown} alt="EnquiriesDown" onClick={(e) => { this.setActive("device") }} />}
                                    </Link>
                                </li>
                            ) : null}
                            <li tabIndex="-1">
                                <Link tabIndex="-1" to={Constants.FileManager} className={this.props.activeLink === 'filemanager' ? "disabled-link" : null}>
                                    {this.props.activeLink === 'filemanager' ? <img tabIndex="-1" src={FileUp} className="active" alt="EnquiriesUp" /> : <img tabIndex="-1" src={FileDown} alt="EnquiriesDown" onClick={(e) => { this.setActive("filemanager") }} />}
                                </Link>
                            </li>

                            {this.props.UserInfo.Role !== "ComposerUser" ? (
                                <li tabIndex="-1">
                                    <Link tabIndex="-1" to={Constants.SettingsDashboard} className={this.props.activeLink === 'settings' ? "disabled-link" : null}>
                                        <i tabIndex="-1" id="settings" className={this.props.activeLink === 'settings' ? "fas fa-cogs navLinks active" : "fas fa-cogs navLinks"} onClick={(e) => { this.setActive("settings") }}></i>
                                    </Link>
                                </li>
                            ) : null}

                            {/* Market Place */}
                            {(this.props.UserInfo.Role === "ComposerUser") ? (
                                <li tabIndex="-1">
                                    <Link tabIndex="-1" to={Constants.MarketPlace} className={this.props.activeLink === 'marketplace' ? "disabled-link" : null}>
                                        {this.props.activeLink === 'marketplace' ? <img tabIndex="-1" src={MarketPlaceUp} className="active" alt="EnquiriesUp" /> : <img tabIndex="-1" src={MarketPlaceDown} alt="EnquiriesDown" onClick={(e) => { this.setActive("marketplace") }} />}
                                    </Link>
                                </li>
                            ) : null}

                            {/* Forum Dashboard */}
                            {(this.props.UserInfo.Role === "Admin" || this.props.UserInfo.Role === "ComposerUser") ? (
                                <li tabIndex="-1">
                                    <Link to={Constants.AllPosts} tabIndex="-1">
                                        <i id="forum" className="fab fa-forumbee" tabIndex="-1"></i>
                                    </Link>
                                </li>
                            ) : null}

                            {/* Help */}
                            {/* {(this.props.UserInfo.Role === "ComposerUser") ? (
                                <li>
                                    <Link to={Constants.AllPosts}>
                                        <i className="far fa-question-circle"></i>
                                    </Link>
                                </li>
                            ) : null} */}

                        </ul>
                    </div>
                    {this.state.isLoggedIn ? (<img src={LogoutButton} alt="logout" className="logoutButton" onClick={() => this.logOut()} />) : null}
                </div>
            </div>
        )
    }
}

export default LeftSidePanel