import React from 'react'
import Axios from 'axios';
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import '../../../assets/css/LicenseManagement.css'
import leftArrow from '../../../assets/images/Untitled-1-10.png'
import customerDown from '../../../assets/images/CustomerDown.png'
import Swal from 'sweetalert2'

class AdminCompanyDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            totalUsers: 0,
            isLoading: false,
            totalLicenses: 0,
            activeLicenses: 0,
            notActiveLicenses: 0,
            companyId: null,
            companyDetails: [],
            ComposerVersion: null,
            PlayerVersion: null
        }
    }
    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            const companyId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
            this.getCompanyRelatedInfo(companyId)
            this.getCompanyDetails(companyId)
        } else {
            this.props.history.push(Constants.Login)
        }

    }

    getCompanyRelatedInfo = (companyId) => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/AdminDashboard/getCompanyRelatedDetails/${companyId}`).then((result) => {
                if (result.data.status) {
                    let activeLicensesArray = result.data.info.totalLicenses.filter(obj => {
                        return obj.Is_Active === 1
                    })
                    let notactiveLicensesArray = result.data.info.totalLicenses.filter(obj => {
                        return obj.Is_Active !== 1
                    })
                    this.setState({
                        totalUsers: result.data.info.totalUsers,
                        totalLicenses: result.data.info.totalLicenses.length,
                        activeLicenses: activeLicensesArray.length,
                        notActiveLicenses: notactiveLicensesArray.length,
                        companyId: companyId,
                        ComposerVersion: result.data.info.playerVersion,
                        PlayerVersion: result.data.info.composerVersion,
                        isLoading: false

                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
        })

    }

    getCompanyDetails = (companyId) => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/AdminDashboard/getCompanyDetails/${companyId}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        companyId: companyId,
                        companyDetails: result.data.info,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        companyId: companyId,
                        isLoading: false
                    })
                }
            });
        })

    }

    activateDeactivateCompany = (action) => {
        Swal.fire({
            title: 'Are you sure you want to deactivate company?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'DeActivate'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    isLoading: true
                }, () => {
                    let data = {
                        companyId: this.state.companyId,
                        requiredAction: action
                    }
                    Axios.put(Constants.BASE_URL + `api/AdminDashboard/activate_deactivate_Company`, data).then((result) => {
                        if (result.data.status) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: result.data.data,
                                showConfirmButton: false,
                                timer: 1000
                            })
                            this.getCompanyDetails(this.state.companyId)
                        } else {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: result.data.data,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.getCompanyDetails(this.state.companyId)
                        }
                    });
                    this.setState({
                        isLoading: false
                    })
                })
            }
        }
        )
    }

    navigate = (link) => {
        this.props.history.push(link + "/" + this.state.companyId)
    }

    navigateBack = (link) => {
        this.props.history.push(link)
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="pl-5 pr-5 reports companyDetailsAdmin">
                    {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                    <div className="row">
                        <div className="col-12 p-0">
                            <label className="searchDiv"><i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                <input type="text" placeholder="Search keyword" />
                            </label>
                        </div>
                    </div>
                    <div className="row userTitle ">
                        <div className="col-12 pb-2">
                            <label className="backButton" onClick={() => this.navigateBack(Constants.AdminUserManagementPanel)}><img src={leftArrow} alt="leftArrow" /> <span> BACK </span></label>
                        </div>
                        <div className="col-12">
                            <p className="m-0 userlistlabel">Company Details</p>
                            <p className="m-0 userlistSublabel">All details related to the company</p>
                        </div>
                    </div>

                    <div className="row companyDetailsSection">
                        <div className="col-2 customerIcon">
                            <img src={customerDown} alt="customerDown" />
                        </div>
                        <div className="col-10 p-0">
                            <div>
                                <label className='w-100 companyDetailsDiv ml-2'>
                                    <span className="adminCompanyName">{this.state.companyDetails.length > 0 ? this.state.companyDetails[0].Name : null}</span>
                                    <span className="deactivateBtn">{this.state.companyDetails.length > 0 ? this.state.companyDetails[0].Is_Active ? <span onClick={() => this.activateDeactivateCompany(false)}>Deactivate</span> : <span onClick={() => this.activateDeactivateCompany(true)}>Activate</span> : null}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row keyInfo companyOverallInfo">
                        <div className="col-12 w-100 keydetailsDiv">
                            <label className="w-100 ">
                                <span className="keyname">Total Users</span>
                                <span className="keyvalue">{this.state.totalUsers}</span>
                            </label>
                        </div>
                        <div className="col-12 w-100 keydetailsDiv">
                            <label className="w-100 ">
                                <span className="keyname">Total Licenses Issued</span>
                                <span className="keyvalue">{this.state.totalLicenses}</span>
                            </label>
                        </div>
                        <div className="col-12 w-100 keydetailsDiv">
                            <label className="w-100 ">
                                <span className="keyname">Active Licenses</span>
                                <span className="keyvalue">{this.state.activeLicenses}</span>
                            </label>
                        </div>
                        <div className="col-12 w-100 keydetailsDiv">
                            <label className="w-100 ">
                                <span className="keyname">Not-Active Licenses</span>
                                <span className="keyvalue">{this.state.notActiveLicenses}</span>
                            </label>
                        </div>
                        <div className="col-12 w-100 keydetailsDiv">
                            <label className="w-100 ">
                                <span className="keyname">Storage</span>
                                <span className="keyvalue">1GB of 2GB</span>
                            </label>
                        </div>
                        <div className="col-12 w-100 keydetailsDiv">
                            <label className="w-100 ">
                                <span className="keyname">Composer Issued</span>
                                <span className="keyvalue">Version {this.state.ComposerVersion}</span>
                            </label>
                        </div>
                        <div className="col-12 w-100 keydetailsDiv">
                            <label className="w-100 ">
                                <span className="keyname">Player Issued</span>
                                <span className="keyvalue">Version {this.state.PlayerVersion}</span>
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="p-0 m-0 actionItemText">Action Item:</p>
                        </div>
                        <div className="col-12 pt-4">
                            <button type="button" className="userListbtn" onClick={() => this.navigate(Constants.AdminCompanysUsersList)}>User List</button>
                            <button type="button" className="issueLicensebtn" onClick={() => this.navigate(Constants.AdminCompanysLicenseIssue)}>Issue License</button>
                            <button type="button" className="releaseSoftwarebtn" onClick={() => this.navigate(Constants.AdminCompanysSoftwareRelease)}>Release Software</button>
                        </div>
                    </div>
                    <hr className="footerDivHR" />
                    <div className="row helpSection footerDiv">
                        <div className="col-12 text-center">
                            <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminCompanyDetails