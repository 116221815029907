import React from 'react';
import { Link } from "react-router-dom"
import { Route } from 'react-router-dom'
import '../../../../assets/css/MarketPlace.css'
import * as Constants from '../../../../assets/helpers/constants'
import DiscoverIcon from '../../../../assets/images/Group 921.png'
import MyProjectsIcon from '../../../../assets/images/Group 922.png'
import PeopleIcon from '../../../../assets/images/Group 923in.png'
import TutorialsIcon from '../../../../assets/images/Group 924.png'
import UploadNewIcon from '../../../../assets/images/Group 926.png'

import DiscoverActiveIcon from '../../../../assets/images/Group 921(1).png'
import MyProjectsActiveIcon from '../../../../assets/images/Group 1036.png'
import PeopleActiveIcon from '../../../../assets/images/Group 923.png'
import TutorialsActiveIcon from '../../../../assets/images/Group 924(1).png'
import UploadNewActiveIcon from '../../../../assets/images/Group 926(1).png'

// Market Place Pages
import DownloadComposer from './downloadComposer'
import Discover from './discover'
import MyProjects from './myProjects'
import People from './people'
import Tutorials from './tutorials'
import UploadNew from './uploadNew'
import ProjectDetails from './projectDetails'

class MarketPlaceDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeLink: null,
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            let activeLink = null;
            if (this.props.location.pathname.includes(Constants.Discover)) {
                activeLink = 'discover'
            } else if (this.props.location.pathname.includes(Constants.MyProjects)) {
                activeLink = 'projects'
            } else if (this.props.location.pathname.includes(Constants.People)) {
                activeLink = 'people'
            } else if (this.props.location.pathname.includes(Constants.Tutorials)) {
                activeLink = 'tutorials'
            } else if (this.props.location.pathname.includes(Constants.UploadNew)) {
                activeLink = 'uploadnew'
            }

            this.setState({
                activeLink: activeLink
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    setLink = (value) => {
        this.setState({
            activeLink: value
        })
    }



    render() {
        return (
            <div className="marketPalceDashboard mt-3">
                <div className="row headerContentSection">
                    <div className="col">
                        {this.state.activeLink === 'discover'
                            ? (<img src={DiscoverActiveIcon} alt="Discover" onClick={() => this.setLink('discover')} />)
                            :
                            (
                                <Link to={Constants.Discover}>
                                    <img src={DiscoverIcon} alt="Discover" onClick={() => this.setLink('discover')} />
                                </Link>
                            )
                        }


                    </div>
                    <div className="col">
                        {this.state.activeLink === 'projects'
                            ? (<img src={MyProjectsActiveIcon} alt="projects" onClick={() => this.setLink('projects')} />)
                            : (
                                <Link to={Constants.MyProjects}>
                                    <img src={MyProjectsIcon} alt="MyProjects" onClick={() => this.setLink('projects')} />
                                </Link>
                            )
                        }

                    </div>
                    <div className="col">
                        {this.state.activeLink === 'people'
                            ? (<img src={PeopleActiveIcon} alt="people" onClick={() => this.setLink('people')} />)
                            : (
                                <Link to={Constants.People}>
                                    <img src={PeopleIcon} alt="People" onClick={() => this.setLink('people')} />
                                </Link>
                            )
                        }
                    </div>
                    <div className="col">
                        {this.state.activeLink === 'tutorials'
                            ? (<img src={TutorialsActiveIcon} alt="tutorials" onClick={() => this.setLink('tutorials')} />)
                            : (<Link to={Constants.Tutorials}>
                                <img src={TutorialsIcon} alt="Tutorials" onClick={() => this.setLink('tutorials')} />
                            </Link>
                            )
                        }

                    </div>
                    <div className="col">
                        {this.state.activeLink === 'uploadnew'
                            ? (<img src={UploadNewActiveIcon} alt="uploadnew" onClick={() => this.setLink('uploadnew')} />)
                            : (
                                <Link to={Constants.UploadNew}>
                                    <img src={UploadNewIcon} alt="UploadNew" onClick={() => this.setLink('uploadnew')} />
                                </Link>
                            )
                        }
                    </div>
                </div>
                <hr className="dashboardHR" />
                <Route exact path={[Constants.MarketPlace, Constants.DownloadComposerView]} render={() => <DownloadComposer setLink={this.setLink}  {...this.props} />} />
                <Route exact path={Constants.Discover} render={() => <Discover setLink={this.setLink}  {...this.props} />} />
                <Route exact path={Constants.MyProjects} render={() => <MyProjects setLink={this.setLink}  {...this.props} />} />
                <Route exact path={Constants.People} render={() => <People setLink={this.setLink}  {...this.props} />} />
                <Route exact path={Constants.Tutorials} render={() => <Tutorials setLink={this.setLink}  {...this.props} />} />
                <Route exact path={Constants.UploadNew} render={() => <UploadNew setLink={this.setLink} {...this.props} />} />
                <Route exact path={[Constants.Discover + "/*", Constants.MyProjects + "/*", Constants.People + "/*", Constants.DownloadComposer + "/*"]} render={() => <ProjectDetails setLink={this.setLink} {...this.props} />} />
            </div>
        )
    }
}

export default MarketPlaceDashboard;