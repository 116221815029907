import React from 'react';
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import LoaderComp from '../../Loader'
import '../../../assets/css/ExperienceDashboard.css'
import AddButton from '../../../assets/images/AddUp.png'
import DeviceListIcon from '../../../assets/images/DevicelistUp.png'
import MediaIcon from '../../../assets/images/MediaUp.png'
import DeleteIcon from '../../../assets/images/trashcan.jpeg'
import UpdateExp from '../../../assets/images/updateExp.png'
import DownloadExp from '../../../assets/images/downloadexp.png'
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import fileDownload from 'js-file-download'

class ExperienceDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            defaultCompany: "Select Company",
            Experiences: [],
            CompanyList: [],
            companyName: null,
            companyId: null
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getCompanyDetails()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    renameExp = (id, name) => {
        Swal.fire({
            title: 'Rename Experience',
            input: 'text',
            inputValue: name,
            showCancelButton: true,
            confirmButtonText: 'Rename',
            inputValidator: (value) => {
                if (!value) {
                    return 'Please enter the name of experience!'
                } else {
                    if (!/[^a-zA-Z0-9]/.test(value)) {
                        // Call an API to rename the experience
                        let data = {
                            expId: id,
                            newName: value,
                            companyId: this.state.companyId
                        }
                        Axios.post(Constants.BASE_URL + `api/ExperienceManagement/renameExperiencename`, data).then((result) => {
                            if (result.data.status) {
                                this.props.getExperiences(this.state.companyName + "_" + this.state.companyId)
                            } else {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'error',
                                    title: 'Something went wrong. Please try again',
                                    showConfirmButton: false,
                                    timer: 1000
                                })
                            }
                        })

                    } else {
                        return 'Should not contain spaces or Special Characters'
                    }
                }
            }
        })
    }

    getCompanyDetails = () => {
        this.setState({
            isLoading: true
        }, () => {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let CompanyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
            let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
            let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role

            if (role === Constants.ContentManager || role === Constants.ComposerUser) {
                this.setState({
                    CompanyList: [{
                        CompanyId: CompanyId,
                        CompanyName: CompanyName
                    }],
                    companyId: CompanyId,
                    companyName: CompanyName,
                    defaultCompany: CompanyName + "_" + CompanyId,
                    isLoading: false
                })
                this.props.getExperiences(CompanyName + "_" + CompanyId)
            } else {
                Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getCompaniesList/${userID}/${CompanyId}`).then((result) => {
                    if (result.data.status) {
                        this.setState({
                            CompanyList: result.data.info.filter((ele, ind) => ind === result.data.info.findIndex(elem => elem.CompanyId === ele.CompanyId)),
                            companyId: CompanyId,
                            companyName: CompanyName,
                            defaultCompany: CompanyName + "_" + CompanyId,
                            isLoading: false
                        })
                        this.props.getExperiences(CompanyName + "_" + CompanyId)
                    }
                })
            }

        })

    }

    showDeviceList = (id) => {
        this.props.history.push(Constants.DeviceList + '/' + id)
    }

    getExp = (value) => {
        var x = value.split("_")
        this.setState({
            defaultCompany: value,
            companyId: x[1],
            companyName: x[0]
        })
        this.props.getExperiences(value)
        this.props.history.push(Constants.ExperienceDashboard)
    }

    addExperience = (id) => {
        this.props.history.push(Constants.AddExperience + '/' + this.state.companyId)
    }

    showMedia = (expName) => {
        this.props.history.push(Constants.MediaManagement + '/' + this.state.companyName + '/' + expName)
    }

    deleteExp = (Id, expName) => {
        Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getDevicesAssociatedToExperiece/${Id}`).then((result) => {
            if (result.data.status) {
                let x = result.data.info
                let y = "<ol>"
                x.map((value) => {
                    y += "<li>" + value.Name + "</li>"
                    return null
                })
                y += "</ol>"
                Swal.fire({
                    title: 'Are you sure you want to delete experience?',
                    icon: 'error',
                    html: "<div class='text-left'><h5>Associated Devices </h5>" + y + "</div>",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Delete'

                }).then((result) => {
                    if (result.isConfirmed) {
                        var x = this.state.defaultCompany.split("_")
                        let data = {
                            expId: Id,
                            location: x[0] + "/" + expName,
                            userId: JSON.parse(localStorage.getItem("userDetails"))[0].UserID
                        }

                        Axios.post(Constants.BASE_URL + `api/mediaManagement/deleteExperience`, data).then((result) => {
                            if (result.data.status) {
                                Swal.fire(
                                    'Deleted!',
                                    'Experience Deleted Sucessfully',
                                    'success'
                                )
                                this.props.getExperiences(this.state.companyName + "_" + this.state.companyId)
                            } else {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'error',
                                    title: 'Something went wrong. Please try again',
                                    showConfirmButton: false,
                                    timer: 1000
                                })
                            }
                        });
                    }
                })

            }
        })
    }

    getFormattedDate = (value) => {
        var date = new Date(value);
        var str = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes();
        return str;
    }

    downloadexp = (id, expName) => {
        Swal.fire({
            title: 'Are you sure you want to download?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Download'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    isLoading: true
                }, () => {
                    let data = {
                        expId: id
                    }
                    Axios.post(Constants.BASE_URL + `api/mediaManagement/downloadExp`, data, {
                        responseType: 'blob',
                    }).then((result) => {
                        fileDownload(result.data, expName + ".exp")
                        this.setState({
                            isLoading: false
                        })
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Download Started.Please wait for completion !!',
                            showConfirmButton: false,
                            timer: 2500
                        })
                    })
                })
            }
        })
    }

    updateexp = (id,expName) => {
        this.props.history.push(Constants.UpdateExperience + "/" + this.state.companyId + "/" + id+"/"+expName)
    }

    render() {
        let columns = [
            {
                grow: 3,
                center: 0,
                cell: row => {
                    let x;
                    if (row.Last_Modified_On === null) {
                        x = this.getFormattedDate(row.Created_On)
                    } else {
                        x = this.getFormattedDate(row.Last_Modified_On)
                    }

                    return <p className="experienceName">Experience Name <span className="divider">|</span> <span className="companyName" title={x} onDoubleClick={() => this.renameExp(row.ExperienceId, row.ExperienceName)}> {row.ExperienceName} </span></p>
                },
                sortable: true
            },
            {
                grow: 2,
                center: 1,
                cell: row => <div className="settingsOptions text-center">
                    <div>
                        <img src={DeviceListIcon} className="center" alt="DeviceListIcon" onClick={(e) => this.showDeviceList(row.ExperienceId)} />
                        <p>Device List</p>
                    </div>
                    <div>
                        <img src={MediaIcon} className="center" alt="MediaIcon" onClick={(e) => this.showMedia(row.ExperienceName)} />
                        <p>Media</p>
                    </div>
                    <div>
                        <img src={DownloadExp} className="center" alt="MediaIcon" onClick={(e) => this.downloadexp(row.ExperienceId, row.ExperienceName)} />
                        <p>Download</p>
                    </div>
                    <div>
                        <img src={UpdateExp} className="center" alt="MediaIcon" onClick={(e) => this.updateexp(row.ExperienceId,row.ExperienceName)} />
                        <p>Update</p>
                    </div>
                    {this.props.UserInfo.Role !== Constants.ContentManager ? (
                        <div>
                            <img src={DeleteIcon} className="center" alt="DeleteIcon" onClick={(e) => this.deleteExp(row.ExperienceId, row.ExperienceName)} />
                            <p>Delete</p>
                        </div>
                    ) : null}
                </div>,
                sortable: false
            }
        ]
        return (
            <div className="ExperienceDashboard" >
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="row" >
                    <div className="col-12 activityLogDiv">
                        <p className="ActivityLogTitle pt-3 ">Published - 2</p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="companyDetails">
                            <span className="selectdiv">Company
                                <select onChange={(e) => this.getExp(e.target.value)} value={this.state.defaultCompany} placeholder="Select Company">
                                    {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                        return <option key={company.CompanyId} id={company.CompanyId} value={company.CompanyName + "_" + company.CompanyId}>{company.CompanyName}</option>
                                    })
                                    )
                                        : null}
                                </select>
                            </span>
                        </label>
                        <label className="companyDetails">
                            <input placeholder="Search Experience" className="expSearchBox" onChange={(e) => this.props.searchExperiences(e.target.value)} />
                            {this.props.UserInfo.Role !== Constants.ContentManager ?
                                (<span onClick={() => this.addExperience(this.props.compId)}>
                                    <img src={AddButton} alt="AddButton" />
                                    <p className="p-0 m-0 addExperiennceText">Add Experience </p>
                                </span>
                                ) :
                                null
                            }
                        </label>
                    </div>
                </div>
                <div className="row experienceManagementDiv m-0 mt-2">
                    <div className="col-12 m-0">
                        <div className="row experienceManagementDetails">
                            <div className="col-12">
                                {this.props.filteredExperienceList.length > 0 ? (
                                    <DataTable
                                        className="experienceDetails"
                                        columns={columns}
                                        data={this.props.filteredExperienceList}
                                        pagination={true}
                                    />
                                ) : null}

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default ExperienceDashboard