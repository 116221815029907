import React from 'react'
import { Link } from 'react-router-dom'
import * as Constants from '../../../../assets/helpers/constants'
import Axios from 'axios';
import Swal from 'sweetalert2'

import BackIcon from '../../../../assets/images/Untitled-1-10.png'
import HeartIcon from '../../../../assets/images/Group 420@2x.png'
import BookmarkIcon from '../../../../assets/images/Group 422@2x.png'
import ViewIcon from '../../../../assets/images/Group 423@2x.png'
import ShareIcon from '../../../../assets/images/Group 421@2x.png'

export default class People extends React.Component {
    constructor() {
        super()
        this.state = {
            userDetails: []
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getPeople()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getPeople = () => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/MarketPlace/getPeople`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        userDetails: result.data.info,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        userDetails: [],
                        isLoading: false
                    }, () => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Error in fetching details.Please try later",
                            showConfirmButton: false,
                            timer: 1000
                        })
                    })
                }
            })
        })
    }

    navigateToProject = (id) => {
        this.props.incrementViews(id)
        this.props.history.push(Constants.People + "/" + id)
    }

    render() {
        return (
            <div className="peopleDetails">
                <div className="row  pl-3">
                    <div className="col-1">
                        <Link to={Constants.MarketPlace} onClick={() => this.props.setLink(null)}><img src={BackIcon} alt="BackIcon" className="backIcon" /><p className="backBtnText">Back</p></Link>
                    </div>
                </div>
                <div className="row peopleDetailsDiv  pl-3">
                    <div className="col-12 peopleDiv">
                        {this.state.userDetails.length > 0 ? (
                            this.state.userDetails.map((details, index) => {
                                return (
                                    <div className="row individualPerson p-0 m-0 mt-4 pt-4" key={index}>
                                        <div className="col-3">
                                            <div className="row">
                                                <div className="col-4 userInformationDiv">
                                                    <img className="userProficePic" src={details.userDetails.ImgLink} alt="Pic" />
                                                </div>
                                                <div className="col-8">
                                                    <p className="userName">{details.userDetails.Name}</p>
                                                    <p className="userDesc">{details.userDetails.Country},{details.userDetails.State} <br/>{details.userDetails.Designation}, {details.userDetails.JobTitle}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 text-center followDetails">
                                            {/* <button type="button" className="followBtn">Follow</button> */}
                                            <label className="heartIconDiv">
                                                <img src={ViewIcon} alt="BookmarkIcon" /><br />
                                                <span>50</span>
                                            </label>
                                            <label className="heartIconDiv">
                                                <img src={HeartIcon} alt="HeartIcon" /> <br />
                                                <span>30</span>
                                            </label>

                                            <label className="heartIconDiv">
                                                <img src={ShareIcon} alt="HeartIcon" /> <br />
                                                <span>20</span>
                                            </label>

                                            <label className="bookmarkIconDiv">
                                                <img src={BookmarkIcon} alt="BookmarkIcon" /><br />
                                                <span>10</span>
                                            </label>

                                        </div>

                                        {/* Project Details of User */}
                                        <div className="col-7 mb-4">
                                            {details.projectDetails.length > 0 ? (
                                                details.projectDetails.map((proj, index) => {
                                                    return (
                                                        <div className="mostLikedProjects" onClick={() => this.navigateToProject(proj.Id)} title={proj.Name} key={index}>
                                                            <img src={proj.Thumbnail} alt="" />
                                                        </div>
                                                    )
                                                })
                                            ) : null}

                                        </div>
                                    </div>
                                )
                            })
                        ) : null}

                    </div>
                </div>
            </div>
        )
    }
}