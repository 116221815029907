import React from 'react'
import Axios from 'axios';
import * as Constants from '../../../assets/helpers/constants'
import Checkout from '../../../assets/helpers/checkout'
import '../../../assets/css/LicenseManagement.css'
import keyIcon from '../../../assets/images/LicenceUp.png'
import copyImg from '../../../assets/images/DuplicateDown.png'
import Moment from 'react-moment';
import Swal from 'sweetalert2'

class LicenceDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            isRenewal: false,
            playerLicensePrice: 0,
            composerLicensePrice: 0,
            totalPlayerLicences: 0,
            totalComposerLicences: 0,
            licenceId: null,
            licenceDetails: [],
            isReseller: true,
            assignedCompany: null,
            deviceName: null,
            deviceUUId: null,
            experienceName: null,
            lastSignedIn: null,
            licenseKey: null,
            deviceList: [],
            experienceList: [],
            companyList: [],
            selectedDevice: "",
            selectedExperience: "",
            selectedCompany: "",
            selectedDuration: 3,
            licenceType: "",
            isTransferable: true,
        }
    }
    componentDidMount() {
        const licenceDetails = JSON.parse(this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1))
        const licenceId = licenceDetails[0].id
        const licenceType = licenceDetails[0].type
        let playerLicenses = licenceDetails.filter(function (item) {
            return item.type === 'Player';
        });

        let composerLicenses = licenceDetails.filter(function (item) {
            return item.type === 'Composer';
        });

        this.setState({
            licenceDetails: licenceDetails,
            totalPlayerLicences: playerLicenses.length,
            totalComposerLicences: composerLicenses.length,
            email: this.props.UserInfo.Email,
            name: this.props.UserInfo.Name,
            licenceType: licenceType,
            licesesCompany: licenceDetails[0].companyId
        }, () => {
            if (licenceDetails.length === 1) {
                this.getLicenceDetails(licenceId)
            }
        })
        this.getLicenceRelatedDetails(licenceDetails[0].companyId, licenceType)
    }

    getLicenceDetails = (licenceId) => {
        Axios.get(Constants.BASE_URL + `api/LicenseManagement/getLicensedetails/${licenceId}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    assignedCompany: result.data.info.assignedCompany,
                    deviceName: result.data.info.deviceName,
                    deviceUUId: result.data.info.deviceUUId,
                    experienceName: result.data.info.experienceName,
                    lastSignedIn: result.data.info.lastSignedIn !== null ? result.data.info.lastSignedIn : result.data.info.licenseCreatedOn,
                    licenseKey: result.data.info.licenseKey,
                    licenceId: licenceId,
                    licenseStatus: result.data.info.licenseStatus,
                    isTransferable: result.data.info.isTransferable
                })
            }
        });
    }

    getLicenceRelatedDetails = (CompanyId, licenseType) => {
        let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
        let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role
        let data = {
            companyId: CompanyId,
            userId: userID,
            licenseType: licenseType
        }
        Axios.post(Constants.BASE_URL + `api/LicenseManagement/getLicenceRelatedDetails`, data).then((result) => {
            if (result.data.status) {
                let companyList = []
                if (role === Constants.ContentManager || role === Constants.ComposerUser) {
                    companyList = result.data.info.companiesList.filter((company) => {
                        return company.CompanyId === CompanyId
                    })
                } else {
                    companyList = result.data.info.companiesList.filter((ele, ind) => ind === result.data.info.companiesList.findIndex(elem => elem.CompanyId === ele.CompanyId))
                }

                this.setState({
                    deviceList: result.data.info.devicesList,
                    experienceList: result.data.info.experiencesList,
                    companyList: companyList
                })
            }
        });
    }

    copyLicenseKey = (value) => {
        navigator.clipboard.writeText(value)
    }

    setValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    submitLicenseChanges = () => {
        let data = {
            "licenseId": this.state.licenceDetails,
            "deviceId": this.state.selectedDevice,
            "companyId": this.state.selectedCompany,
            "experienceId": this.state.selectedExperience,
            "userId": this.props.UserInfo.UserID
        }
        Axios.put(Constants.BASE_URL + `api/LicenseManagement/assignLicense`, data).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Successfully updated Licence',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.props.getLicenses(this.props.licenceInfo.defaultCompany)
                this.props.history.push(Constants.LicenseDashboard)
            }
        });
    }

    unAssign = () => {
        let data = {
            "LicenseId": this.state.licenceDetails
        }
        Axios.put(Constants.BASE_URL + `api/LicenseManagement/unassignLicense`, data).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Successfully unassigned Licence',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.props.getLicenses(this.props.licenceInfo.defaultCompany)
                this.props.history.push(Constants.LicenseDashboard)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Unable to unassign licence. Please contact Admin',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        });
    }

    getLicences = () => {
        this.setState({
            playerLicensePrice: 0,
            composerLicensePrice: 0,
            totalPlayerLicences: 0,
            totalComposerLicences: 0,
            licenceId: null,
            licenceDetails: [],
            isReseller: true,
            assignedCompany: null,
            deviceName: null,
            deviceUUId: null,
            experienceName: null,
            lastSignedIn: null,
            licenseKey: null,
            deviceList: [],
            experienceList: [],
            companyList: [],
            selectedDevice: "",
            selectedExperience: "",
            selectedCompany: "",
        }, () => {
            this.props.getLicenses(this.props.licenceInfo.defaultCompany)
            this.props.history.push(Constants.LicenseDashboard)
            // window.location.reload();
        })
    }

    renewLicenses = () => {
        this.setState({
            isRenewal: true
        }, () => {
            // Get pricing of licenses
            Axios.get(Constants.BASE_URL + `api/getLicensePricing`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        playerLicensePrice: result.data.info[0].licence_type === "Player" ? result.data.info[0].price : 0,
                        composerLicensePrice: result.data.info[1].licence_type === "Composer" ? result.data.info[1].price : 0,
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Unable to fetch pricing details. Please contact Admin',
                        showConfirmButton: false,
                        timer: 1000
                    })
                }
            })
        })
    }

    changeDuration = (value) => {
        this.setState({
            selectedDuration: parseInt(value)
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide">

                {!this.state.isRenewal ?
                    (
                        // licence Details Screen
                        <div className="signin pl-5 licenseDetails">
                            <p className="loginTitle mb-0">License Details</p>
                            <p className="addExpSubTitle">Configure your device below</p>
                            <div className="row">
                                <div className="col-12">
                                    <label className="licenseKeyDetails">
                                        <span className="keyIcon">
                                            <img src={keyIcon} alt="keyIcon" />
                                        </span>
                                        <span className="keyDetails ml-3" onClick={() => this.copyLicenseKey(this.state.licenseKey)} >
                                            <p className="p-0 m-0 key" >{this.state.licenseKey}</p>
                                            <img src={copyImg} alt="copyImg" className="copyImg" />
                                        </span>
                                    </label>
                                </div>

                            </div>
                            <div className="row keyInfo">
                                <div className="col-12 w-100 keydetailsDiv">
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="keyname">Assigned Device</span>
                                        </div>
                                        <div className="col-6">
                                            <span className="keyvalue">{this.state.licenceDetails.length === 1 ? this.state.deviceName : null}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 w-100 keydetailsDiv">
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="keyname">Device UUID</span>
                                        </div>
                                        <div className="col-6">
                                            <span className="keyvalue">{this.state.licenceDetails.length === 1 ? this.state.deviceUUId : null}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 w-100 keydetailsDiv">
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="keyname">Active Experience</span>
                                        </div>
                                        <div className="col-6">
                                            <span className="keyvalue">{this.state.licenceDetails.length === 1 ? this.state.experienceName : null}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 w-100 keydetailsDiv">
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="keyname">Updated/Assigned</span>
                                        </div>
                                        <div className="col-6">
                                            {this.state.licenceDetails.length === 1 ?
                                                <span className="keyvalue">
                                                    <Moment format="DD-MMM-YYYY">
                                                        {this.state.licenceDetails.length === 1 ? this.state.lastSignedIn
                                                            : null}
                                                    </Moment></span>
                                                : null
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12 w-100 keydetailsDiv">
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="keyname">Status</span>
                                        </div>
                                        <div className="col-6">
                                            <span className="keyvalue">{this.state.licenceDetails.length === 1 ? this.state.licenseStatus ? "Active" : "InActive" : null}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 w-100 keydetailsDiv">
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="keyname">Assigned Company</span>
                                        </div>
                                        <div className="col-6">
                                            <span className="keyvalue">{this.state.licenceDetails.length === 1 ? this.state.assignedCompany : null}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row assignNewDetails">

                                {this.state.licenceType !== 'Composer' ?
                                    this.state.licenceDetails.length === 1 && this.state.isTransferable ? (
                                        <div className="col-12 selectdiv">
                                            <select className="deviceList" value={this.state.selectedDevice} onChange={(e) => this.setValue("selectedDevice", e.target.value)}>
                                                <option value="">Assign New Device</option>
                                                {this.state.deviceList.length > 0 ? (
                                                    this.state.deviceList.map((device, index) => {
                                                        return <option key={index} id={device.id} value={device.Id}>{device.Name}</option>
                                                    })
                                                ) : null}
                                            </select>
                                        </div>
                                    ) : null
                                    : this.state.licenceDetails.length === 1 && (<div className="col-12 selectdiv">
                                        <select className="deviceList" value={this.state.selectedDevice} onChange={(e) => this.setValue("selectedDevice", e.target.value)}>
                                            <option value="">Assign New Device</option>
                                            {this.state.deviceList.length > 0 ? (
                                                this.state.deviceList.map((device, index) => {
                                                    return <option key={index} id={device.id} value={device.Id}>{device.Name}</option>
                                                })
                                            ) : null}
                                        </select>
                                    </div>)}


                                {this.state.licenceType !== 'Composer' ? (
                                    <div className="col-12 selectdiv" value={this.state.selectedExperience} onChange={(e) => this.setValue("selectedExperience", e.target.value)}>
                                        <select className="experienceList">
                                            <option value="">Assign New Experience</option>
                                            {this.state.experienceList.length > 0 ? (
                                                this.state.experienceList.map((experience, index) => {
                                                    return <option key={index} id={experience.id} value={experience.Id}>{experience.Name}</option>
                                                })
                                            ) : null}
                                        </select>
                                    </div>
                                ) : null}


                                {this.props.UserInfo.Role !== Constants.ContentManager ? (
                                    <div className="col-12 selectdiv">
                                        <select className="companyList" value={this.state.selectedCompany} onChange={(e) => this.setValue("selectedCompany", e.target.value)}>
                                            <option value="">Assign Company</option>
                                            {this.state.companyList.length > 0 ? (
                                                this.state.companyList.map((company, index) => {
                                                    return <option key={index} id={company.CompanyId} value={company.CompanyId}>{company.CompanyName}</option>
                                                })
                                            ) : null}
                                        </select>
                                    </div>) : null}
                                <div className="col-12">
                                    {(this.state.selectedDevice !== "" || this.state.selectedExperience !== "" || this.state.selectedCompany !== "") ? <button type="button" className="saveChanges" onClick={this.submitLicenseChanges}>Save Changes</button> : null}
                                </div>
                            </div>

                            <div className="row resellerUnassignRenewBtns">
                                <div className="col-6">
                                    <button type="button " className="unassign" onClick={this.unAssign}>Un-Assign</button>
                                </div>
                                <div className="col-6">
                                    <button type="button" onClick={this.renewLicenses} className="renew">Renew</button>
                                </div>
                            </div>
                        </div>
                    ) :
                    (
                        // licence Renewal Screen
                        <div className="signin pl-5 licenseDetails ">
                            <p className="loginTitle mb-0">License Renew</p>
                            <div className="row licenseRenewalDetails ">
                                <div className="col-12 p-0">
                                    <div className="row">
                                        <label className="licenseSubscription w-100">
                                            <span className="subscriptionPreiodText">
                                                Subscription Period
                                            </span>
                                            <span className="subscriptionPreiodSeperator">|</span>
                                            <span className="subscriptionPreiodSelect selectdiv">
                                                <select onChange={(e) => this.changeDuration(e.target.value)} value={this.state.selectedDuration}>
                                                    <option value={3}>3 Months</option>
                                                    <option value={6}>6 Months</option>
                                                    <option value={12}>1 Year</option>
                                                    <option value={24}>2 Years</option>
                                                </select>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <table className="w-100 table pricingTable">
                                                <thead>
                                                    <tr>
                                                        <th>Licence</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Player Licence</td>
                                                        <td>{this.state.totalPlayerLicences}</td>
                                                        <td>{this.state.playerLicensePrice} / Per Month</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Composer Licence</td>
                                                        <td>{this.state.totalComposerLicences}</td>
                                                        <td>{this.state.composerLicensePrice} / Per Month</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="totalLabel">Total</td>
                                                        <td></td>
                                                        <td className="totalPrice">{this.state.playerLicensePrice * this.state.selectedDuration * this.state.totalPlayerLicences + this.state.composerLicensePrice * this.state.selectedDuration * this.state.totalComposerLicences} /-</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 w-100">
                                            <Checkout {...this.props} checkoutType="licenseRenewal" getLicences={this.getLicences} composerLicences={this.state.totalComposerLicences} playerLicences={this.state.totalPlayerLicences} licenceDetails={this.state.licenceDetails} duration={this.state.selectedDuration} name={this.state.name} email={this.state.email} amount={this.state.playerLicensePrice * this.state.selectedDuration * this.state.totalPlayerLicences + this.state.composerLicensePrice * this.state.selectedDuration * this.state.totalComposerLicences} BTNtitle="Proceed" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )

                }
            </div>
        )
    }
}

export default LicenceDetails