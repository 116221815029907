import React from 'react';
import Axios from 'axios';
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
// import SidePanelFooter from './sidepanelFooter'
// import UploadImg from '../../../assets/images/Upload.png'
import Swal from 'sweetalert2'
import { ProgressBar } from 'react-bootstrap';

let source;
class AddExperience extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            defaultValueExperienceStatus: "Experience Status",
            companyId: null,
            experienceName: "",
            file: "",
            tagId: "",
            tagsList: [],
            CompanyName: "",
            percentage: 0,
            defaultTagValue: "select tag",
            isDisabled: false,
            selectfilePlaceHolder: ""
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            const companyId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
            Axios.get(Constants.BASE_URL + `api/getCompanyDetails/${companyId}`).then((result) => {
                if (result.data.status && result.data.info.length > 0) {
                    this.setState({
                        CompanyName: result.data.info[0].Name
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Cannot fetch Company Details",
                        showConfirmButton: false,
                        timer: 1000
                    })
                }
            });

            Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getTagsList/${companyId}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        tagsList: result.data.info,
                        companyId: companyId
                    })
                }
            });
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    addExperience = (event) => {
        event.preventDefault();

        if (this.state.experienceName.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Experience Cannot Be Empty",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (/[^a-zA-Z0-9]/.test(this.state.experienceName)) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Experience Name Should not contain spaces or Special Characters",
                showConfirmButton: false,
                timer: 2000
            })
            return false
        }

        if (this.state.defaultValueExperienceStatus === "Experience Status") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please select Experience Status ",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.file === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please upload Experience File",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }
        source = Axios.CancelToken.source();
        this.setState({
            isLoading: false,
            isDisabled: true
        }, () => {
            const formData = new FormData();
            formData.append('fileUpload', this.state.file);
            formData.append('expStatus', this.state.defaultValueExperienceStatus);
            formData.append('tagId', this.state.tagId);
            formData.append('ExpName', this.state.experienceName);
            formData.append('companyId', this.state.companyId);
            formData.append('userId', JSON.parse(localStorage.getItem("userDetails"))[0].UserID !== null ? JSON.parse(localStorage.getItem("userDetails"))[0].UserID : 0);
            formData.append('companyName', this.state.CompanyName)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            var options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent
                    let percentage = Math.floor(loaded * 100 / total)
                    if (percentage < 100) {
                        this.setState({
                            percentage: percentage
                        })
                    }
                },
                cancelToken: source.token
            }

            Axios.post(Constants.BASE_URL + "api/ExperienceManagement/createExperience", formData, options, config).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Experience Created Successfully!!",
                        showConfirmButton: false,
                        timer: 1000
                    })

                    this.setState({
                        percentage: 100,
                        isLoading: false,
                        isDisabled: false

                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                percentage: 0
                            })
                        }, 1000)
                        this.props.getExperiences(this.props.companyName + "_" + this.state.companyId)
                        this.props.history.push(Constants.ExperienceDashboard);
                    })

                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: result.data.info,
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading: false,
                        isDisabled: false

                    })
                }
            });

        })

        return false
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    validateExpName = (value) => {
        Axios.get(Constants.BASE_URL + `api/ExperienceManagement/validateExperiencName/${value}/${this.state.companyId}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    "experienceName": value
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Experience Name Already Exists",
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    "experienceName": ""
                })
            }
        });

    }

    cancelUpload = () => {
        if (source) {
            source.cancel("On click Cancel");
            this.setState({
                percentage: 0,
                isDisabled: false
            })
        }
    }

    changeFile = (e) => {
        var fileName = e.target.value
        var extension = fileName.split('.').pop();
        if (extension !== 'exp') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please upload only <br /> experience file",
                showConfirmButton: false,
                timer: 3000
            })
            this.setState({
                selectfilePlaceHolder: ""
            })
        } else {
            let data = {
                fileSize : e.target.files[0].size,
                companyId: this.state.companyId
            }

            Axios.post(Constants.BASE_URL + `api/validateStorage`,data).then((result) => {
                if (!result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Please upgrade Company storage to upload. Please contact admin! ",
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.setState({
                        file: "",
                        selectfilePlaceHolder: ""
                    })
                }else{
                    this.setState({
                        file: e.target.files[0],
                        selectfilePlaceHolder: e.target.files[0].name
                    })
                }
            })
        }
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="signin pl-5 addExperience">
                    <p className="loginTitle mb-0">Add New Experience</p>
                    <p className="addExpSubTitle">Configure your experience below</p>
                    <form id="addExperience" onSubmit={this.addExperience} >
                        <input type="text" name="experienceName" className="w-100 expName" value={this.state.experienceName} placeholder="Experience Name" maxLength="40" onChange={(e) => this.changeValue("experienceName", e.target.value)} onBlur={(e) => this.validateExpName(e.target.value)} required disabled={this.state.isDisabled} />
                        <div className="selectdiv">
                            <select value={this.state.defaultValueExperienceStatus} name="experienceStatus" onChange={(e) => this.changeValue("defaultValueExperienceStatus", e.target.value)} required disabled={this.state.isDisabled}>
                                <option disabled value="Experience Status">Experience Status</option>
                                <option value="1">Active</option>
                                <option value="0">InActive</option>
                            </select>
                        </div>
                        <div className="selectdiv">

                            <select value={this.state.tagId !== "" ? this.state.tagId : this.state.defaultTagValue} name="tagdetails" onChange={(e) => this.changeValue("tagId", e.target.value)} placeholder="Select Tag" disabled={this.state.isDisabled}>
                                <option disabled value={this.state.defaultTagValue}>Select Tag</option>
                                {this.state.tagsList.map((tag, index) => {
                                    return <option key={index} value={tag.Id}>{tag.Name}</option>
                                })}
                            </select>
                        </div>

                        <div className="fileDiv">
                            <label className="w-100">
                                <input type="file" id="files" className="hide" name="expFile" accept="*.exp" placeholder="Upload Experience File" onChange={this.changeFile} required disabled={this.state.isDisabled} />
                                <label htmlFor="files">{this.state.selectfilePlaceHolder === "" ? "Select file to Upload" : this.state.selectfilePlaceHolder}</label>
                            </label>
                        </div>
                        <div>
                            {this.state.percentage > 0 ? (
                                <ProgressBar now={this.state.percentage} active={"true"} label={`${this.state.percentage}%`} />
                            ) : null}
                            <br />
                            {this.state.percentage > 0 ? <button type="button" onClick={this.cancelUpload} className="CancelRequestButton">Cancel Upload</button> : null}
                        </div>
                        <button disabled={this.state.isDisabled} type="submit" className="uploadExperienceBtn">Upload Experience </button>
                    </form>
                    {/* <SidePanelFooter /> */}
                </div>
            </div>
        )
    }
}

export default AddExperience