import React from 'react';

class SoftwareRelease extends React.Component {
    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="pl-5 pr-5 userRegistration">
                    <div className="row userTitle">
                        <div className="col-12">
                            <p className="m-0 userlistlabel">Software Release</p>
                            <p className="m-0 userlistSublabel">Release management to all users types</p>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default SoftwareRelease