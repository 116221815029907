import React from 'react';
import Axios from 'axios'
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import '../../../assets/css/LicenseManagement.css'
import addBtn from '../../../assets/images/AddUp.png'
import Swal from 'sweetalert2'
import Checkout from '../../../assets/helpers/checkout'

class AdminCompanysLicenseIssue extends React.Component {
    constructor() {
        super()
        this.state = {
            CompanyId: null,
            CompanyList: [],
            isLoading: false,
            IsComposer: true,
            IsPlayer: false,
            IsTransferable: false,
            IsInteractive: false,
            playerLicences: 0,
            composerLicences: 0,
            selectedDuration: 3,
            playerLicensePrice: 0,
            composerLicensePrice: 0,
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getCompanyDetails()
            this.getLicensePricing()
        } else {
            this.props.history.push(Constants.Login)
        }


    }

    getLicensePricing() {
        Axios.get(Constants.BASE_URL + `api/getLicensePricing`).then((result) => {
            if (result.data.status) {
                this.setState({
                    playerLicensePrice: result.data.info[0].licence_type === "Player" ? result.data.info[0].price : 0,
                    composerLicensePrice: result.data.info[1].licence_type === "Composer" ? result.data.info[1].price : 0,
                    email: this.props.UserInfo.Email,
                    name: this.props.UserInfo.Name,
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Unable to fetch pricing details. Please contact Admin',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        })
    }

    changeDuration = (value) => {
        this.setState({
            selectedDuration: parseInt(value)
        })
    }


    getCompanyDetails = () => {
        this.setState({
            isLoading: true
        }, () => {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let CompanyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
            let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID

            let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role
            if (role === Constants.ContentManager) {
                this.setState({
                    CompanyList: [{
                        CompanyId: CompanyId,
                        CompanyName: CompanyName
                    }],
                    CompanyId: CompanyId,
                    isLoading: false
                })
            } else {
                Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getCompaniesList/${userID}/${CompanyId}`).then((result) => {
                    if (result.data.status) {
                        this.setState({
                            CompanyList: result.data.info,
                            CompanyId: CompanyId,
                            isLoading: false,
                        })
                    }
                })
            }
        })
    }

    changeCompany = (id) => {
        this.setState({
            CompanyId: id,
        })
    }

    setValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    changeLicenseType = (key) => {
        if (key === "IsComposer") {
            this.setState({
                IsComposer: true,
                IsPlayer: false,
                IsInteractive: false,
                IsTransferable: false,
                playerLicences: 0,
                composerLicences: 0
            })
        } else {
            this.setState({
                IsComposer: false,
                IsPlayer: true,
                IsInteractive: false,
                IsTransferable: false,
                playerLicences: 0,
                composerLicences: 0
            })
        }
    }

    setQuantity = (Licensetype, quantity) => {
        this.setState({
            [Licensetype]: parseInt(quantity)
        })
    }

    getLicences = () => {
        this.setState({
            playerLicensePrice: 0,
            composerLicensePrice: 0,
            licenceId: null,
            licenceDetails: [],
            isReseller: true,
            assignedCompany: null,
            deviceName: null,
            deviceUUId: null,
            experienceName: null,
            lastSignedIn: null,
            licenseKey: null,
            deviceList: [],
            experienceList: [],
            companyList: [],
            selectedDevice: "",
            selectedExperience: "",
            selectedCompany: "",
        }, () => {
            this.props.getQuickStats()
            this.props.history.push(Constants.AdminUserManagementPanel)
        })
    }

    decreaseValue = (key) => {
        let finalValue = this.state[key] - 1
        this.setState({
            [key]: finalValue >= 0 ? finalValue : 0
        })
    }

    appendValue = (key) => {
        let finalValue = this.state[key] + 1
        this.setState({
            [key]: finalValue >= 0 ? finalValue : 0
        })
    }
    render() {
        return (
            <div className="rightSidePanel slide">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className=" pl-5 buyLicenses">
                    <p className="loginTitle mb-0">Buy Licenses</p>
                    <div className="row">
                        <div className="col-12">
                            <p>Company:</p>
                        </div>
                        <div className="col-12 p-0" >
                            <label className="companyDetails text-left">
                                <span className="selectdiv">
                                    <select onChange={(e) => this.changeCompany(e.target.value)} value={this.state.CompanyId !== null ? this.state.CompanyId : ""} placeholder="Select Company">
                                        {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                            return <option key={company.CompanyId} id={company.CompanyId} value={company.CompanyId}>{company.CompanyName}</option>
                                        })
                                        )
                                            : null}
                                    </select>
                                </span>

                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label>LicenseType</label>
                        </div>
                        <div className="col-4">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="lictype" checked={this.state.IsComposer} onChange={() => this.changeLicenseType("IsComposer")} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Composer</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="lictype" checked={this.state.IsPlayer} onChange={() => this.changeLicenseType("IsPlayer")} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Player</label>
                            </div>
                        </div>
                    </div>

                    {this.state.IsPlayer ? (
                        <>
                            <div className="row">
                                <div className="col-4">
                                    <label>Transferable</label>
                                </div>
                                <div className="col-4">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="istransferable" checked={this.state.IsTransferable} onChange={() => this.setValue("IsTransferable", true)} />
                                        <label className="form-check-label" htmlFor="inlineCheckbox1">Yes</label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="istransferable" checked={!this.state.IsTransferable} onChange={() => this.setValue("IsTransferable", false)} />
                                        <label className="form-check-label" htmlFor="inlineCheckbox2">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label>Interactive</label>
                                </div>
                                <div className="col-4">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="isinteractive" checked={this.state.IsInteractive} onChange={() => this.setValue("IsInteractive", true)} />
                                        <label className="form-check-label" htmlFor="inlineCheckbox1">Yes</label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="isinteractive" checked={!this.state.IsInteractive} onChange={() => this.setValue("IsInteractive", false)} />
                                        <label className="form-check-label" htmlFor="inlineCheckbox2">No</label>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}

                    <div className="row priceingDiv">
                        <div className="col-12 w-100 ">
                            <table className="table w-100">
                                <thead className="text-center">
                                    <tr>
                                        <th className="text-left">License</th>
                                        <th className="text-center">Quantity</th>
                                        <th className="text-left">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.IsComposer ? (
                                        <tr>
                                            <td className="firstCol">Composer License</td>
                                            <td>
                                                <label>
                                                    <span onClick={() => this.decreaseValue("composerLicences")} > <img src={addBtn} alt="addBtn" /> </span>
                                                    <span> <input min={0} type="number" value={this.state.composerLicences} onChange={(e) => this.setQuantity("composerLicences", e.target.value)} />  </span>
                                                    <span onClick={() => this.appendValue("composerLicences")}> <img src={addBtn} alt="addBtn" /> </span>
                                                </label>
                                            </td>
                                            <td>{this.state.composerLicensePrice}/-</td>
                                        </tr>
                                    ) : null}
                                    {this.state.IsPlayer ? (
                                        <tr>
                                            <td className="firstCol">Player License</td>
                                            <td>
                                                <label>
                                                    <span onClick={() => this.decreaseValue("playerLicences")}> <img src={addBtn} alt="addBtn" /></span>
                                                    <span> <input min={0} type="number" value={this.state.playerLicences} onChange={(e) => this.setQuantity("playerLicences", e.target.value)} />  </span>
                                                    <span onClick={() => this.appendValue("playerLicences")}> <img src={addBtn} alt="addBtn" /> </span>
                                                </label>
                                            </td>
                                            <td>{this.state.playerLicensePrice}/-</td>
                                        </tr>) : null}

                                    <tr className="firstCol">
                                        <td colSpan="1">Duration</td>
                                        <td colSpan="2">
                                            <select onChange={(e) => this.changeDuration(e.target.value)} value={this.state.selectedDuration}>
                                                <option value={3}>3 Months</option>
                                                <option value={6}>6 Months</option>
                                                <option value={12}>1 year</option>
                                                <option value={24}>2 Years</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr >
                                        <td className="firstCol" colSpan="1">Total</td>
                                        <td colSpan="2" className="text-right">
                                            <p>{this.state.IsComposer ? this.state.composerLicences * this.state.selectedDuration * this.state.composerLicensePrice : this.state.playerLicences * this.state.selectedDuration * this.state.playerLicensePrice} /-</p>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        {this.state.playerLicences > 0 || this.state.composerLicences > 0 ? (
                            <div className="col-12">
                                <Checkout {...this.props} companyId={this.state.CompanyId} isTransferable={this.state.IsTransferable} isInteractive={this.state.IsInteractive} checkoutType="buyLicense" getLicences={this.getLicences} composerLicences={this.state.composerLicences} playerLicences={this.state.playerLicences} licenceDetails={this.state.licenceDetails} duration={this.state.selectedDuration} name={this.state.name} email={this.state.email} amount={this.state.IsComposer ? this.state.composerLicences * this.state.selectedDuration * this.state.composerLicensePrice : this.state.playerLicences * this.state.selectedDuration * this.state.playerLicensePrice} BTNtitle="Buy" />
                            </div>
                        ) : null}

                    </div>
                </div>
            </div>
        )
    }
}

export default AdminCompanysLicenseIssue

