import React from 'react'
import DataTable from 'react-data-table-component';
import Axios from 'axios'
import * as Constants from '../../assets/helpers/constants'
import Swal from 'sweetalert2'

class MyPosts extends React.Component {
  constructor() {
    super()
    this.state = {
      postsList: [],
      pathName: null,
      columns: [
        {
          name: 'Created By',
          grow: 2,
          center: 1,
          cell: (row, index) => <div data-tag="allowRowEvents" className="devicesSlNo text-center">{row.UserName}</div>,
          sortable: false,
        },
        {
          name: 'Topic',
          grow: 2,
          center: 1,
          cell: row => <div data-tag="allowRowEvents" className="osIcon text-center">{row.Topic}</div>,
          sortable: true
        },
        {
          name: 'Tags ',
          grow: 2,
          center: 1,
          cell: row => <div data-tag="allowRowEvents" className="devicesName">{(JSON.parse(row.Tags)).map((value, index) => {
            return <label key={index}>{value.label} ,</label>
          })}</div>,
          selector: 'Tags',
          sortable: true
        },
        {
          name: 'Category',
          grow: 2,
          center: 1,
          cell: row => <div data-tag="allowRowEvents" className="devicesStatus text-center">{(JSON.parse(row.Category)).map((value, index) => {
            return <label key={index}>{value.label} ,</label>
          })}</div>,
          selector: 'Category',
          sortable: true
        },

        {
          name: 'Description',
          grow: 3,
          center: 1,
          cell: row => <div data-tag="allowRowEvents" className="devicesExpName text-center">{row.Content}</div>,
          selector: 'Content',
          sortable: false
        },
        {
          name: 'Action',
          grow: 1,
          center: 1,
          cell: (row) => <button type="button" className="viewButton" onClick={() => this.handleAction(row.Post_Id)}>View</button>,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
    }
  }

  componentDidMount() {
    if (localStorage.getItem("userDetails") !== null) {
      let userDetails = JSON.parse(localStorage.getItem("userDetails"))
      let UserId = userDetails[0].UserID
      this.setState({
        pathName: this.props.location.pathname,
      }, () => {
        this.props.getMyPosts(UserId)
      })
    } else {
      this.props.history.push(Constants.Login)
    }
  }

  deletePost = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(Constants.BASE_URL + `api/ForumDashboard/deletePost/${id}`).then((result) => {
          if (result.data.status) {
            // alert("Post Deleted Sucessfully");
            Swal.fire(
              'Deleted!',
              'Post Deleted Sucessfully',
              'success'
            )
            this.props.getMyPosts()
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Something went wrong. Please try again',
              showConfirmButton: false,
              timer: 1500
            })
          }

        });

      }
    })



  }

  handleAction = (id) => {
    this.props.history.push(this.state.pathName + "/Details/" + id)
  }

  navigate = (link) => {
    this.props.history.push(Constants.MyPosts + "/CreatePost")
  }

  render() {
    return (<div className="myPosts">
      <div className="row AllpostsTitle">
        <div className="col-6">
          <p className="pageTitle">My Posts </p>
        </div>
        <div className="col-6 text-right">
          <button type="button" className="addnewPost" onClick={this.navigate}>Create New Post</button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <input className="searchBar" onChange={(e) => { this.props.searchMyPostsText(e) }} placeholder="Search keyword" />
        </div>
      </div>
      {this.props.myPostsList.length !== 0 ?
        <DataTable
          columns={this.state.columns}
          data={this.props.myPostsList}
          progressPending={this.props.myPostsList.length !== 0 ? false : true}
          pagination={true}
        /> : null
      }
    </div>)
  }
}

export default MyPosts;