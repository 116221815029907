import React from 'react';
import '../../../assets/css/Settings.css'
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'

class UploadTutorials extends React.Component {
    constructor() {
        super()
        this.state = {
            title: "",
            link: "",
            thumbnail: null
        }
    }

    submitTutorials = () => {
        const formData = new FormData();
        formData.append('title', this.state.title);
        formData.append('link', this.state.link);
        formData.append('thumbnail', this.state.thumbnail);
        formData.append('userId', this.props.UserInfo.UserID);
        Axios.post(Constants.BASE_URL + "api/MarketPlace/uploadtutorials", formData).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Uploaded Tutorial Successfully',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.props.history.push(Constants.SettingsUploadTutorials)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Error while uploading tutorial !!',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        })
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    setImage = (key, value) => {
        if (value !== undefined) {
            this.setState({
                [key]: value
            }, () => {
                var reader = new FileReader();
                let fileDetails = value
                const sourceDiv = document.getElementById("previewA");
                sourceDiv.innerHTML = ""
                if (fileDetails.type === 'image/png' || fileDetails.type === 'image/jpg' || fileDetails.type === 'image/jpeg') {
                    reader.onload = function (e) {
                        var img = document.createElement("img");
                        img.title = fileDetails.name;
                        img.src = e.target.result;
                        sourceDiv.appendChild(img);
                    };
                    reader.readAsDataURL(value);
                } else {

                }
            })
        } else {
            this.setState({
                [key]: null
            })
        }
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="pl-5 pr-5 settingsEmailConfig userRegistration settingsUsersList tutorials">
                    <div className="row userTitle">
                        <div className="col-12">
                            <p className="m-0 userlistlabel">Upload Tutorials</p>
                            <p className="m-0 userlistSublabel">Learn more about baetho</p>
                        </div>
                    </div>
                    <div className="row">
                        <form className="w-100">
                            {/* <div className="col-12 text-center mt-4">
                                <input className="hide" type="file" name="fileUpload" id="fileUpload" onChange={(e) => this.setImage("thumbnail", e.target.files[0])} />
                                {this.state.thumbnail ?
                                    <label htmlFor='fileUpload'> <div id="previewA"></div></label> :
                                    <div>
                                        <label htmlFor='fileUpload'>  <img src={ProfileDup} alt="profilePic" /></label>
                                        <p className="uplaodProfilePic mt-1">Upload Profile picture</p>
                                    </div>}
                            </div> */}
                            <div className="col-12">
                                <input type="file" name="fileUpload" id="fileUpload" onChange={(e) => this.changeValue("thumbnail", e.target.files[0])} />
                            </div>
                            <div className="col-12">
                                <input type="text" placeholder="Title" onChange={(e) => this.changeValue("title", e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <input type="text" placeholder="Link" onChange={(e) => this.changeValue("link", e.target.value)} required />
                            </div>

                            <div className="col-12">
                                <button type="button" onClick={this.submitTutorials} className="addUserSubmitBtn" >Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default UploadTutorials