import React from 'react';
import Axios from 'axios'
import * as Constants from '../../assets/helpers/constants'
import { withRouter, Link, Route } from "react-router-dom";

// Forum Links 
import AllPosts from './allPosts'
import MyPosts from './myPosts'
import PostDetails from './postDetails'
import AddPost from './addPost'
import '../../assets/css/ForumDashboard.css'
import logoImg from '../../assets/images/logo.png';

class Forum extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            UserInfo: {},
            activeLink: "allposts",
            allPostsList: [],
            myPostsList: [],
            userId : null
        }
    }

    componentDidMount() {
        if (localStorage.getItem("userDetails") === null) {
            this.props.history.push(Constants.Login);
        } else {
            let activeLink = "allposts"
            let userDetails = JSON.parse(localStorage.getItem("userDetails"))
            if (this.props.location.pathname === Constants.MyPosts) {
                activeLink = 'myposts'
            }
            this.setState({
                UserInfo: userDetails[0],
                activeLink: activeLink,
                userId : userDetails[0].UserID
            })
        }
    }

    getAllPosts = () => {
        Axios.get(Constants.BASE_URL + `api/ForumDashboard/getAllPosts`).then((result) => {
            this.setState({
                allPostsList: result.data,
            })
        })
    }

    getMyPosts = (UserId = this.state.userId) => {
        Axios.get(Constants.BASE_URL + `api/ForumDashboard/MyPosts/${UserId}`).then((result) => {
            this.setState({
                myPostsList: result.data.length > 0 ? result.data : [],
            })
        })
    }

    searchAllPostsText = (e) => {
        var data = {
            searchValue: e.target.value
        }
        Axios.post(Constants.BASE_URL + 'api/ForumDashboard/searchData', data).then((result) => {
            this.setState({
                allPostsList: result.data.data
            })
        })
    }

    searchMyPostsText = (e) => {
        var data = {
            searchValue: e.target.value
        }
        Axios.post(Constants.BASE_URL + 'api/ForumDashboard/searchData', data).then((result) => {
            this.setState({
                myPostsList: result.data.data
            })
        })
    }

    setValue = (value) => {
        this.setState({
            activeLink: value
        })
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row forumDashboard">
                    <div className="col-2 leftPanel">
                        <div className="row">
                            <div className="col-12">
                                <img src={logoImg} alt="logo" className="logoImg" />
                            </div>
                            <div className="col-12 pl-5 navLinks">
                                <p className="title">Discussion Forum</p>
                                <Link to={Constants.AllPosts} className={this.state.activeLink === 'allposts' ? "disabled-link" : null} onClick={() => this.setValue("allposts")}>
                                    <button type="button" className={this.state.activeLink === 'allposts' ? "active" : null} > All Posts</button>
                                </Link>
                                <Link to={Constants.MyPosts} className={this.state.activeLink === 'myposts' ? "disabled-link" : null} onClick={() => this.setValue("myposts")}>
                                    <button type="button" className={this.state.activeLink === 'myposts' ? "active" : null} > My Posts</button>
                                </Link>
                                <Link to={Constants.Contents}>
                                    <button type="button" > CMS</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-7 contentPanel">
                        <Route path={Constants.AllPosts} render={() => <AllPosts searchAllPostsText={this.searchAllPostsText} getAllPosts={this.getAllPosts} allPostsList={this.state.allPostsList}  {...this.props} />} />
                        <Route path={Constants.MyPosts} render={() => <MyPosts searchMyPostsText={this.searchMyPostsText} getMyPosts={this.getMyPosts} myPostsList={this.state.myPostsList} {...this.props} />} />
                    </div>
                    <div className="col-md-3 rightPanel p-0">
                        <Route exact path={Constants.MyPosts + "/CreatePost"} component={() => <AddPost getMyPosts={this.getMyPosts}   UserInfo={this.state.UserInfo}  {...this.props} />} />
                        <Route path={[Constants.AllPosts + "/Details/:id", Constants.MyPosts + "/Details/:id"]} component={() => <PostDetails  getAllPosts={this.getAllPosts}  getMyPosts={this.getMyPosts} UserInfo={this.state.UserInfo}  {...this.props} />} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Forum)