import React from 'react';
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
// import SidePanelFooter from './sidepanelFooter'
import Swal from 'sweetalert2'

import jpgIcon from '../../../assets/images/Group 498@2x.png'
import pngIcon from '../../../assets/images/Group 500@2x.png'
import pdfIcon from '../../../assets/images/Group 499@2x.png'
import xmlIcon from '../../../assets/images/Group 502@2x.png'
import mp4Icon from '../../../assets/images/Group 504@2x.png'
import flvIcon from '../../../assets/images/Group 495@2x.png'
import mp3Icon from '../../../assets/images/Group 501@2x.png'
import gifIcon from '../../../assets/images/Group 520@2x.png'

import folderIcon from '../../../assets/images/folder.png'
import dropdownImg from '../../../assets/images/Untitled-1-10.png'

class MediaDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            media: [],
            mediaId: null,
            requiredType: null,
            CompanyName: null,
            extensionRequired: null,
            breadCrumbLength: 0,
            filteredMedia: [],
            location: null,
            jpegFiles: [],
            pngFiles: [],
            pdfFiles: [],
            xmlFiles: [],
            foldersFiles: [],
            otherFormats: [],
            files: [],
            filteredFiles: []
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            let url = this.props.location.pathname.split('/')
            const requiredType = url[url.length - 2]
            const CompanyName = url[url.length - 5]
            const mediaId = url[url.length - 1]
            const extension = url[url.length - 3]
            this.setState({
                CompanyName: CompanyName,
                requiredType: requiredType,
                extensionRequired: extension,
                mediaId: mediaId
            }, () => {
                this.getMediaLinks(CompanyName)
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getMediaLinks = (CompanyName) => {
        let data = {
            "CompanyName": CompanyName,
            "requiredType": this.state.requiredType,
            'extensionRequired': this.state.extensionRequired
        }
        Axios.post(Constants.BASE_URL + 'api/mediaManagement/getmediaLinksdetails', data).then((result) => {
            if (result.data.status) {
                if (result.data.info.length > 0) {
                    this.setState({
                        media: result.data.info,
                        filteredMedia: result.data.info,
                        CompanyName: CompanyName,
                        location: CompanyName,
                        breadCrumb: CompanyName,
                    })
                } else {
                    this.setState({
                        media: [],
                        filteredMedia: [],
                        CompanyName: CompanyName,
                        location: CompanyName,
                        breadCrumb: CompanyName,
                    })
                }
            }
        })
    }

    linkMedia = (mediaLocation) => {
        let data = {
            "mediaId": this.state.mediaId,
            "location": mediaLocation,
            "userId": this.props.UserInfo.UserID
        }
        Axios.put(Constants.BASE_URL + 'api/mediaManagement/setMediaLocation', data).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Media Linked Successfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                this.props.getFileDetails()
            }
        })
    }

    filterMedia = (e) => {
        let currentMedia = this.state.media
        let latestMedia = currentMedia.filter((media) => {
            return media.name.toLowerCase().includes(e.target.value.toLowerCase())
        })
        this.setState({
            filteredMedia: latestMedia
        })
    }

    navigateFolder = (folderName) => {
        let location = this.state.CompanyName + "/" + folderName
        let locationSplit = location.split("/")
        var x = locationSplit.join("/")
        this.setState({
            CompanyName: this.state.CompanyName + "/" + folderName,
            breadCrumb: x,
            breadCrumbLength: locationSplit.length,
            files: []
        }, () => {
            this.getMediaLinks(this.state.CompanyName)
        })
    }

    navback = () => {
        let currentLocation = this.state.CompanyName;
        let locationSplit = currentLocation.split("/")
        locationSplit.pop()
        let finalLocation = locationSplit.join("/")
        var x = locationSplit.join("/")
        this.setState({
            location: finalLocation,
            CompanyName: finalLocation,
            breadCrumb: x,
            breadCrumbLength: locationSplit.length,
            files: []
        }, () => {
            this.getMediaLinks(finalLocation)
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="mediaLinkDetails">
                    <div className="signin pl-5 ">
                        <div className="row">
                            <div className="col-12 p-0">
                                <label className="searchDiv"><i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                    <input type="text" placeholder="Search keyword" onChange={this.filterMedia} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {this.state.breadCrumbLength > 1 ? (<label className="backbutton" onClick={this.navback}> <span><img src={dropdownImg} alt="backbutton" /></span>BACK  {(this.state.breadCrumb !== "" && this.state.breadCrumb !== null) ? <span>( {this.state.breadCrumb})</span> : null} </label>) : null}
                            </div>
                            <div className="col-12">
                                <p className="title m-0">Link Media</p>
                                <p className="subTitle">Link media and content for the experience</p>
                            </div>
                        </div>
                        <div className="mediaContainer">
                            {this.state.requiredType === 'folder' ? (this.state.filteredMedia.map((media, index) => {
                                return (
                                    <div key={index} className="row individualmediaLink" >
                                        <div className="col-3">
                                            <img className="mediaIcon" src={folderIcon} alt="folderIcon" />
                                        </div>
                                        <div className="col-9">
                                            <p className="m-0 p-0 title">{media.name}</p>
                                            <button type="button" className="linkbutton" onClick={() => this.navigateFolder(media.name)} >Open</button>
                                            <button type="button" className="linkbutton" title={media.name} onClick={() => this.linkMedia(media.link)}>Link</button>
                                        </div>
                                    </div>
                                )
                            })) : null}

                            {this.state.requiredType === 'file' ? (this.state.filteredMedia.map((media, index) => {
                                let icon = null
                                if (media.extension === ".png" || media.extension === ".PNG") {
                                    icon = pngIcon
                                } else if (media.extension === ".pdf" || media.extension === ".PDF") {
                                    icon = pdfIcon
                                } else if (media.extension === ".xml" || media.extension === ".XML") {
                                    icon = xmlIcon
                                } else if (media.extension === ".jpg" || media.extension === ".JPG") {
                                    icon = jpgIcon
                                } else if (media.extension === ".mp4" || media.extension === ".MP4") {
                                    icon = mp4Icon
                                } else if (media.extension === ".mp3" || media.extension === ".MP3") {
                                    icon = mp3Icon
                                } else if (media.extension === ".flv" || media.extension === ".FLV") {
                                    icon = flvIcon
                                } else if (media.extension === ".gif" || media.extension === ".GIF") {
                                    icon = gifIcon
                                } else {
                                    icon = folderIcon
                                }
                                return (
                                    // media.extension.toLowerCase() === "."+this.state.requiredType.toLowerCase() || media.extension === '' ? 
                                    <div key={index} className="row individualmediaLink" >
                                        <div className="col-3">
                                            <img className="mediaIcon" src={icon} alt="folderIcon" onClick={() => this.navigateFolder(media.name)} />
                                        </div>
                                        <div className="col-9">
                                            <p className="m-0 p-0 title">{media.name}</p>
                                            {media.extension === '' ? <button type="button" className="linkbutton" onClick={() => this.navigateFolder(media.name)} >Open</button> : <button type="button" className="linkbutton" onClick={() => this.linkMedia(media.link)}>Link</button>}
                                        </div>
                                    </div>
                                    // : null
                                )
                            })) : null}
                        </div>
                        {/* <SidePanelFooter /> */}
                    </div>
                </div>
            </div>
        )

    }
}

export default MediaDetails