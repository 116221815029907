import React from 'react'
import Axios from 'axios';
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import addUp from '../../../assets/images/AddUp.png'
import leftArrow from '../../../assets/images/Untitled-1-10.png'
import Swal from 'sweetalert2'

class AdminSoftwareRelease extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAddNewVersion: false,
            versionsList: [],
            filteredVersionsList: [],
            type: "",
            OSType: "",
            versionNo: "",
            uploadedFile: null,
            isComposer: false,
            isPlayer: false,
        }
    }

    componentDidMount = () => {
        if (this.props.UserInfo !== undefined) {
            this.setState({
                isLoading: true
            }, () => {
                this.getVersions()
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getVersions = () => {
        Axios.get(Constants.BASE_URL + `api/AdminDashboard/getSoftwareVersions`).then((result) => {
            if (result.data.status) {
                this.setState({
                    versionsList: result.data.info,
                    filteredVersionsList: result.data.info,
                    isLoading: false
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong.Failed to fetch versions!!',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isLoading: false
                })
            }
        });
    }


    uploadNewVersion = () => {
        this.setState({
            isAddNewVersion: true
        })
    }

    navBack = () => {
        this.setState({
            isAddNewVersion: false,
            type: "",
            OSType: "",
            versionNo: "",
            uploadedFile: null
        }, () => {
            this.getVersions()
        })
    }

    submitNewversion = () => {
        if (this.state.versionNo === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please Enter Version No",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.type === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please select Type",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.uploadedFile === null) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please choose the file ",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.OSType === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please Select the OS for the Release",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        this.setState({
            isLoading: true
        }, () => {

            const formData = new FormData();
            formData.append('fileUpload', this.state.uploadedFile);
            formData.append('type', this.state.type);
            formData.append('OSType', this.state.OSType);
            formData.append('versionNo', this.state.versionNo);
            formData.append('userId', this.props.UserInfo.UserID);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            Axios.post(Constants.BASE_URL + `api/AdminDashboard/uploadNewSoftwareVersion`, formData, config).then((result) => {
                if (result.data.status) {
                    if (result.data.info === 'Success') {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: result.data.info,
                            showConfirmButton: false,
                            timer: 1000
                        })
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.navBack()
                        })
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: result.data.info,
                            showConfirmButton: false,
                            timer: 1000
                        })
                        this.setState({
                            isLoading: false
                        })

                    }
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Something went wrong.Please try after sometime!!",
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading: false
                    })
                }
            });
        })


    }

    setValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    changeVersionList = (key) => {
        this.setState({
            [key]: !this.state[key],
            isLoading: true
        }, () => {
            let versionsList = this.state.versionsList
            let newArray = []
            if (this.state.isComposer && !this.state.isPlayer) {
                newArray = versionsList.filter(function (item) {
                    return item.Type === 'Composer';
                });
            } else if (this.state.isPlayer && !this.state.isComposer) {
                newArray = versionsList.filter(function (item) {
                    return item.Type === 'Player';
                });
            } else {
                newArray = versionsList
            }
            this.setState({
                filteredVersionsList: newArray,
                isLoading: false
            })
        })
    }


    render() {
        return (
            <div className="rightSidePanel slide">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}

                {!this.state.isAddNewVersion ? (
                    <div className="pl-5 pr-5 reports adminsoftwareRelease">
                        <div className="row">
                            <div className="col-12 p-0">
                                <label className="searchDiv"><i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                    <input type="text" placeholder="Search keyword" />
                                </label>
                            </div>
                        </div>
                        <div className="row userTitle">
                            <div className="col-12">
                                <p className="m-0 userlistlabel">Versions Released</p>
                                <p className=" userlistSublabel">All available software versions for release to users</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onChange={() => this.changeVersionList("isComposer")} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Composer</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" onChange={() => this.changeVersionList("isPlayer")} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox2">Player</label>
                                </div>
                            </div>
                        </div>
                        <div className="row versionsDiv">
                            <div className="col-12">
                                {this.state.filteredVersionsList.length > 0 ? this.state.filteredVersionsList.map((version) => {
                                    return (
                                        <div className="row softwareDetails">
                                            <div className="col-12">
                                                <label className="w-100">
                                                    <span className="softwareType">{version.Type}</span>
                                                    <span className="versionNo">V{version.Version}</span>
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }) : <div>No Data found</div>}
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="col-12 text-center w-100">
                                <div className="uploadNewVersion" onClick={this.uploadNewVersion}>
                                    <label>
                                        <span><img className="uploadNewVersionImg" src={addUp} alt="AddUp" /></span>
                                        <span className="uploadNewVersionText">Upload new version</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <hr className="footerDivHR" />
                        <div className="row helpSection footerDiv">
                            <div className="col-12 text-center">
                                <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                )
                    : (
                        <div className="addNewVersionDiv">
                            <div className="row">
                                <label className="backButton" onClick={this.navBack}><img src={leftArrow} alt="leftArrow" /> <span> BACK </span></label>
                            </div>
                            <div className="row uploadNewVersionDiv">
                                <div className="col-12 p-0">
                                    <p className="m-0 uploadNewVersionText p-0">Upload New Version</p>
                                    <p className=" uploadNewVersionSubText">All software versions available for release to users</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 pl-0">
                                    <form className="uploadnewVersionForm">
                                        <input type="text" name="versionNo" placeholder="Software Version" value={this.state.versionNo} onChange={(e) => this.setValue("versionNo", e.target.value)} />
                                        <select value={this.state.type} onChange={(e) => this.setValue("type", e.target.value)}>
                                            <option disabled value="">Type</option>
                                            <option value="Player">Player</option>
                                            <option value="Composer">Composer</option>
                                        </select>
                                        <input type="file" name="uploadFile" onChange={(e) => this.setValue("uploadedFile", e.target.files[0])} />
                                        <select value={this.state.OSType} onChange={(e) => this.setValue("OSType", e.target.value)}>
                                            <option disabled value="">OS Type</option>
                                            <option value="Windows">Windows</option>
                                            <option value="IOS">IOS</option>
                                            <option value="Android">Android</option>
                                        </select>
                                        <button type="button" className="uploadnewRevBtn" onClick={this.submitNewversion}>Upload</button>
                                    </form>
                                </div>
                            </div>

                        </div>
                    )
                }



            </div>)
    }
}

export default AdminSoftwareRelease