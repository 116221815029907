import React from 'react'
import { Button } from 'react-bootstrap'
import Axios from 'axios'
import * as Constants from '../../assets/helpers/constants'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Swal from 'sweetalert2'

const animatedComponents = makeAnimated();
class AddPost extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      topic: "",
      content: "",
      tags: [],
      category: [],
      tagsDropdown: [],
      categoryDropdown: []
    }
  }

  componentDidMount() {

    if (localStorage.getItem("userDetails") !== null) {
      Axios.get(Constants.BASE_URL + `api/ForumDashboard/Tags`).then((result) => {
        let tagDetails = []
        result.data.forEach(element => {
          let tagObject = {
            label: element.Name,
            value: element.Name
          }
          tagDetails.push(tagObject)
        });
        this.setState({
          tagsDropdown: tagDetails
        })
      });

      Axios.get(Constants.BASE_URL + `api/ForumDashboard/Category`).then((result) => {
        let categoryDetails = []
        result.data.forEach(element => {
          let categoryObject = {
            label: element.Name,
            value: element.Name
          }
          categoryDetails.push(categoryObject)
        });
        this.setState({
          categoryDropdown: categoryDetails
        })
      });
    } else {
      this.props.history.push('/')
    }
  }

  submit = () => {
    var UserId = ""

    var x = JSON.parse(localStorage.getItem("userDetails"))
    UserId = x[0].UserID

    let data = {
      UserId: UserId,
      Topic: this.state.topic,
      Content: this.state.content,
      Tags: JSON.stringify(this.state.tags),
      Category: JSON.stringify(this.state.category),
    }

    Axios.post(Constants.BASE_URL + "api/ForumDashboard/insertPost", data).then((result) => {
      if (result.data.status) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Post created successfully',
          showConfirmButton: false,
          timer: 1000
      })
        this.props.getMyPosts()
        this.props.history.push(Constants.MyPosts);
      }else{
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Something went wrong ..Please try again',
          showConfirmButton: false,
          timer: 1000
      })
        // alert("Something went wrong ..Please try again")
      }
    });

  }

  onChange = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  setTags = (value, key) => {
    this.setState({
      [key]: value
    })
  }

  changeTagSelect = (value, key) => {
    this.setState({
      tags: value
    })
  }

  changeCategorySelect = (value, key) => {
    this.setState({
      category: value
    })
  }

  render() {
    let TagDropdown = ""
    if (this.state.tagsDropdown.length > 0) {
      TagDropdown = <Select
        onChange={this.changeTagSelect}
        closeMenuOnSelect={false}
        components={animatedComponents}
        // defaultValue={[colourOptions[4], colourOptions[5]]}
        isMulti
        placeholder="Select Tags"
        options={this.state.tagsDropdown}
      />
    }

    let CategoryDropdown = ""
    if (this.state.categoryDropdown.length > 0) {
      CategoryDropdown = <Select
        onChange={this.changeCategorySelect}
        closeMenuOnSelect={false}
        components={animatedComponents}
        // defaultValue={[colourOptions[4], colourOptions[5]]}
        isMulti
        placeholder="Select Categories"
        options={this.state.categoryDropdown}
      />
    }

    return (<div className="Content createPost" >
      <div className="row userTitle">
        <div className="col-12">
          <p className="m-0 userlistlabel">New Post</p>
          <p className=" userlistSublabel">Please complete to form to add a new post</p>
        </div>
      </div>
      <div className="row">
        <div className="form">
          <div className="col-12">
            <input type="text" placeholder="Topic" name="Topic" onChange={(e) => { this.onChange("topic", e.target.value) }} required/>

          </div>
          <div className="col-12">
            <input className="m-0" type="text" name="question" placeholder="Question Description" onChange={(e) => { this.onChange("content", e.target.value) }}  required/>
          </div>
          <div className="col-12">
            {TagDropdown}
          </div>
          <div className="col-12">
            {CategoryDropdown}
          </div>
          <div className="col-12">
            <Button className='submitButton' variant="success" size='sm' onClick={this.submit}>Create Post</Button>
          </div>
        </div>



      </div>
    </div>

    )
  }
}

export default AddPost;