import React from 'react';
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'

class ResetPassword extends React.Component {
    constructor() {
        super()
        this.state = {
            uniqueId: "",
            password: "",
            rePassword: "",
            title : ""
        }
    }

    componentDidMount() {
        var url = this.props.location.pathname;
        let uniqueId = url.substring(url.lastIndexOf('/') + 1)
        let pageType = url.substring(url.lastIndexOf('/') + 2)
        let title = ""
         // Partner => Set Password
        // Forget password => Password Recover
        if(pageType === "forgetPassword"){
            title = "Password Recovery"
        }else{
            title = "Set Password"
        }
       
        
        this.setState({
            uniqueId: uniqueId,
            title: title
        })
    }

    changeValue = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }

    resetPassword = (e) => {
        e.preventDefault();
        if(this.state.password.trim() === "" || this.state.rePassword.trim() === "" ){
              Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Please enter both the fields",
                    showConfirmButton: false,
                    timer: 1000
                })
            return false
        }

        if (this.state.password === this.state.rePassword) {
            let data = {
                uniqueId: this.state.uniqueId,
                password: this.state.password
            }

            Axios.put(Constants.BASE_URL + "api/resetPassword", data).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Password changed sucessfully",
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.props.history.push('/Login');
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: result.data.info,
                        showConfirmButton: false,
                        timer: 1000
                    })
                }
            });
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Both Passwords should be same",
                showConfirmButton: false,
                timer: 1000
            })
        }
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="signin pl-5 resetPassword">
                    <p className="loginTitle mb-0">{this.state.title}</p>
                    <p className="loginsubTitle mb-0">Set a new password for your account.</p>
                    <form className="signin_form" onSubmit ={this.resetPassword}>
                        <div className="row">
                            <div className="col-12">
                                <input type="password" className="newPassword" placeholder="Enter New Password" value={this.state.password} onChange={(e) => this.changeValue("password", e)} required/>
                            </div>
                            <div className="col-12">
                                <input type="password" className="confirmPassword" placeholder="Confirm Password" value={this.state.rePassword} onChange={(e) => this.changeValue("rePassword", e)} required />
                            </div>
                        </div>
                        <div className="row submitButtonSection" >
                            <div className="col-12">
                                <button type="submit" className="submitNewpassword" >Save & Login</button>
                            </div>
                        </div>
                        <p className="formFooter">Term of use. Privacy policy</p>
                    </form>
                </div>
            </div>
        )
    }
}

export default ResetPassword