import React from 'react'
import Axios from 'axios';
import Swal from 'sweetalert2'

import * as Constants from '../../../../assets/helpers/constants'
import BackIcon from '../../../../assets/images/Untitled-1-10.png'
import ExperienceDownload from '../../../../assets/images/Group 1029.png'
import ProjectDownload from '../../../../assets/images/Group 1030.png'

import ShareProject from '../../../../assets/images/Group 1121@2x.png'


import HeartIcon from '../../../../assets/images/Group 1120@2x.png'
import HeartIconDown from '../../../../assets/images/Group 1116@2x.png'

import BookmarkIcon from '../../../../assets/images/Group 1122@2x.png'
import BookmarkIconDown from '../../../../assets/images/Group 1118@2x.png'


export default class ProjectDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isdiscover: false,
            comment: "",
            existingComments: []
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            if (this.props.location.pathname.includes(Constants.Discover)) {
                this.setState({
                    isdiscover: true
                })
            }
            this.props.getProjectDetails()
            if (this.props.projectInfo.projectsDetails !== null) {
                this.getComments(this.props.projectInfo.projectsDetails.Id)

            }
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getComments = (projectId) => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/projectComments/${projectId}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    existingComments: result.data.info
                })
            } else {
                this.setState({
                    existingComments: []
                })
            }
        })
    }

    navigate = () => {
        if (this.props.location.pathname.includes(Constants.Discover)) {
            this.props.setLink('discover')
            this.props.history.push(Constants.Discover)
        } else if (this.props.location.pathname.includes(Constants.MyProjects)) {
            this.props.setLink('projects')
            this.props.history.push(Constants.MyProjects)
        } else if (this.props.location.pathname.includes(Constants.DownloadComposer)) {
            this.props.setLink(null)
            this.props.history.push(Constants.MarketPlace)
        } else {
            this.props.setLink('people')
            this.props.history.push(Constants.People)
        }
    }

    downloadExperience = () => {
        let link = this.props.projectInfo.experienceDetails.Link
        window.open(
            link,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    downloadProject = () => {
        let link = this.props.projectInfo.projectsDetails.Link
        window.open(
            link,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    secondsToDhms = (seconds) => {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor(seconds % (3600 * 24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
        var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
        return dDisplay + hDisplay + mDisplay;
    }

    likeProject = (projectId, devisionId) => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/likeProject/${this.props.UserInfo.UserID}/${projectId}`).then((result) => {
            if (result.data.status) {
                const parent = document.getElementById(devisionId);
                const image = parent.querySelector('.heartIconDiv1 .imagesection')
                image.innerHTML = ""
                let newImage = document.createElement('img')
                newImage.src = HeartIcon
                newImage.alt = "HeartIcon"
                newImage.onclick = () => this.unlikeProject(projectId, devisionId)
                image.appendChild(newImage)

            }
        })
    }

    unlikeProject = (projectId, devisionId) => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/unlikeProject/${this.props.UserInfo.UserID}/${projectId}`).then((result) => {
            if (result.data.status) {
                const parent = document.getElementById(devisionId);
                const image = parent.querySelector('.heartIconDiv1 .imagesection')
                image.innerHTML = ""
                let newImage = document.createElement('img')
                newImage.src = HeartIconDown
                newImage.alt = "HeartIconDown"
                newImage.onclick = () => this.likeProject(projectId, devisionId)
                image.appendChild(newImage)


            }
        })
    }

    saveProject = (projectId, devisionId) => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/saveProject/${this.props.UserInfo.UserID}/${projectId}`).then((result) => {
            if (result.data.status) {
                const parent = document.getElementById(devisionId);
                const image = parent.querySelector(' .bookmarksection')
                image.innerHTML = ""
                let newImage = document.createElement('img')
                newImage.src = BookmarkIcon
                newImage.alt = "BookmarkIcon"
                newImage.onclick = () => this.unsaveProject(projectId, devisionId)
                image.appendChild(newImage)
            }
        })
    }

    unsaveProject = (projectId, devisionId) => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/unsaveProject/${this.props.UserInfo.UserID}/${projectId}`).then((result) => {
            if (result.data.status) {
                const parent = document.getElementById(devisionId);
                const image = parent.querySelector('.bookmarksection')
                image.innerHTML = ""
                let newImage = document.createElement('img')
                newImage.src = BookmarkIconDown
                newImage.alt = "BookmarkIconDown"
                newImage.onclick = () => this.saveProject(projectId, devisionId)
                image.appendChild(newImage)
            }
        })
    }

    addComment = () => {
        let data = {
            userId: this.props.UserInfo.UserID,
            comment: this.state.comment,
            projectId: this.props.projectInfo.projectsDetails.Id
        }
        Axios.post(Constants.BASE_URL + `api/MarketPlace/addProjectComment`, data).then((result) => {
            if (result.data.status) {
                this.setState({
                    comment: "",
                    existingComments: []
                }, () => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Successfully posted Commnet",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.getComments(this.props.projectInfo.projectsDetails.Id)
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Error in posting comment.Please try later",
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        })
    }

    setValue = (value) => {
        this.setState({
            comment: value === "" ? null : value
        })
    }

    render() {
        return (
            <div className="projectDetails">
                <div className="row">
                    <div className="col-1 " onClick={this.navigate}>
                        <div className="backDiv"><img src={BackIcon} alt="BackIcon" className="backIcon" /><p className="backBtnText">Back</p></div>
                    </div>
                </div>
                <div className="row">
                    <div className="sideBtnsProjectDetails">
                        <img src={ExperienceDownload} alt="Download Experience" onClick={this.downloadExperience} />
                        <p className="expTest m-0">Experience</p>
                        <img src={ProjectDownload} alt="Download Project Download" onClick={this.downloadProject} />
                        <p className="expTest m-0">Project</p>
                    </div>
                    <div className="col">
                        <div className="row projectInformation">
                            <div className="col-12">
                                <div className="row projectTitleDetails">
                                    <div className="col-4">
                                        <p className="projectTitle">{this.props.projectInfo.projectsDetails !== null ? this.props.projectInfo.projectsDetails.Name : null}</p>
                                    </div>
                                    <div className="col-8">
                                        <p className="projectDesc">
                                            {this.props.projectInfo.projectsDetails !== null ? this.props.projectInfo.projectsDetails.Description : null}
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" id="mediaDetails">
                                        <div className="row">
                                            {this.props.projectInfo.images.length > 0 ? (
                                                this.props.projectInfo.images.map((image, index) => {
                                                    return <div className="col-6 mediaDiv" key={index}><img className="projectImages" alt={image.name} src={image.Link} /> </div>
                                                })
                                            ) : null}

                                            {this.props.projectInfo.videos.length > 0 ? (
                                                this.props.projectInfo.videos.map((image, index) => {
                                                    return <div className="col-6 mediaDiv" key={index}><video className="projectImages" alt={image.name} src={image.Link} controls /> </div>
                                                })
                                            ) : null}

                                            {/* {this.state.existingComments.length > 0 ? ( */}
                                                <div className="col-12 commentsSection">
                                                    <div className="row">
                                                        <div className="col-2 profilePicComment">
                                                            <img src={this.props.UserInfo.ProflePic} alt="ProfilePic" />
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="commentSection">
                                                                <textarea onChange={(e) => this.setValue(e.target.value)} className="w-100" rows={5} cols={5} placeholder="Express your opinion">{this.state.comment}</textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <button type="button" className="commentBtn" onClick={this.addComment} disabled={this.state.comment === "" ? true : false}>
                                                                Comment
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            {/* ) : null} */}
                                            <div className="col-12 mt-5">
                                                <p className="commentsText">Comments</p>
                                            </div>
                                            <div className="col-12 commentsSection">
                                                {this.state.existingComments.length > 0 ? (
                                                    this.state.existingComments.map((comment, index) => {
                                                        let currentDate = new Date()
                                                        let createdDate = new Date(comment.Created_On)
                                                        let time = Math.ceil(Math.abs(currentDate - createdDate) / (1000))
                                                        return (
                                                            <div className="row mb-3">
                                                                <div className="col-2 profilePicComment">
                                                                    <img src={comment.Profile_Pic} alt="ProfilePic" />
                                                                </div>
                                                                <div className="col-8">
                                                                    <p className="commentName">{comment.UserName}</p>
                                                                    <p className="commentValue">{comment.Comment}</p>
                                                                </div>
                                                                <div className="col-2">
                                                                    <p className="commentValue">
                                                                        {this.secondsToDhms(time)} ago
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                ) : <div>No Commments</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sideBtnsProjectDetails" id={"proj_20"}>
                        {this.state.isdiscover ? (
                            <label className="heartIconDiv1" >
                                {this.props.projectInfo.projectsDetails && this.props.projectInfo.projectsDetails.existsLike ? (
                                    <span className="imagesection">
                                        <img src={HeartIcon} alt="HeartIcon" onClick={() => this.unlikeProject(this.props.projectInfo.projectsDetails.Id, "proj_20")} />
                                    </span>
                                ) : (
                                    <span className="imagesection">
                                        <img src={HeartIconDown} alt="HeartIcon" onClick={() => this.likeProject(this.props.projectInfo.projectsDetails.Id, "proj_20")} />
                                    </span>
                                )}
                                <p className="expTest">Like</p>
                            </label>
                        )
                            : null}
                        <label className="heartIconDiv1" >
                            <span className="bookmarksection1">
                                <img src={ShareProject} alt="Share Project" />
                                <p className="expTest">Share</p>

                            </span>
                        </label>
                        {this.state.isdiscover ? (
                            <label className="bookmarkIconDiv1 " >
                                {this.props.projectInfo.projectsDetails && this.props.projectInfo.projectsDetails.existsSaved ? (
                                    <span className="bookmarksection">
                                        <img src={BookmarkIcon} alt="BookmarkIcon" onClick={() => this.unsaveProject(this.props.projectInfo.projectsDetails.Id, "proj_20")} />
                                    </span>
                                ) : (
                                    <span className="bookmarksection">
                                        <img src={BookmarkIconDown} alt="BookmarkIcon" onClick={() => this.saveProject(this.props.projectInfo.projectsDetails.Id, "proj_20")} />
                                    </span>
                                )}
                                <p className="expTest">Save</p>

                            </label>

                        ) : null}
                    </div>
                </div>
            </div>)
    }
}