import React from 'react';
import Axios from 'axios';
import * as Constants from '../../../assets/helpers/constants'
import '../../../assets/css/ExperienceDashboard.css'
import jpgIcon from '../../../assets/images/JPGUp.png'
import pngIcon from '../../../assets/images/PNGUp.png'
import pdfIcon from '../../../assets/images/PDFUp.png'
import xmlIcon from '../../../assets/images/XMLUp.png'
import folderIcon from '../../../assets/images/folder.png'
import dropdownImg from '../../../assets/images/Untitled-1-10.png'
import linkedFolderIcon from '../../../assets/images/Group 525@2x.png'
import Swal from 'sweetalert2'

class MediaManagement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLinkPanelOpen: false,
            companyName: null,
            experienceName: null,
            location: null,
            files: [],
            jpeg: 0,
            png: 0,
            pdf: 0,
            xml: 0,
            jpegFiles: [],
            pngFiles: [],
            pdfFiles: [],
            xmlFiles: [],
            foldersFiles: [],
            otherFormats: [],
            breadCrumb: null
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            let url = this.props.location.pathname.split('/')
            let startURL = url.splice(3);
            let endUrl = ""
            if (startURL.includes('file') || startURL.includes('folder')) {
                endUrl = startURL.splice(0, startURL.length - 2)
            } else {
                endUrl = startURL
            }

            if (url.length > 2) {
                const ExpName = endUrl[1]
                const CompanyName = endUrl[0]
                if (CompanyName !== "folder" && CompanyName !== "file") {
                    let data = {
                        "location": endUrl.join("/")
                    }
                    Axios.post(Constants.BASE_URL + 'api/mediaManagement/getMediaofExperience', data).then((result) => {
                        if (result.data.status) {
                            this.setState({
                                location: CompanyName + "/" + ExpName,
                                companyName: CompanyName,
                                experienceName: ExpName
                            })
                            this.props.getMediaFromDb(CompanyName, ExpName);
                        }
                    })
                } else {
                    this.setState({
                        location: CompanyName + "/" + ExpName,
                        companyName: CompanyName,
                        experienceName: ExpName
                    })
                }
            }
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    navigateFolder = (folderName) => {
        let prevLocation = this.state.location
        let x = ""
        let location = this.state.location + "/" + folderName
        let locationSplit = location.split("/")
        if (locationSplit[locationSplit.length - 2] === "others") {
            let locationSplit1 = prevLocation.split("/")
            locationSplit1.splice(0, 2)
            x = locationSplit1.join("/")
            this.setState({
                location: prevLocation,
                breadCrumb: x,
                files: []
            }, () => {
                this.props.getFileDetails(x, this.state.experienceName, this.state.companyName)
            })
        } else {
            locationSplit.splice(0, 2)
            x = locationSplit.join("/")
            this.setState({
                location: this.state.location + "/" + folderName,
                breadCrumb: x,
                files: []
            }, () => {
                this.props.getFileDetails(x, this.state.experienceName, this.state.companyName)
            })
        }
    }

    navback = () => {
        let currentLocation = this.state.location;
        let locationSplit = currentLocation.split("/")
        if (locationSplit.length <= 2) {
            this.props.history.push(Constants.ExperienceDashboard)
        } else {
            locationSplit.pop()
            let finalLocation = locationSplit.join("/")
            locationSplit.splice(0, 2)
            var x = locationSplit.join("/")
            this.setState({
                location: finalLocation,
                breadCrumb: x,
                files: []
            }, () => {
                if (this.state.isLinkPanelOpen) {
                    this.setState({
                        isLinkPanelOpen: false
                    })
                }
                this.props.history.push(Constants.MediaManagement + "/" + finalLocation)
                this.props.getMediaFromDb(this.state.companyName, this.state.experienceName)
                this.props.getFileDetails(x, this.state.experienceName, this.state.companyName)
            })
        }
    }

    getFileExtension = (filename) => {
        var ext = /^.+\.([^.]+)$/.exec(filename);
        return ext == null ? "" : ext[1];
    }

    linkFile = (mediaId, fileType, filePath) => {
        let fileExt = this.getFileExtension(filePath)
        if (fileExt === "") {
            fileExt = 'folder'
        }
        this.setState({
            isLinkPanelOpen: true
        }, () => {
            this.props.getFileDetails()
            this.props.history.push(Constants.MediaManagement + "/" + this.state.companyName + "/" + this.state.experienceName + "/" + fileExt + "/" + fileType + "/" + mediaId)
        })
    }

    unLinkFile = (mediaId) => {
        let data = {
            mediaId: mediaId,
            userId: this.props.UserInfo.UserID
        }
        Axios.put(Constants.BASE_URL + 'api/mediaManagement/UnLinkMediaLocation', data).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Successfully Unlinked Media",
                    showConfirmButton: false,
                    timer: 1000
                })
                this.props.getFileDetails()
                this.props.history.push(Constants.MediaManagement + "/" + this.state.companyName + "/" + this.state.experienceName)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to Unlink Media. Please try again!!",
                    showConfirmButton: false,
                    timer: 1000
                })
                this.props.getFileDetails()
                this.props.history.push(Constants.MediaManagement + "/" + this.state.companyName + "/" + this.state.experienceName)
            }
        })
    }

    render() {
        return (
            <div className="ExperienceDashboard mediaManagement">
                <div className="row">
                    <div className="col-12 activityLogDiv">
                        <p className="ActivityLogTitle pt-3 ">Published - 2</p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 expInfo">
                        <div className="whiteBacground">
                            <div className="row">
                                <div className="col-12">
                                    <p className="companyTitle">Company:</p>
                                    <p className="companyName">{this.state.companyName}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className="experienceTitle">Experience Name</p>
                                    <p className="experienceName">{this.state.experienceName}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="statusDetails">Status:  <span>Published</span></label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12  fileIcons">
                                    <label><img src={jpgIcon} alt="jpgIcon" /><span>{this.state.jpeg}</span></label>
                                    <label><img src={pngIcon} alt="pngIcon" /><span>{this.state.png}</span></label>
                                    <label><img src={pdfIcon} alt="pdfIcon" /><span>{this.state.pdf}</span></label>
                                    <label><img src={xmlIcon} alt="xmlIcon" /><span>{this.state.xml}</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 filesList">
                        <div className="whiteBacground">
                            <div className="row fileslistNavBar">
                                <div className="col-6 text-left">
                                    <label className="backbutton" onClick={this.navback}> <span><img src={dropdownImg} alt="backbutton" /></span>BACK  {(this.state.breadCrumb !== "" && this.state.breadCrumb !== null) ? <span>( {this.state.breadCrumb})</span> : null} </label>
                                </div>
                                <div className="col-6 text-right">
                                    <input type="text" className="expSearchBox w-75" placeholder="Search Media" onChange={(e) => this.props.filterMediaFiles(e.target.value)} />
                                </div>
                            </div>
                            <div className="row ">
                                {this.props.filteredMMFiles.length > 0 ? (
                                    <table className="table filesListDetials">
                                        <thead>
                                            <tr>
                                                <th>File Type</th>
                                                <th>File Name</th>
                                                <th>File Path</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.filteredMMFiles.map((file, index) => {
                                                var location = file.LinkLocation.split("/")
                                                var finalLocation = file.LinkLocation
                                                finalLocation = location.splice(-2).join("/")
                                                return (<tr key={index}>
                                                    <td><div className="linkType">{file.TypeofLink}</div></td>
                                                    <td ><div className="fileName">{file.Name}</div></td>
                                                    <td ><div className="location">{finalLocation}</div></td>
                                                    <td ><div className="linkButtons" >{file.Is_Linked ? <span onClick={() => this.unLinkFile(file.Id)}> UnLink</span> : file.TypeofLink === 'folder' ? <span onClick={() => this.linkFile(file.Id, file.TypeofLink, file.Location)}>Link Folder</span> : <span onClick={() => this.linkFile(file.Id, file.TypeofLink, file.Location)}>Link File</span>}   </div> </td>
                                                </tr>)
                                            })}

                                        </tbody>
                                    </table>
                                ) : null}

                                {this.props.filteredMMFolders.length > 0 ? (this.props.filteredMMFolders.map((folderDetails, index) => {
                                    return (
                                        <div key={index} className="col-3 text-center fileDetails" onClick={() => this.navigateFolder(folderDetails.Name)}>
                                            {folderDetails.Is_Linked ? <img className="folderIcon" src={linkedFolderIcon} alt="newFolderImg" /> : <img className="folderIcon" src={folderIcon} alt="newFolderImg" />}
                                            <p className="m-0">{folderDetails.Name} </p>
                                        </div>
                                    )
                                })) : null}

                                {this.props.filesList.length === 0 || this.props.foldersFiles.length > 0 ? (
                                    <div className="col-3 text-center fileDetails" onClick={() => this.navigateFolder("others")} >
                                        <img className="folderIcon" src={folderIcon} alt="folderIcon" />
                                        <p className="m-0">Others</p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MediaManagement