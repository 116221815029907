import React from 'react';
import Axios from 'axios';
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import Swal from 'sweetalert2'

class Changepassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            companyId: null,
            CompanyName: "",
            isDisabled: false,
            oldPassword: "",
            newPassword: "",
            reNewPassword: ""
        }
    }

    componentDidMount() {
        if (this.props.UserInfo === undefined) {
            this.props.history.push(Constants.Login)
        }
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    updatePassword = (e) => {
        e.preventDefault()
        const oldPassword = this.state.oldPassword
        const newPassword = this.state.newPassword
        const reNewPassword = this.state.reNewPassword
        const userEmail = this.props.UserInfo.Email

        if (oldPassword === '' || newPassword === '' || reNewPassword === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "All fields are mandatory !!",
                showConfirmButton: false,
                timer: 1500
            })
            return false
        }

        if (newPassword !== reNewPassword) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "New Password and Re-Enter Password should be same !!",
                showConfirmButton: false,
                timer: 1500
            })
            return false
        }

        let data = {
            oldPassword,
            newPassword,
            userEmail
        }

        Axios.post(Constants.BASE_URL + `api/ChangePassword`, data).then((result) => {
            if (!result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Something went wrong. Please try again !!",
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Updated Password Successfully !!",
                    showConfirmButton: false,
                    timer: 2000
                })
                this.setState({
                    oldPassword: "",
                    newPassword: "",
                    reNewPassword: ""
                })
            }
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="signin pl-5 addExperience">
                    <p className="loginTitle mb-0">Change Password</p>
                    <p className="addExpSubTitle">Configure your password below</p>
                    <form id="addExperience" onSubmit={this.updatePassword} >
                        <input type="password" name="oldPassword" className="w-100 expName" value={this.state.oldPassword} placeholder="Current Password" maxLength="100" onChange={(e) => this.changeValue("oldPassword", e.target.value)} required disabled={this.state.isDisabled} />
                        <input type="password" name="newPassword" className="w-100 expName" value={this.state.newPassword} placeholder="New Password" maxLength="100" onChange={(e) => this.changeValue("newPassword", e.target.value)} required disabled={this.state.isDisabled} />
                        <input type="password" name="reNewPassword" className="w-100 expName" value={this.state.reNewPassword} placeholder="Re-Enter New Password" maxLength="100" onChange={(e) => this.changeValue("reNewPassword", e.target.value)} required disabled={this.state.isDisabled} />
                        <button disabled={this.state.isDisabled} type="submit" className="uploadExperienceBtn">Update Password </button>
                    </form>
                </div>
            </div>
        )
    }
}

export default Changepassword