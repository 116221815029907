import React from 'react'
import '../../../assets/css/Settings.css'
import '../../../assets/css/AdminDashboardCSS/Index.css'
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import leftArrow from '../../../assets/images/Untitled-1-10.png'
import Swal from 'sweetalert2'

class AdminUserCreation extends React.Component {
    constructor() {
        super()
        this.state = {
            hasEmailSent: false,
            defaultCompany: "SelectCompany",
            CompanyList: [],
            usersList: [],
            companyId: "",
            userId: null,
            showUsersScreen: true,
            showUserRegistration: false,
            showCreateNewCompany: false,
            showCompleteUserRegistration: false,
            companyOptions: [],
            rolesOptions: [],
            password: "",
            confirmPass: "",
            firstName: "",
            lastName: "",
            userName: "",
            email: "",
            companyName: "",
            userRole: ""
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            let CompanyId = this.props.UserInfo.CompanyId
            let userId = this.props.UserInfo.UserID
            this.getCompanyDetails(CompanyId, userId)
            this.getRoles(CompanyId)
        }else{
            this.props.history.push(Constants.Login)
        }
    }

    setFormData = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    // React Select Functions
    handleRoleChange = (newValue, actionMeta) => {
        if (newValue != null) {
            this.setState({
                "userRole": newValue.value
            })
        } else {
            this.setState({
                "userRole": ""
            })
        }
    }

    handleCompanyChange = (newValue, actionMeta) => {
        if (newValue != null) {
            this.setState({
                "companyName": newValue.value
            })
        } else {
            this.setState({
                "companyName": ""
            })
        }
    }

    getCompanyDetails = (CompanyId, userId) => {
        let companiesListOptions = []
        Axios.get(Constants.BASE_URL + `api/AdminDashboard/getALLCompanies`).then((result) => {
            result.data.info.forEach(element => {
                let obj = {
                    value: element.Id,
                    label: element.Name
                }
                companiesListOptions.push(obj)
            });
            if (result.data.status) {
                this.setState({
                    CompanyList: result.data.info.filter((ele, ind) => ind === result.data.info.findIndex(elem => elem.Id === ele.Id)),
                    companyId: CompanyId,
                    userId: userId,
                    companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value))
                })
            } else {
                this.setState({
                    CompanyList: [],
                    companyId: CompanyId,
                    userId: userId,
                    companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value))
                })
            }
        })
    }
    
    getRoles = (CompanyId) => {
        Axios.get(Constants.BASE_URL + `api/AdminDashboard/getALLRoles`).then((result) => {
            if (result.data.status) {
                let rolesListOptions = []

                result.data.info.forEach(element => {
                    let obj = {
                        value: element.Id,
                        label: element.Name
                    }
                    rolesListOptions.push(obj)
                });

                this.setState({
                    companyId: CompanyId,
                    rolesOptions: rolesListOptions
                })
            } else {
                this.setState({
                    companyId: CompanyId,
                    rolesOptions: []
                })
            }
        })
    }

    validateForm = (event) => {
        event.preventDefault();

        if (this.state.firstName === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "First Name field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.lastName === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Last Name field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.userName === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "User Name field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }
        if (this.state.email === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Email field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.password === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "password field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.password !== this.state.confirmPass) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: " Passwords are not matching. Please Enter both password and confirm password the same",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (Number.isInteger(this.state.companyName)) {
            this.createNewUser()
        } else {
            this.setValue("showCreateNewCompany")
        }
        return false
    }

    createNewUser = () => {
        let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
        let data = {
            password: this.state.password,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            userName: this.state.userName,
            email: this.state.email,
            companyName: this.state.companyName,
            userRole: this.state.userRole,
            userId: userID
        }

        Axios.post(Constants.BASE_URL + `api/CreateUser`, data).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "User created succesfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                this.props.history.push(Constants.AdminUserManagementPanel)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to create user.Please try again",
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        })

    }

    navigate = () => {
        this.props.history.push(Constants.AdminUserManagementPanel)
    }
    
    render() {
        return (
            <div className="rightSidePanel slide">

                <div className="pl-5 pr-5 userRegistration adminUserregistration">
                    <div className="row userTitle">
                        <div className="col-12 pb-2">
                            <label className="backButton" onClick={this.navigate}><img src={leftArrow} alt="leftArrow" /> <span> BACK </span></label>
                        </div>
                        <div className="col-12">
                            <p className="m-0 userlistlabel">User Registration</p>
                            <p className="m-0 userlistSublabel">Please complete to register your account.</p>
                        </div>
                    </div>

                    <form id="newUserForm" onSubmit={this.validateForm}>
                        <div className="row">
                            <div className="col-6">
                                <input type="text" name="FirstName" placeholder="First Name" value={this.state.firstName} onChange={(e) => this.setFormData("firstName", e.target.value)} required />
                            </div>
                            <div className="col-6">
                                <input type="text" name="LastName" placeholder="Last Name" value={this.state.lastName} onChange={(e) => this.setFormData("lastName", e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <input type="text" name="UserName" placeholder="User Name" value={this.state.userName} onChange={(e) => this.setFormData("userName", e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <input type="email" name="Email" placeholder="Email" value={this.state.email} onChange={(e) => this.setFormData("email", e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <CreatableSelect
                                    isClearable
                                    onChange={this.handleCompanyChange}
                                    options={this.state.companyOptions}
                                    placeholder="Company Name"
                                />
                            </div>
                            <div className="col-12">
                                <input type="password" name="password" placeholder="Password" value={this.state.password} onChange={(e) => this.setFormData("password", e.target.value)} required/>
                            </div>
                            <div className="col-12">
                                <input type="password" name="confirmPassword" placeholder="Confirm Password" value={this.state.confirmPass} onChange={(e) => this.setFormData("confirmPass", e.target.value)} required/>
                            </div>
                            <div className="col-12">
                                <Select
                                    isClearable
                                    onChange={this.handleRoleChange}
                                    options={this.state.rolesOptions}
                                    placeholder="User Role"
                                />
                            </div>
                            <div className="col-12">
                                <button type="submit" className="addUserSubmitBtn">Add User</button>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-12">
                            <p className="footerText">Term of use. Privacy policy</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminUserCreation