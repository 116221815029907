import React from 'react';
import { Route } from 'react-router-dom';
import * as Constants from '../../../assets/helpers/constants'
import AdminSoftwareRelease from './AdminSoftwareVersionRelease'
import AdminUsageReports from './AdminUsageReports'
import AdminUserManagement from './AdminUserManagement'
import AdminUserDetails from './AdminUserDetails'
import AdminUserCreation from './AdminUserCreation'
import AdminCompanyDetails from './AdminCompanyDetails'


import AdminCompanysUsersList from './AdminCompanyUsersList'
import AdminCompanysSoftwareRelease from './AdminCompanysSoftwareRelease'
import AdminCompanysLicenseIssue from './AdminCompanysLicenseIssue'
class AdminRightSidePanel extends React.Component {
    componentDidMount() {
        if (this.props.UserInfo === undefined) {
            this.props.history.push(Constants.Login);
        }
    }

    render() {
        return (
            <div>
                <AdminUserDetails {...this.props} />
                <Route exact path={Constants.AdminUsageReportsPanel} component={() => <AdminUsageReports {...this.props} />} />
                <Route exact path={Constants.AdminUserManagementPanel} component={() => <AdminUserManagement {...this.props} />} />
                <Route exact path={Constants.AdminSoftwareReleasePanel} component={() => <AdminSoftwareRelease {...this.props} />} />
                <Route exact path={Constants.AdminUserCreation} component={() => <AdminUserCreation {...this.props} />} />
                <Route exact path={Constants.AdminCompanyDetails + "/*"} component={() => <AdminCompanyDetails {...this.props} />} />
                <Route exact path={Constants.AdminCompanysUsersList + "/*"} component={() => <AdminCompanysUsersList {...this.props} />} />
                <Route exact path={Constants.AdminCompanysSoftwareRelease + "/*"} component={() => <AdminCompanysSoftwareRelease {...this.props} />} />
                <Route exact path={Constants.AdminCompanysLicenseIssue + "/*"} component={() => <AdminCompanysLicenseIssue {...this.props} />} />
            </div>
        )
    }
}

export default AdminRightSidePanel