import React from 'react';
import '../../../assets/css/Settings.css'
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import CreatableSelect from 'react-select/creatable';
import AndroidIcon from '../../../assets/images/AndroidUp.png'
import IOSIcon from '../../../assets/images/IOSUp.png'
import WindowsIcon from '../../../assets/images/WindowsUp.png'
import Swal from 'sweetalert2'
import LoaderComp from '../../Loader'
import Moment from 'react-moment';


class DeviceDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            DevicesKeys: [],
            LicenseKey: "",
            tagId: "",
            TagName: "",
            ExperienceName: "",
            DeviceName: "",
            DeviceId: 0,
            DeviceStatus: 0,
            DeviceIp: "",
            DeviceUUID: "",
            companyId: "",
            companyName: "",
            experienceList: [],
            tagsList: [],
            selectedExperience: "",
            selectedTag: "",
            tagsListOptions: [],
            devicesType: null,
            initialDeviceName: "",
            DeviceOS: "",
            DeviceSoftwareVersion: "",
            lastSignedIn: ""
        }
    }

    componentDidMount() {
        const deviceDetails = JSON.parse(this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1))
        const deviceId = deviceDetails[0].id
        const deviceCompanyId = deviceDetails[0].companyId
        const devicesType = deviceDetails[0].type
        this.setState({
            DevicesKeys: deviceDetails,
            DeviceId: deviceId,
            companyId: deviceCompanyId,
            devicesType: devicesType,
            companyName: ""
        }, () => {
            if (deviceDetails.length === 1) {
                this.getDeviceDetails(deviceId)
            }
            this.getExperiences(deviceCompanyId)
            this.getTags(deviceCompanyId)
        })
    }

    getDeviceDetails = (DeviceId) => {
        Axios.get(Constants.BASE_URL + `api/DeviceManagement/getDeviceDetais/${DeviceId}`).then((result) => {
            if (result.data.info !== undefined || result.data.info !== null) {
                var data = result.data.info
                this.setState({
                    LicenseKey: data.LicenseKey,
                    tagId: data.tagId,
                    TagName: data.TagName,
                    ExperienceName: data.ExperienceName,
                    DeviceName: data.DeviceName,
                    initialDeviceName: data.DeviceName,
                    DeviceId: data.DeviceId,
                    DeviceStatus: data.DeviceStatus,
                    DeviceIp: data.DeviceIp,
                    DeviceUUID: data.DeviceUUID,
                    DeviceOS: data.DeviceOS,
                    DeviceSoftwareVersion: data.SoftwareVersion,
                    lastSignedIn : data.lastSignedIn
                })
            }
        })
    }

    getExperiences = (company) => {
        Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getExperiencs/${company}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    experienceList: result.data.info
                })
            } else {
                this.setState({
                    experienceList: []
                })
            }
        })
    }

    getTags = (company) => {
        Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getTagsList/${company}`).then((result) => {
            if (result.data.status) {
                let tagsListOptions = []
                result.data.info.forEach(element => {
                    let obj = {
                        value: element.Id,
                        label: element.Name
                    }
                    tagsListOptions.push(obj)
                });

                this.setState({
                    tagsList: result.data.info,
                    tagsListOptions: tagsListOptions
                })
            } else {
                this.setState({
                    tagsList: []
                })
            }
        })
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    renameDevice = () => {
        if (this.state.initialDeviceName !== this.state.DeviceName) {
            if (this.state.DeviceName.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Device Name cannot be empty!!',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    DeviceName: this.state.initialDeviceName
                })
            } else {
                let title = ""
                if (this.state.DevicesKeys.length <= 1) {
                    title = 'Are you sure you want to rename device?'
                } else {
                    title = 'Are you sure you want to rename devices?'
                }
                Swal.fire({
                    title: title,
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Rename'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            isLoading: true
                        }, () => {
                            let data = {
                                name: this.state.DeviceName,
                                devicesList: this.state.DevicesKeys
                            }
                            Axios.put(Constants.BASE_URL + `api/DeviceManagement/updateDeviceNames`, data).then((result) => {

                                if (result.data.status) {
                                    Swal.fire({
                                        position: 'top-end',
                                        icon: 'success',
                                        title: 'Updated Devices successfully',
                                        showConfirmButton: false,
                                        timer: 1000
                                    })
                                    this.setState({
                                        isLoading: false
                                    }, () => {
                                        this.props.getDevices(this.state.companyName, this.state.companyId)
                                    })
                                } else {
                                    Swal.fire({
                                        position: 'top-end',
                                        icon: 'error',
                                        title: 'Unable to update Device Name. Please contact admin !!',
                                        showConfirmButton: false,
                                        timer: 1000
                                    })
                                    this.setState({
                                        isLoading: false
                                    })
                                }
                            })
                        })
                    } else {
                        this.setState({
                            DeviceName: this.state.initialDeviceName
                        })
                    }
                })
            }
        }
    }

    submitDeviceChanges = () => {
        var filteredIds = this.state.DevicesKeys.map(a => a.id)
        let data = {
            deviceId: filteredIds,
            expId: this.state.selectedExperience,
            tagId: this.state.selectedTag,
            userId: this.props.UserInfo.UserID,
            companyId: this.state.companyId,
        }

        Axios.put(Constants.BASE_URL + `api/DeviceManagement/updateDevice`, data).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Device Updated sucessfully",
                    showConfirmButton: false,
                    timer: 1000
                })
                this.props.getDevices(this.state.companyName, this.state.companyId)
                this.props.history.push(Constants.DeviceDashboard)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Unable to update Device. Please contact admin',
                    showConfirmButton: false,
                    timer: 1000
                })

            }
        })
    }

    handleCompanyChange = (newValue, actionMeta) => {
        if (newValue != null) {
            this.setState({
                "selectedTag": newValue.value
            })
        } else {
            this.setState({
                "selectedTag": ""
            })
        }
    }

    deleteDevice = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                Axios.delete(Constants.BASE_URL + `api/deleteDevice/${id}`).then((result) => {
                    if (result.data.status) {
                        Swal.fire(
                            'Deleted!',
                            'Device Deleted sucessfully',
                            'success'
                        )
                        this.props.getDevices(this.state.companyName, this.state.companyId)
                        this.props.history.push(Constants.DeviceDashboard)

                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Unable to delete Device. Please contact admin',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    }
                })
            }
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="signin pl-5  deviceDetails">
                    <p className="loginTitle mb-0">Device Details</p>
                    <p className="addExpSubTitle">Configure your device below</p>
                    <div className="row">
                        <div className="col-12">
                            <label className="licenseKeyDetails">
                                <span className="keyIcon">
                                    <img src={this.state.DeviceOS.toLowerCase() === "ios" ? IOSIcon : this.state.DeviceOS.toLowerCase() === "android" ? AndroidIcon : WindowsIcon} alt="OSIcon" />
                                </span>
                                <span className="keyDetails ml-3">
                                    <p className="p-0 m-0 key" >
                                        <input className="deviceName" placeholder="Rename Device/s" value={this.state.DeviceName} onBlur={this.renameDevice} title={this.state.DeviceName} onChange={(e) => this.changeValue("DeviceName", e.target.value)} />
                                        {/* <span className="deviceName">{this.state.DevicesKeys.length === 1 ? this.state.DeviceName : null} </span> */}
                                        {this.state.DevicesKeys.length === 1 ? <span className="deleteBTN" onClick={() => this.deleteDevice(this.state.DeviceId)}> Delete</span> : null} </p>
                                </span>
                            </label>
                        </div>

                    </div>
                    <div className="row keyInfo">
                        <div className="col-12 w-100 keydetailsDiv">
                            <div className="row">
                                <div className="col-6">
                                    <span className="keyname">Status</span>
                                </div>
                                <div className="col-6">
                                    <span className="keyvalue">{this.state.DevicesKeys.length === 1 ? this.state.DeviceStatus === 1 ? "Active" : "Not Active" : null}</span>
                                </div>
                            </div>
                        </div>
                        {this.state.devicesType !== "Composer" ? (
                            <div className="col-12 w-100 keydetailsDiv">
                                <div className="row">
                                    <div className="col-6">
                                        <span className="keyname">Experience Type</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="keyvalue">{this.state.DevicesKeys.length === 1 ? this.state.ExperienceName : null}</span>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="col-12 w-100 keydetailsDiv">
                            <div className="row">
                                <div className="col-6">
                                    <span className="keyname">Tags</span>
                                </div>
                                <div className="col-6">
                                    <span className="keyvalue tagValue">{this.state.DevicesKeys.length === 1 ? this.state.TagName : null}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 w-100 keydetailsDiv">
                            <div className="row">
                                <div className="col-6">
                                    <span className="keyname">Device IP</span>
                                </div>
                                <div className="col-6">
                                    <span className="keyvalue">{this.state.DevicesKeys.length === 1 ? this.state.DeviceIp : null}</span>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 w-100 keydetailsDiv">
                            <div className="row">
                                <div className="col-6">
                                    <span className="keyname">Last SignedIn</span>
                                </div>
                                <div className="col-6">
                                    <span className="keyvalue">{this.state.DevicesKeys.length === 1 ? <Moment format="DD-MMM-YYYY">
                                        {this.state.DevicesKeys.length === 1 ? this.state.lastSignedIn
                                            : null}
                                    </Moment> : null}</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 w-100 keydetailsDiv">
                            <div className="row">
                                <div className="col-6">
                                    <span className="keyname">Device UUID</span>
                                </div>
                                <div className="col-6">
                                    <span className="keyvalue" style={{ fontSize: "12px" }}>{this.state.DevicesKeys.length === 1 ? this.state.DeviceUUID : null}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 w-100 keydetailsDiv">
                            <div className="row">
                                <div className="col-6">
                                    <span className="keyname">Software Version</span>
                                </div>
                                <div className="col-6">
                                    <span className="keyvalue" style={{ fontSize: "12px" }}>{this.state.DevicesKeys.length === 1 ? this.state.DeviceSoftwareVersion : null}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 w-100 keydetailsDiv">
                            <div className="row">
                                <div className="col-6">
                                    <span className="keyname">License </span>
                                </div>
                                <div className="col-6">
                                    <span className="keyvalue">{this.state.DevicesKeys.length === 1 ? this.state.LicenseKey : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row assignNewDetails">
                        <div className="col-12">
                            {this.state.devicesType !== "Composer" ? (
                                <div className="selectdiv">
                                    <select className="experienceList" value={this.state.selectedExperience} onChange={(e) => this.changeValue("selectedExperience", e.target.value)}>
                                        <option value="">Assign New Experience</option>
                                        {this.state.experienceList.length > 0 ? (
                                            this.state.experienceList.map((experience, index) => {
                                                return <option key={index} id={experience.ExperienceId} value={experience.ExperienceId}>{experience.ExperienceName}</option>
                                            })
                                        ) : null}
                                    </select>
                                </div>
                            ) : null}

                            <div className="col-12 selectTagContainer p-0">
                                <CreatableSelect
                                    className="changetag"
                                    isClearable
                                    onChange={this.handleCompanyChange}
                                    options={this.state.tagsListOptions}
                                    placeholder="Select Tag"
                                />
                            </div>
                            <div className="col-12">
                                {(this.state.selectedExperience !== "" || this.state.selectedTag !== "") ? <button type="button" className="saveChanges w-100" onClick={this.submitDeviceChanges}>Save Changes</button> : null}
                            </div>
                        </div>
                    </div>

                    {/* <div className="row resellerUnassignRenewBtns">
                    <div className="col-6">
                        <button type="button " className="unassign" onClick={this.unAssign}>Un-Assign</button>
                    </div>
                    <div className="col-6">
                        <button type="button" className="renew">Renew</button>
                    </div>
                </div> */}
                </div>
            </div >
        )
    }
}

export default DeviceDetails