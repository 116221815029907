import React from 'react'
import Axios from 'axios';
import Swal from 'sweetalert2'
import LoaderComp from '../../../Loader'

import * as Constants from '../../../../assets/helpers/constants'
import BackgroundVideo from '../../../../assets/images/video.mp4'


export default class DownloadComposer extends React.Component {
    constructor() {
        super()
        this.state = {
            mostViewedProj: []
        }
    }


    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getMostViewedProjects()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getMostViewedProjects = () => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/MarketPlace/mostViewedProjects`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        mostViewedProj: result.data.info,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        mostViewedProj: [],
                        isLoading: false
                    }, () => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Error in fetching details.Please try later",
                            showConfirmButton: false,
                            timer: 1000
                        })
                    })
                }
            })
        })
    }

    navigate = (id) => {
        this.props.history.push(Constants.DownloadComposer + "/" + id)
    }

    checkIfDetailsPresent = () => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/checkUserDetails/${this.props.UserInfo.UserID}`).then((result) => {
            if (result.data.status) {
                window.open(
                    result.data.link,
                    '_blank' 
                );
            } else {
                this.props.history.push(Constants.DownloadComposerView)
            }
        })
    }

    render() {
        return (
            <div>
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="row pl-3">
                    <div className="col-12">
                        <div className="downloadBox">
                            <video width="320" height="240" autoPlay="autoplay" id="vid" muted loop>
                                <source src={BackgroundVideo} type="video/mp4" />
                            </video>
                            <p>Build Projects with Baetho Composer</p>
                            <button type="button" onClick={this.checkIfDetailsPresent}>Download Baetho Composer</button>
                        </div>
                    </div>
                </div>
                <div className="row mostViewed mt-5">
                    <div className="col-12">
                        <div className="row m-0 p-0">
                            <div className="col-2 projDiv">
                                <p>Most Viewed</p>
                            </div>
                            <div className="col-10">
                                <div className="row">
                                    {this.state.mostViewedProj.length > 0 ? (this.state.mostViewedProj.map((proj, index) => {
                                        return (
                                            <div className="col-2 projDiv" title={proj.Name} key={index}>
                                                <img src={proj.Thumbnail} alt="UploadNew" />
                                                <p className="button"><span onClick={() => this.navigate(proj.Id)}>View</span></p>
                                            </div>
                                        )
                                    })) : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}