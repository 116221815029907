import React from 'react'
import '../../../assets/css/UserDetails.css'
import * as Constants from '../../../assets/helpers/constants'
import Axios from 'axios';
import Swal from 'sweetalert2'

import HeartIcon from '../../../assets/images/Group 420@2x.png'
import BookmarkIcon from '../../../assets/images/Group 422@2x.png'
import ViewIcon from '../../../assets/images/Group 423@2x.png'
import ShareIcon from '../../../assets/images/Group 421@2x.png'

class MarketPlaceDiscoverUserDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            mostViewedProj: [],
            userDetails:[],
            views:0,
            saved:0,
            likes:0,
            shares:0
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getMostViewedProjects()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getMostViewedProjects = () => {
        this.setState({
            isLoading: true
        }, () => {
            let userId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
            Axios.get(Constants.BASE_URL + `api/MarketPlace/mostViewedProjectsOfUser/${userId}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        mostViewedProj: result.data.info.projects,
                        userDetails : result.data.info.userDetails[0],
                        isLoading: false,
                        likes:result.data.info.Likes,
                        saved:result.data.info.Saved,
                        shares:result.data.info.Shares,
                        views:result.data.info.Views
                    })
                } else {
                    this.setState({
                        mostViewedProj: [],
                        userDetails:[],
                        isLoading: false
                    }, () => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Error in fetching details.Please try later",
                            showConfirmButton: false,
                            timer: 1000
                        })
                    })
                }
            })
        })
    }

    navigate = (id) => {
        const projectId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        if (parseInt(projectId) !== id) {
            this.props.incrementViews(id)
            this.props.getProjectDetails()
            this.props.history.push(Constants.MyProjects + "/" + id)
        }
    }

    render() {
        return (
            <div className="rightSidePanel slide marketPlaceUserInfo marketPlaceUserInfoDiscover" >
                <div className="row mt-5">
                    <div className="col-12 text-center marketUserDetails mt-5">
                        <img src={this.state.userDetails.ImgLink} alt="Profile Pic" className="userProfilePic" />
                        <p className="p-0 mt-2 mb-2 userName">{this.state.userDetails.Name}</p>
                        <p className="p-0 mt-2 mb-2 userDesignation">{this.state.userDetails.Country},{this.state.userDetails.State}<br/>{this.state.userDetails.JobTitle}, {this.state.userDetails.Designation}</p>
                        <label className="heartIconDiv">
                            <img src={ViewIcon} alt="BookmarkIcon" /><br />
                            <span>{this.state.views}</span>
                        </label>
                        <label className="heartIconDiv">
                            <img src={HeartIcon} alt="HeartIcon" /> <br />
                            <span>{this.state.likes}</span>
                        </label>
                        <label className="heartIconDiv">
                            <img src={ShareIcon} alt="HeartIcon" /> <br />
                            <span>{this.state.shares}</span>
                        </label>

                        <label className="heartIconDiv">
                            <img src={BookmarkIcon} alt="BookmarkIcon" /><br />
                            <span>{this.state.saved}</span>
                        </label>

                    </div>
                </div>
                <div className="mostViewProjectsUser row">
                    <div className="col-12">
                        <p className="mostViewProjectsUserTitle">
                            The most viewed projects of {this.state.userDetails.Name}
                        </p>
                    </div>

                    {this.state.mostViewedProj.length > 0 ? (this.state.mostViewedProj.map((proj, index) => {
                        return (
                            <div className="col-4 projDiv" key={index} title={proj.Name}>
                                <img src={proj.Thumbnail} alt="UploadNew" onClick={() => this.navigate(proj.Id)} />
                            </div>
                        )
                    })) : null}
                </div>
                <hr className="footerDivHR" />
                <div className="row helpSection footerDiv">
                    <div className="col-12 text-center">
                        <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default MarketPlaceDiscoverUserDetails