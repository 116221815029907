import React from 'react';
import '../../../assets/css/LicenseManagement.css'
import Axios from 'axios'
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import copyImg from '../../../assets/images/DuplicateDown.png'
import DataTable from 'react-data-table-component';
import Moment from 'react-moment';

class LicenseDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            defaultCompany: "",
            Experiences: [],
            CompanyList: [],
            licensesList: props.licenceInfo.licensesList,
            filteredLicenses: props.licenceInfo.licensesList,
            companyId: this.props.licenceInfo.companyId,
            companyName: "",
            assigned: props.licenceInfo.assigned,
            unassigned: props.licenceInfo.unassigned,
            expired: props.licenceInfo.expired,
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getCompanyDetails()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    copyLicenseKey = (value) => {
        navigator.clipboard.writeText(value)
    }

    getCompanyDetails = () => {
        this.setState({
            isLoading: true
        }, () => {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let CompanyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
            let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID

            let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role
            if (role === Constants.ContentManager || role === Constants.ComposerUser) {
                this.setState({
                    CompanyList: [{
                        CompanyId: CompanyId,
                        CompanyName: CompanyName
                    }],
                    companyId: CompanyId,
                    companyName: CompanyName,
                    defaultCompany: CompanyName + "_" + CompanyId,
                    isLoading: false
                })
                this.props.getLicenses(CompanyName + "_" + CompanyId)
            } else {
                Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getCompaniesList/${userID}/${CompanyId}`).then((result) => {
                    if (result.data.status) {
                        this.setState({
                            CompanyList: result.data.info.filter((ele, ind) => ind === result.data.info.findIndex(elem => elem.CompanyId === ele.CompanyId)),
                            companyId: CompanyId,
                            companyName: CompanyName,
                            defaultCompany: CompanyName + "_" + CompanyId,
                            isLoading: false
                        })
                        this.props.getLicenses(CompanyName + "_" + CompanyId)
                    }
                })
            }
        })
    }

    render() {
        let columns = [
            {
                name: 'Issued On',
                grow: 1.5,
                cell: row => <div data-tag="allowRowEvents" className="infoDetails"><Moment format="DD-MMM-YYYY">
                    {row.Created_On}
                </Moment></div>,
                selector: 'Created_On',
                sortable: true,
            },
            {
                name: 'License Key ',
                grow: 3,
                cell: row => <div data-tag="allowRowEvents" className="licenceKeyDiv" title="Click To Copy Licence Key">
                    <span className="key">{row.licenseKey} </span>
                    <span> {row.Is_Active === 1 ? <div className="green"></div> : <div className="red"></div>}</span>
                    <span onClick={() => this.copyLicenseKey(row.licenseKey)} className="copyImg">
                        <img src={copyImg} alt="copyImg" />
                    </span>
                    <span className="keySeperator">|</span>

                </div>,
                selector: 'licenseKey',
                sortable: true
            },
            // {
            //     name: 'Status',
            //     left: true,
            //     grow: 0.5,
            //     cell: row => <div data-tag="allowRowEvents" className="infoDetails">{row.Is_Active === 1 ? "Active" : "InActive"}</div>,
            //     selector: 'Is_Active',
            //     sortable: true
            // },
            {
                name: 'Assigned Device',
                left: true,
                grow: 1.5,
                cell: row => <div data-tag="allowRowEvents" className="infoDetails">{row.DeviceName != null ? row.DeviceName : ""}</div>,
                selector: 'DeviceName',
                sortable: true
            }
        ]

        if (this.props.licenceInfo.isPlayer) {
            columns.push({
                name: 'Assigned Experience',
                left: true,
                grow: 1.5,
                cell: row => <div data-tag="allowRowEvents" className="infoDetails" >{row.ExperienceName != null ? row.ExperienceName : ""}</div>,
                selector: 'ExperienceName',
                sortable: true
            },
                {
                    name: 'Interactive',
                    left: true,
                    grow: 1,
                    cell: row => <div data-tag="allowRowEvents" className="infoDetails" >{row.Is_Interactive ? "True" : "False"}</div>,
                    selector: 'Is_Interactive',
                    sortable: true
                },
                {
                    name: 'Transferable',
                    left: true,
                    grow: 1,
                    cell: row => <div data-tag="allowRowEvents" className="infoDetails" >{row.Is_Transferable ? "True" : "False"}</div>,
                    selector: 'Is_Transferable',
                    sortable: true
                }
            )
        }

        columns.push(
            {
                name: 'Assigned On',
                center: true,
                grow: 2,
                cell: row => <div data-tag="allowRowEvents" className="infoDetails">
                    {row.Start_Date != null ? (
                        <Moment format="DD-MMM-YYYY">
                            {row.Start_Date}
                        </Moment>)
                        : null}
                </div>,
                selector: 'Start_Date',
                sortable: true
            },
            {
                name: 'Expiry Date',
                center: true,
                grow: 1.8,
                cell: row => <div data-tag="allowRowEvents" className="infoDetails">
                    {row.Expiry_Date != null ? (
                        <Moment format="DD-MMM-YYYY">
                            {row.Expiry_Date}
                        </Moment>)
                        : null}
                </div>,
                selector: 'Expiry_Date',
                sortable: true
            }
        )

        return (
            <div className="LicenseDashboard">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="row">
                    <div className="col-12 activityLogDiv">
                        <p className="licenseCount pt-3 ">
                            <span>Assigned - {this.props.licenceInfo.assigned}</span>
                            <span>Unassigned - {this.props.licenceInfo.unassigned}</span>
                            <span>Expired - {this.props.licenceInfo.expired}</span></p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="companyDetails">
                            <span className="selectdiv">Company
                                <select onChange={(e) => this.props.getLicenses(e.target.value)} value={this.props.licenceInfo.defaultCompany !== null ? this.props.licenceInfo.defaultCompany : ""} placeholder="Select Company">
                                    {/* <option id="Select Company" disabled > Select Company </option> */}
                                    {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                        return <option key={company.CompanyId} id={company.CompanyId} value={company.CompanyName + "_" + company.CompanyId}>{company.CompanyName}</option>
                                    })
                                    )
                                        : null}
                                </select>
                            </span>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="type" checked={this.props.licenceInfo.isComposer} onChange={() => this.props.changeLicenses("Composer")} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Composer</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="type" checked={this.props.licenceInfo.isPlayer} onChange={() => this.props.changeLicenses("Player")} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Player</label>
                            </div>
                            <div className="form-check searchField">
                                <input type="text" value={this.props.licenceInfo.searchValue} onChange={(e) => this.props.searchLicenses(e.target.value)} placeholder="Search For License key" />
                            </div>
                        </label>
                    </div>
                </div>
                <div className="row licensesDiv">
                    <div className="col-12">
                        {(this.props.licenceInfo.licensesList != null && this.props.licenceInfo.filteredLicenses.length > 0) ?
                            <DataTable
                                columns={columns}
                                data={this.props.licenceInfo.searchLicenses}
                                selectableRows={true}
                                progressPending={this.props.licenceInfo.licensesList != null ? false : true}
                                pagination={true}
                                onSelectedRowsChange={this.props.selectedLicense}
                            /> : null
                        }
                    </div>
                </div>
            </div>)
    }
}

export default LicenseDashboard