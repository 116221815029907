import React from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import LoaderComp from '../../Loader'
import Swal from 'sweetalert2'
import leftArrow from '../../../assets/images/Untitled-1-10.png'


class Recovery extends React.Component {
    constructor() {
        super()
        this.state = {
            hasEmailSent: false,
            recoveryEmail: "",
            isLoading: false
        }
    }

    sendEmail = (e) => {
        e.preventDefault();
        if (this.state.recoveryEmail !== "") {
            this.setState({
                isLoading: true
            }, () => {
                let data = {
                    email: this.state.recoveryEmail
                }
                Axios.post(Constants.BASE_URL + "api/sendEmail", data).then((result) => {
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Email Triggered succesfully",
                            showConfirmButton: false,
                            timer: 1000
                        })
                        this.setState({
                            hasEmailSent: true,
                            isLoading: false
                        })
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: result.data.message,
                            showConfirmButton: false,
                            timer: 1000
                        })
                        this.setState({
                            isLoading: false
                        })
                    }
                });
            })

        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Email field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            this.setState({
                isLoading: false
            })
        }
        return false
    }

    setEmail = (e) => {
        this.setState({
            recoveryEmail: e.target.value
        })
    }

    render() {
        let htmlText = (
            <div className="signin pl-5 recoveryDetails">
                <Link to="/Login" className="links"><label className="backButton" ><img src={leftArrow} alt="leftArrow" /> <span> BACK  TO LOGIN</span></label></Link>
                <p className="loginTitle mb-0">RECOVERY</p>
                <p className="loginsubTitle mb-0">Enter your email and we send you a password reset link.</p>
                <form className="signin_form" onSubmit={this.sendEmail}>
                    <div className="row">
                        <div className="col-12">
                            <input type="email" className="inputfields" value={this.state.recoveryEmail} onChange={(e) => this.setEmail(e)} placeholder="Email" required />
                        </div>
                    </div>
                    <div className="row submitButtonSection">
                        <div className="col-12">
                            <button type="submit" className="LoginButton" >Send request</button>
                        </div>
                        {/* <div className="col-6">
                            <Link to="/Login" className="links">
                                <label>Signin</label>
                            </Link>
                        </div> */}
                    </div>
                    <p className="formFooter">Term of use. Privacy policy</p>
                </form>

            </div>
        )
        if (this.state.hasEmailSent) {
            htmlText = (
                <div className="signin pl-5 text-center recoverySucess">
                    <p className="loginTitle mb-0">Done!</p>
                    <p className="loginsubTitle mb-0">Please check your email.</p>
                    <form className="signin_form">
                        <div className="row">
                            <div className="col-12">
                                <Link to="/Login" className="links">
                                    <button className="btn LoginButton">Login</button>
                                </Link>
                            </div>

                        </div>
                        <p className="formFooter">Term of use. Privacy policy</p>
                    </form>

                </div>
            )
        }
        return (
            <div className="rightSidePanel slide">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}

                {htmlText}
            </div>
        )

    }
}

export default Recovery