import React from 'react'
import '../../../assets/css/UserDetails.css'
import * as Constants from '../../../assets/helpers/constants'
import Axios from 'axios';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

import ProfileDup from '../../../assets/images/Group 1045.svg'
import BackIcon from '../../../assets/images/Untitled-1-10.png'


import HeartIcon from '../../../assets/images/Group 420@2x.png'
import BookmarkIcon from '../../../assets/images/Group 422@2x.png'
import ViewIcon from '../../../assets/images/Group 423@2x.png'
import ShareIcon from '../../../assets/images/Group 421@2x.png'
import withOutNotification from '../../../assets/images/Bell.png'
import withNotification from '../../../assets/images/BellNotify.png'

class MarketPlaceUserDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            mostViewedProj: [],
            userDetails: [],
            hasNotifications: false,
            iseditProfile: false,
            profilePic: null,
            isNotificationPanel: false,
            notificationActive: "followrequest",
            views: 0,
            saved: 0,
            likes: 0,
            shares: 0
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getMostViewedProjects()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getMostViewedProjects = () => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/MarketPlace/mostViewedProjectsOfUser/${this.props.UserInfo.UserID}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        mostViewedProj: result.data.info.projects,
                        userDetails: result.data.info.userDetails[0],
                        isLoading: false,
                        likes: result.data.info.Likes,
                        saved: result.data.info.Saved,
                        shares: result.data.info.Shares,
                        views: result.data.info.Views
                    })
                } else {
                    this.setState({
                        mostViewedProj: [],
                        userDetails: [],
                        isLoading: false
                    }, () => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Error in fetching details.Please try later",
                            showConfirmButton: false,
                            timer: 1000
                        })
                    })
                }
            })
        })
    }

    setValue = (key, value) => {
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                [key]: value
            }
        })
    }

    setImage = (key, value) => {
        this.setState({
            [key]: value
        }, () => {
            if (value !== undefined) {
                var reader = new FileReader();
                let fileDetails = value
                const sourceDiv = document.getElementById("preview");
                sourceDiv.innerHTML = ""
                if (fileDetails.type === 'image/png' || fileDetails.type === 'image/jpg' || fileDetails.type === 'image/jpeg') {
                    reader.onload = function (e) {
                        var img = document.createElement("img");
                        img.title = fileDetails.name;
                        img.src = e.target.result;
                        sourceDiv.appendChild(img);
                    };
                    reader.readAsDataURL(value);
                } else {

                }
            } else {
                this.setState({
                    profilePic: null
                })
            }

        })

    }

    setnotificationTab = (value) => {
        this.setState({
            notificationActive: value
        })
    }

    submitEditedChanged = () => {
        let data = new FormData()
        data.append("profilePic", this.state.profilePic)
        data.append("mobileNo", this.state.userDetails.MobileNo)
        data.append("address", this.state.userDetails.Address)
        data.append("country", this.state.userDetails.Country)
        data.append("state", this.state.userDetails.State)
        data.append("jobTitle", this.state.userDetails.JobTitle)
        data.append("designation", this.state.userDetails.Designation)
        data.append("userId", this.props.UserInfo.UserID)
        data.append("previousLink", this.props.UserInfo.ImgLink)
        Axios.post(Constants.BASE_URL + `api/MarketPlace/updateUserDetails`, data).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Sucessfully Updated the profile',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.props.history.push(Constants.MarketPlace)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Error in updating the profile.Please try again',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        })
    }

    navigate = (id) => {
        const projectId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        if (parseInt(projectId) !== id) {
            this.props.incrementViews(id)
            this.props.getProjectDetails()
            this.props.history.push(Constants.MyProjects + "/" + id)
        }
    }

    editProfile = (value) => {
        this.setState({
            iseditProfile: value
        })
    }

    showNotificationPanel = () => {
        this.setState({
            isNotificationPanel: true
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide marketPlaceUserInfo" >
                {this.state.iseditProfile
                    ? (
                        <div className="row downloadComposerView p-0 pt-3">
                            <div className="col-12">
                                <div className="row ">
                                    <div className="col-5">
                                        <Link to={Constants.MarketPlace} onClick={() => this.editProfile(false)}><img src={BackIcon} alt="BackIcon" className="backIcon" /><p className="backBtnText">Back</p></Link>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="titleDiv col-12">
                                        <p className="title">Edit Profile</p>
                                        <p className="subTitle">Update about you</p>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                        <input type="file" id="imgupload" onChange={(e) => this.setImage("profilePic", e.target.files[0])} />
                                        {this.state.userDetails.ImgLink != null
                                            ? (
                                                <label htmlFor="imgupload">
                                                    <div id="preview" htmlFor='imgupload'>
                                                        <img alt="profilePic" src={this.state.userDetails.ImgLink} />
                                                    </div>

                                                </label>
                                            )
                                            :
                                            <>
                                                {this.state.profilePic !== null ? <label htmlFor='imgupload'> <div id="preview" htmlFor='imgupload'></div></label> :
                                                    <div>
                                                        <label htmlFor='imgupload'>  <img src={ProfileDup} alt="profilePic" /></label>
                                                        <p className="uplaodProfilePic mt-1">Upload Profile picture</p>
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                    <div className="col-12">
                                        <input type="number" placeholder="Mobile" value={this.state.userDetails.MobileNo} onChange={(e) => this.setValue("MobileNo", e.target.value)} />
                                        <textarea placeholder="Address" rows="3" cols="40" value={this.state.userDetails.Address} onChange={(e) => this.setValue("Address", e.target.value)}></textarea>
                                        <input type="text" placeholder="Country" value={this.state.userDetails.Country} onChange={(e) => this.setValue("Country", e.target.value)} />
                                        <input type="text" placeholder="State" value={this.state.userDetails.State} onChange={(e) => this.setValue("State", e.target.value)} />
                                        <input type="text" placeholder="Job title" value={this.state.userDetails.JobTitle} onChange={(e) => this.setValue("JobTitle", e.target.value)} />
                                        <input type="text" placeholder="Designation" value={this.state.userDetails.Designation} onChange={(e) => this.setValue("Designation", e.target.value)} />
                                        <button type="button" className="nextBtn" onClick={this.submitEditedChanged}>Save Changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    : null
                }

                {!this.state.iseditProfile && !this.state.isNotificationPanel ? (
                    <div className="row">
                        <div className="col-12">

                            <div className="row">
                                <div className="col-12 text-right mt-3 ">
                                    <div className="notificationDiv">
                                        {this.state.hasNotifications ? <img src={withNotification} alt="notificationPic" onClick={() => this.showNotificationPanel(true)} /> : <img src={withOutNotification} alt="notificationPic" onClick={this.showNotificationPanel} />}
                                    </div>
                                </div >
                                <div className="col-12 text-center marketUserDetails mt-4">
                                    <div className="editProfileDiv">
                                        <p className="w-100" onClick={() => this.editProfile(true)}><span className="editProfiletext">Edit Profile</span></p>
                                    </div>
                                    <img src={this.state.userDetails.ImgLink} alt="Profile Pic" className="userProfilePic" />
                                    <p className="p-0 mt-2 mb-2 userName">{this.state.userDetails.Name}</p>
                                    <p className="p-0 mt-2 mb-2 userDesignation">{this.state.userDetails.Country},{this.state.userDetails.State} <br />{this.state.userDetails.JobTitle}, {this.state.userDetails.Designation}</p>
                                    <label className="heartIconDiv">
                                        <img src={ViewIcon} alt="BookmarkIcon" /><br />
                                        <span>{this.state.views}</span>
                                    </label>
                                    <label className="heartIconDiv">
                                        <img src={HeartIcon} alt="HeartIcon" /> <br />
                                        <span>{this.state.likes}</span>
                                    </label>
                                    <label className="heartIconDiv">
                                        <img src={ShareIcon} alt="HeartIcon" /> <br />
                                        <span>{this.state.shares}</span>
                                    </label>

                                    <label className="heartIconDiv">
                                        <img src={BookmarkIcon} alt="BookmarkIcon" /><br />
                                        <span>{this.state.saved}</span>
                                    </label>

                                    <p className="followerstext"><span>1k Followers</span></p>

                                </div>
                            </div >
                            <div className="mostViewProjectsUser row">
                                <div className="col-12">
                                    <p className="mostViewProjectsUserTitle">
                                        The most viewed projects of {this.state.userDetails.Name}
                                    </p>
                                </div>

                                {this.state.mostViewedProj.length > 0 ? (this.state.mostViewedProj.map((proj, index) => {
                                    return (
                                        <div className="col-4 projDiv" key={index} title={proj.Name}>
                                            <img src={proj.Thumbnail} alt="UploadNew" onClick={() => this.navigate(proj.Id)} />
                                        </div>
                                    )
                                })) : null}
                            </div>
                            <hr className="footerDivHR" />
                            <div className="row helpSection footerDiv">
                                <div className="col-12 text-center">
                                    <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                                </div>
                            </div>
                        </div >
                    </div >
                ) : null
                }

                {this.state.isNotificationPanel ?
                    (
                        <div className="row downloadComposerView p-0 pt-3">
                            <div className="col-12">
                                <div className="row ">
                                    <div className="col-5">
                                        <Link to={Constants.MarketPlace} onClick={() => this.showNotificationPanel(false)}><img src={BackIcon} alt="BackIcon" className="backIcon" /><p className="backBtnText">Back</p></Link>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="titleDiv col-12">
                                        <p className="title">Notifications</p>
                                    </div>
                                    <div className="col-12">
                                        <button className={this.state.notificationActive === 'followrequest' ? "notificationBtnsActive notificationBtns" : "notificationBtns"} onClick={() => this.setnotificationTab('followrequest')} >Follow Requests</button>
                                        <button className={this.state.notificationActive === 'project' ? "notificationBtnsActive notificationBtns" : "notificationBtns"} onClick={() => this.setnotificationTab('project')}>Project</button>
                                        <button className={this.state.notificationActive === 'updates' ? "notificationBtnsActive notificationBtns" : "notificationBtns"} onClick={() => this.setnotificationTab('updates')}>Updates</button>
                                    </div>
                                </div>
                                <hr />

                            </div>
                            {/* <div className="col-12">
                                {this.state.notificationActive === "followrequest" ? ( <div>Follow requests</div>) : null}
                                {this.state.notificationActive === "project" ? ( <div>project requests</div>) : null}
                                {this.state.notificationActive === "updates" ? ( <div>updates requests</div>) : null}
                            </div> */}



                        </div>
                    )
                    : null}


            </div>
        )
    }
}

export default MarketPlaceUserDetails