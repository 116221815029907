import React from 'react';
import '../../../assets/css/Settings.css'
import * as Constants from '../../../assets/helpers/constants'

class SettingsDashboard extends React.Component {
    constructor() {
        super()
        this.state = {
            activeLink: null
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            let path = this.props.location.pathname;
            let activeLink = null
            if (path === Constants.SettingsEmailConfig) {
                activeLink = "emailConfig"
            } else if (path === Constants.SettingsSoftwareRelease) {
                activeLink = "softwareRelease"
            } else if (path.includes('user')) {
                activeLink = "userManagement"
            } else if (path === Constants.SettingsUsageReports) {
                activeLink = "usageReport"
            } else if (path === Constants.SettingsAddStorage) {
                activeLink = "addStorage"
            }else if (path === Constants.SettingsChangePassword) {
                activeLink = "changePassword"
            }
            this.setState({
                activeLink: activeLink
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    navigate = (link, value) => {
        this.setState({
            activeLink: value
        }, () => {
            this.props.history.push(link)
        })
    }
    render() {
        return (
            <div className="SettingsDashboard">
                <div className="row">
                    <div className="col-12 activityLogDiv">
                        <p className="ActivityLogTitle pt-3 ">Content Manager Settings</p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row settingsManagementDiv m-0 mt-2">
                    <div className="col-12 m-0">
                        <div className="row">
                            <div className="col-12">
                                <p className="settingsTitle">Configure and Manage your Experience</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div id="emailConfig" className={this.state.activeLink === "emailConfig" ? "sectionBox activeBox" : "sectionBox"} onClick={(e) => this.navigate(Constants.SettingsEmailConfig, "emailConfig")}>
                                    <p className="settingType">Setting Type</p>
                                    <p className="settingName">Email Configuration</p>
                                    <label className="settingStatus">Status: <span>Published</span></label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div id="userManagement" className={this.state.activeLink === "userManagement" ? "sectionBox activeBox" : "sectionBox"} onClick={(e) => this.navigate(Constants.SettingsUserManagement, "userManagement")}>
                                    <p className="settingType">Setting Type</p>
                                    <p className="settingName">Users Management</p>
                                    <label className="settingStatus">Status: <span>Published</span></label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div id="usageReport" className={this.state.activeLink === "uploadTutorials" ? "sectionBox activeBox" : "sectionBox"} onClick={(e) => this.navigate(Constants.SettingsUploadTutorials, "uploadTutorials")}>
                                    <p className="settingType">Setting Type</p>
                                    <p className="settingName">Upload Tutorials</p>
                                    <label className="settingStatus">Status: <span>Published</span></label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div id="usageReport" className={this.state.activeLink === "addStorage" ? "sectionBox activeBox" : "sectionBox"} onClick={(e) => this.navigate(Constants.SettingsAddStorage, "addStorage")}>
                                    <p className="settingType">Setting Type</p>
                                    <p className="settingName">Add Storage</p>
                                    <label className="settingStatus">Status: <span>Published</span></label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div id="usageReport" className={this.state.activeLink === "changePassword" ? "sectionBox activeBox" : "sectionBox"} onClick={(e) => this.navigate(Constants.SettingsChangePassword, "changePassword")}>
                                    <p className="settingType">Setting Type</p>
                                    <p className="settingName">Change Password</p>
                                    <label className="settingStatus">Status: <span>Published</span></label>
                                </div>
                            </div>
                            {/* <div className="col-3">
                                <div id="usageReport" className={this.state.activeLink === "usageReport" ? "sectionBox activeBox" : "sectionBox"} onClick={(e) => this.navigate(Constants.SettingsUsageReports, "usageReport")}>
                                    <p className="settingType">Setting Type</p>
                                    <p className="settingName">Usage Report</p>
                                    <label className="settingStatus">Status: <span>Published</span></label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div id="softwareRelease" className={this.state.activeLink === "softwareRelease" ? "sectionBox activeBox" : "sectionBox"} onClick={(e) => this.navigate(Constants.SettingsSoftwareRelease, "softwareRelease")}>
                                    <p className="settingType">Setting Type</p>
                                    <p className="settingName">Software Release</p>
                                    <label className="settingStatus">Status: <span>Published</span></label>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingsDashboard