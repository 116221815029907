import React from 'react';
import Axios from 'axios';
import * as Constants from '../../../assets/helpers/constants'
import LoaderComp from '../../Loader'
import Swal from 'sweetalert2'
import CreatableSelect from 'react-select/creatable';

class PartnerRegistration extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            fields: "",
            fullname: "",
            phoneno: "",
            DOB: "",
            email: "",
            companyname: "",
            designation: "",
            address: "",
            source: "",
            companyOptions: [
                {
                    value: "A friend or colleauge",
                    label: "A friend or colleauge"
                },
                {
                    value: "Google",
                    label: "Google"
                },
                {
                    value: "Social Media",
                    label: "Social Media"
                }
            ]
        }
    }

    handleCompanyChange = (newValue, actionMeta) => {
        if (newValue != null) {
            this.setState({
                "source": newValue.value
            })
        } else {
            this.setState({
                "source": ""
            })
        }
    }

    submitForm = (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true
        }, () => {
            if (this.state.fullname.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Full Name is Mandatory',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isLoading: false
                })
                return false
            }

            if (this.state.DOB === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'DOB is Mandatory',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isLoading: false
                })
                return false
            }

            if (this.state.phoneno.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Phone No is Mandatory',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isLoading: false
                })
                return false
            }

            if (this.state.companyname.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Company is Mandatory',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isLoading: false
                })
                return false
            }

            if (this.state.address.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Address is Mandatory',
                    showConfirmButton: false,
                    timer: 1000
                })
                this.setState({
                    isLoading: false
                })
                return false
            }

            if (this.state.source.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Where Did you hear about us field is Mandatory',
                    showConfirmButton: false,
                    timer: 3000
                })
                this.setState({
                    isLoading: false
                })
                return false
            }

            let data = {
                fields: this.state.fields,
                fullname: this.state.fullname,
                phoneno: this.state.phoneno,
                DOB: this.state.DOB,
                email: this.state.email,
                companyname: this.state.companyname,
                designation: this.state.designation,
                address: this.state.address,
                source: this.state.source
            }

            Axios.post(Constants.BASE_URL + "api/PartnerRegistration", data).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Email sent successfully. <br />Please set the password',
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.history.push('/Login');
                    })
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: result.data.info,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                }
            });
        })
    }

    changeValue = (key, e) => {
        this.setState({
            [key]: e.target.value
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="registrationPanel pl-5">
                    {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                    <p className="loginTitle mb-0">PARTNER REGISTRATION</p>
                    <p className="loginsubTitle mb-0">Please create your account.</p>
                    <form className="signin_form" onSubmit={this.submitForm}>
                        <div className="row">
                            <div className="col-12">
                                <input type="text" className="inputfields" value={this.state.fields} placeholder="Fields" onChange={(e) => this.changeValue("fields", e)} />
                            </div>
                            <div className="col-12">
                                <input type="text" className="inputfields" value={this.state.fullname} placeholder="Full Name" onChange={(e) => this.changeValue("fullname", e)} required />
                            </div>
                            <div className="col-6">
                                <input type="date" className="inputfields" value={this.state.DOB} placeholder="Date Of Birth" onChange={(e) => this.changeValue("DOB", e)} required />
                            </div>
                            <div className="col-6">
                                <input type="number" className="inputfields" value={this.state.phoneno} placeholder="Phone No" onChange={(e) => {
                                    if (e.target.value.length > 10) {
                                        return false
                                    } else {
                                        this.changeValue("phoneno", e)
                                    }
                                }} required />
                            </div>
                            <div className="col-12">
                                <input type="email" className="inputfields" value={this.state.email} placeholder="Email" onChange={(e) => this.changeValue("email", e)} required />
                            </div>
                            <div className="col-12">
                                <input type="text" className="inputfields" value={this.state.companyname} placeholder="Company Name" onChange={(e) => this.changeValue("companyname", e)} required />
                            </div>
                            <div className="col-12">
                                <input type="text" className="inputfields" value={this.state.designation} placeholder="Designation" onChange={(e) => this.changeValue("designation", e)} />
                            </div>
                            <div className="col-12 mb-4">
                                <textarea className="inputfields" value={this.state.address} placeholder="Address" cols="50" rows="5" maxLength="254" onChange={(e) => this.changeValue("address", e)} required />
                            </div>
                            <div className="col-12">
                                <CreatableSelect
                                    isClearable
                                    onChange={this.handleCompanyChange}
                                    options={this.state.companyOptions}
                                    placeholder="Where did you hear about us ?"
                                />
                            </div>
                        </div>
                        <div className="row submitButtonSection">
                            <div className="col-6">
                                <button type="submit" className="btn LoginButton">Register</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default PartnerRegistration