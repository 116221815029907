import React from 'react';
// import { Link } from "react-router-dom";
import Axios from 'axios';
import * as Constants from '../../../assets/helpers/constants'
import pdfIcon from '../../../assets/images/Group 499@2x.png'
import xmlIcon from '../../../assets/images/Group 502@2x.png'
import mp4Icon from '../../../assets/images/Group 504@2x.png'
import flvIcon from '../../../assets/images/Group 495@2x.png'
import mp3Icon from '../../../assets/images/Group 501@2x.png'
import LoaderComp from '../../Loader'
import Swal from 'sweetalert2'
import { ProgressBar } from 'react-bootstrap';

let source;
class UploadFiles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: [],
            location: "",
            userId: null,
            companyId: null,
            percentage: 0,
            isDisabled: false,
            showSubmitBtn: true
        }
    }

    componentDidMount() {
        let details = JSON.parse(localStorage.getItem("details"))
        this.setState({
            location: details.location,
            userId: details.userId,
            companyId: details.companyId
        })
    }

    componentWillUnmount() {
        // localStorage.removeItem("details");
    }

    fileupload = (e) => {
        let newFiles = []
        for (const file in e.target.files) {
            if (typeof e.target.files[file] === 'object') {
                newFiles.push(e.target.files[file])
            }
        }
        let finalList = this.state.file.concat(newFiles)
        this.setState({
            file: finalList
        }, () => {
            this.generatePreview()
        })
    }

    removeItem = (removeItem) => {
        let filesList = this.state.file
        let newFilesList = filesList.filter((file) => {
            let fileName = file.name.replace(/[^A-Z0-9]/ig, "")
            return fileName !== removeItem
        })
        this.setState({
            file: newFilesList
        }, () => {
            this.generatePreview()
        })
    }

    generatePreview = () => {
        const files = this.state.file
        const state = this
        const sourceDiv = document.getElementById("preview");
        sourceDiv.innerHTML = ""
        for (var y = 0; y < files.length; y++) {
            var reader = new FileReader();
            let fileDetails = files[y]
            let fileName = fileDetails.name.replace(/[^A-Z0-9]/ig, "")
            if (fileDetails.type === 'image/png' || fileDetails.type === 'image/jpg' || fileDetails.type === 'image/jpeg') {
                reader.onload = function (e) {
                    var div = document.createElement("div");
                    div.setAttribute('class', 'previewDiv col-3 ' + fileName);
                    var img = document.createElement("img");
                    img.title = fileDetails.name;
                    img.src = e.target.result;

                    var closeBtn = document.createElement("p");
                    closeBtn.innerHTML = "X"
                    closeBtn.title = "Remove File"
                    closeBtn.onclick = () => state.removeItem(fileName)
                    div.appendChild(closeBtn)
                    div.appendChild(img)
                    sourceDiv.appendChild(div);
                };
                reader.readAsDataURL(files[y]);
            } else {
                var div = document.createElement("div");
                div.setAttribute('class', 'previewDiv col-3 ' + fileName);
                var img = document.createElement("img");
                img.title = fileDetails.name;
                if (fileDetails.name.includes(".pdf") || fileDetails.name.includes(".PDF")) {
                    img.src = pdfIcon
                } else if (fileDetails.name.includes(".xml") || fileDetails.name.includes(".XML")) {
                    img.src = xmlIcon
                } else if (fileDetails.name.includes(".mp4") || fileDetails.name.includes(".MP4")) {
                    img.src = mp4Icon
                } else if (fileDetails.name.includes(".mp3") || fileDetails.name.includes(".MP3")) {
                    img.src = mp3Icon
                } else if (fileDetails.name.includes(".flv") || fileDetails.name.includes(".FLV")) {
                    img.src = flvIcon
                }

                var closeBtn = document.createElement("p");
                closeBtn.innerHTML = "X"
                closeBtn.title = "Remove File"
                closeBtn.onclick = () => state.removeItem(fileName)
                div.appendChild(closeBtn)
                div.appendChild(img)
                sourceDiv.appendChild(div);
            }
        }
    }

    submitFiles = (e) => {
        e.preventDefault()
        this.setState({
            isLoading: true,
            isDisabled: false,
            showSubmitBtn: false
            // isDisabled: true
        }, () => {
            var files = this.state.file

            source = Axios.CancelToken.source();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            var options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent
                    let percentage = Math.floor(loaded * 100 / total)
                    if (percentage < 100) {
                        this.setState({
                            percentage: percentage
                        })
                    }
                },
                cancelToken: source.token
            }

            // Append data to form data
            const formData = new FormData();
            formData.append("location", this.state.location)
            formData.append("userId", this.state.userId)
            let filesSize = 0
            for (var i = 0; i < files.length; i++) {
                formData.append(files[i].name, files[i])
                filesSize = filesSize + files[i].size
            }

            let data = {
                fileSize: filesSize,
                companyId: this.state.companyId
            }

            Axios.post(Constants.BASE_URL + `api/validateStorage`, data).then((result) => {
                if (!result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Please upgrade Company storage to upload. Please contact admin! ",
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {
                    Axios.post(Constants.BASE_URL + "api/mediaManagement/uploadFiles", formData, options, config).then((result) => {
                        if (result.data.status) {
                            this.props.getFileManagerFolderDetails(this.state.location)
                            this.props.history.push(Constants.FileManager)
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: "Files Uploaded Sucessfully",
                                showConfirmButton: false,
                                timer: 1000
                            })
                            localStorage.removeItem("details");
                        } else {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: "Error while Uploading files",
                                showConfirmButton: false,
                                timer: 1000
                            })
                        }
                    });
                }
            })
        })
    }

    cancelUpload = () => {
        if (source) {
            source.cancel("On click Cancel");
            this.setState({
                percentage: 0,
                isDisabled: false,
                isLoading: false,
                showSubmitBtn: true
            })
        }
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="signin uploadFiles pl-5">
                    {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                    <p className="loginTitle mb-0">Upload files</p>
                    <p className="loginsubTitle mb-0">Please select Files to Upload</p>

                    {this.state.percentage > 0 && !this.state.showSubmitBtn && <p>Uploading files ... </p>}
                    <form id="uploadFilesDiv" onSubmit={this.submitFiles}>
                        {/* <label htmlFor="FileUpload1" className="chooseFilesText">Choose Files</label> */}
                        <input type="file" onChange={this.fileupload} id="FileUpload1" multiple disabled={this.state.isDisabled} />
                        <div id="preview" className="row"></div>
                        {this.state.percentage > 0 ? (
                            <ProgressBar now={this.state.percentage} active={"true"} label={`${this.state.percentage}%`} />
                        ) : null}
                        <br />
                        {this.state.percentage > 0 ? <button type="button" onClick={this.cancelUpload} className="CancelRequestButton submitButtonSection">Cancel Upload</button> : null}
                        {this.state.file.length > 0 && this.state.showSubmitBtn ? (<button type="submit" id="submitFilesbtn" onClick={this.submitFiles} >Submit</button>) : null}
                    </form>
                </div>
            </div>
        );
    }
}

export default UploadFiles