import React from 'react'
import Axios from 'axios';
import * as Constants from '../../../assets/helpers/constants'
import LoaderComp from '../../Loader'
import Swal from 'sweetalert2'
import { ProgressBar } from 'react-bootstrap';

let source;
class UpdateExperience extends React.Component {
    constructor() {
        super()
        this.state = {
            selectfilePlaceHolder: "",
            isLoading: false,
            percentage: 0,
            file: "",
            expId: '',
            expName: '',
            isDisabled: false,
            companyId: null
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            let url = this.props.location.pathname.split('/')
            let expName = url.pop()
            let expId = url.pop()
            let companyId = url.pop()
            this.setState({
                expId: expId,
                companyId: companyId,
                expName: expName
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    submitexperience = () => {
        Swal.fire({
            title: 'Are you sure you want to Update Experience?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Update'
        }).then((result) => {
            if (result.isConfirmed) {
                source = Axios.CancelToken.source();
                this.setState({
                    isLoading: true
                }, () => {
                    const formData = new FormData();
                    formData.append('fileUpload', this.state.file);
                    formData.append('expId', this.state.expId);
                    formData.append('UserId', JSON.parse(localStorage.getItem("userDetails"))[0].UserID !== null ? JSON.parse(localStorage.getItem("userDetails"))[0].UserID : 0);
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    var options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent
                            let percentage = Math.floor(loaded * 100 / total)
                            if (percentage < 100) {
                                this.setState({
                                    percentage: percentage
                                })
                            }
                        },
                        cancelToken: source.token
                    }

                    Axios.post(Constants.BASE_URL + "api/ExperienceManagement/updateExp", formData, options, config).then((result) => {
                        if (result.data.status) {
                            this.setState({
                                isLoading: false
                            }, () => {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: "Successfully updated the experience.",
                                    showConfirmButton: false,
                                    timer: 2000
                                })
                                this.props.getExperiences()
                                this.props.history.push(Constants.ExperienceDashboard)
                            })
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'error',
                                    title: "Error while Updating the experience. Please try again later.",
                                    showConfirmButton: false,
                                    timer: 3000
                                })
                            })
                        }
                    })
                })
            }
        })
    }

    cancelUpload = () => {
        if (source) {
            source.cancel("On click Cancel");
            this.setState({
                percentage: 0,
                isDisabled: false,
                isLoading: false
            })
        }
    }

    changeFile = (e) => {
        var fileName = e.target.value
        var extension = fileName.split('.').pop();
        if (extension !== 'exp') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please upload only <br /> experience file",
                showConfirmButton: false,
                timer: 3000
            })
            this.setState({
                selectfilePlaceHolder: ""
            })
        } else {
            let data = {
                fileSize: e.target.files[0].size,
                companyId: this.state.companyId
            }
            Axios.post(Constants.BASE_URL + `api/validateStorage`, data).then((result) => {
                if (!result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Please upgrade Company storage to upload. Please contact admin! ",
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.setState({
                        file: "",
                        selectfilePlaceHolder: ""
                    })
                } else {
                    this.setState({
                        file: e.target.files[0],
                        selectfilePlaceHolder: e.target.files[0].name
                    })
                }
            })

            this.setState({
                file: e.target.files[0],
                selectfilePlaceHolder: e.target.files[0].name
            })
        }
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="signin pl-5 addExperience">
                    <p className="loginTitle mb-0">Update Experience</p>
                    <div className='row mt-5'>
                        <label className="w-100 pl-3 experienceNameTitle">
                            Experience Name : <span>{this.state.expName}</span>
                        </label>
                    </div>
                    <div className="row mt-5 ">
                        <div className="titleDiv col-12">
                            <div className="fileDiv">
                                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}

                                <label className="w-100">
                                    <input type="file" id="files" className="hide" name="expFile" accept="*.exp" placeholder="Upload Experience File" onChange={this.changeFile} required disabled={this.state.isDisabled} />
                                    <label htmlFor="files">{this.state.selectfilePlaceHolder === "" ? "Select file to Upload" : this.state.selectfilePlaceHolder}</label>
                                </label>
                            </div>
                            <div>
                                {this.state.percentage > 0 ? (
                                    <ProgressBar now={this.state.percentage} active={"true"} label={`${this.state.percentage}%`} />
                                ) : null}
                                <br />
                                {this.state.percentage > 0 ? <button type="button" onClick={this.cancelUpload} className="CancelRequestButton">Cancel Upload</button> : null}
                            </div>
                            <button disabled={this.state.percentage > 0 ? true : false} type="button" onClick={this.submitexperience} className="uploadExperienceBtn">Update Experience </button>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default UpdateExperience