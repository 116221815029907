import React from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios';
import * as Constants from '../../../../assets/helpers/constants'
import LoaderComp from '../../../Loader'
import Swal from 'sweetalert2'
import BackIcon from '../../../../assets/images/Untitled-1-10.png'
import HeartIcon from '../../../../assets/images/Group 420@2x.png'
import BookmarkIcon from '../../../../assets/images/Group 422@2x.png'
import ViewIcon from '../../../../assets/images/Group 423@2x.png'
import ShareIcon from '../../../../assets/images/Group 421@2x.png'

export default class MyProjects extends React.Component {
    constructor() {
        super()
        this.state = {
            projects: [],
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getProjects()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getProjects = () => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/MarketPlace/getMyProjects/${this.props.UserInfo.UserID}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        projects: result.data.info,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Error in fetching details.Please try later",
                            showConfirmButton: false,
                            timer: 3000
                        })
                    })
                }
            })
        })
    }

    Navigate = (id) => {
        // this.props.incrementViews(id)
        this.props.history.push(Constants.MyProjects + "/" + id)
    }

    render() {
        return (
            <div className="discoverProjectsDiv">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="row  pl-3">
                    <div className="col-1">
                        <Link to={Constants.MarketPlace} onClick={() => this.props.setLink(null)}><img src={BackIcon} alt="BackIcon" className="backIcon" /><p className="backBtnText">Back</p></Link>
                    </div>
                </div>
                <div className="row projectsContent">
                    <div className="col-12">
                        <div className="row">
                            {this.state.projects.length > 0 ? (
                                this.state.projects.map((project, index) => {
                                    return (
                                        <div className="col-3 individualProj" key={index} id={"proj_" + index}>
                                            <img className="mainImg" src={project.Thumbnail} onClick={() => this.Navigate(project.Id)} alt="ProjectIcons" />
                                            <label>
                                                <p className="projectTitle">{project.Name}</p>
                                            </label>
                                            <label className="bookmarkIconDiv">
                                                <img src={BookmarkIcon} alt="BookmarkIcon" /><br />
                                                <span>{project.SavedCount}</span>
                                            </label>

                                            <label className="heartIconDiv">
                                                <img src={ShareIcon} alt="HeartIcon" /> <br />
                                                <span>{project.Shares}</span>
                                            </label>
                                            <label className="heartIconDiv" >
                                                    <span className="imagesection">
                                                        <img src={HeartIcon} alt="HeartIcon"  />
                                                    </span>
                                                <br />
                                                <span className="count">{project.Likes}</span>
                                            </label>
                                            <label className="heartIconDiv">
                                                <img src={ViewIcon} alt="BookmarkIcon" /><br />
                                                <span>{project.Views}</span>
                                            </label>
                                        </div>
                                    )
                                })
                            ) : null}

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}