import React from 'react';
import Axios from 'axios';
import SidePanelFooter from './sidepanelFooter'
import '../../../assets/css/RightSideSlider.css'
import * as Constants from '../../../assets/helpers/constants'
import AndroidIcon from '../../../assets/images/AndroidUp.png'
import IOSIcon from '../../../assets/images/IOSUp.png'
import WindowsIcon from '../../../assets/images/WindowsUp.png'
import Swal from 'sweetalert2'

class DeviceList extends React.Component {
    constructor() {
        super()
        this.state = {
            devicesList: [],
            filteredDevicesList: [],
            experienceId: null
        }
    }
    componentDidMount() {
        const expId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        this.getDeviceList(expId)
    }
    getDeviceList = (expId) => {
        Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getExperienceDeviceList/${expId}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    experienceId: expId,
                    devicesList: result.data.info,
                    filteredDevicesList: result.data.info
                })
            } else {
                this.setState({
                    devicesList: [],
                    filteredDevicesList: [],
                    experienceId: expId,
                })
            }
        })
    }

    searchDevices = (value) => {
        let intialFiles = this.state.devicesList
        let latestFiles = this.state.devicesList
        if (value !== "") {
            latestFiles = intialFiles.filter((media) => {
                return media.Name.toLowerCase().includes(value.toLowerCase())
            })
        }
        this.setState(prevState => ({
            filteredDevicesList: latestFiles
        }))
    }

    render() {
        return (
            <div className="rightSidePanel slide deviceList">
                <div className="signin pl-5 ">
                    <div className="row">
                        <div className="col-12 p-0">
                            <label className="searchDiv"><i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                <input type="text" placeholder="Search keyword" onChange={(e) => this.searchDevices(e.target.value)} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="deviceDisplayText">All the devices listed below are linked to the selected experience</p>
                        </div>
                    </div>
                    <div className="row devicesContainer">
                        <div className="col-12">
                            {this.state.filteredDevicesList.map((device, index) => {
                                return (
                                    <div key={index} className="row individualDevice" >
                                        <div className="col-3">
                                            <img src={device.OS === "IOS" ? IOSIcon : device.OS === "Android" ? AndroidIcon : WindowsIcon} alt="AndroidIcon" />
                                        </div>
                                        <div className="col-7 deviceDetails">
                                            <p className="deviceName mb-2">{device.Name}</p>
                                            <p className="deviceStatus mb-2" >{device.Is_Active ? "Active" : "Not Active "}</p>
                                        </div>
                                        {/* <div className="col-2 deviceDetails">
                                            <p className="closeBtn" onClick={() => this.deleteDevice(device.Id)}>X</p>
                                        </div> */}
                                    </div>
                                )
                            })
                            }
                        </div>

                    </div>
                    <SidePanelFooter />
                </div>
            </div>
        )
    }
}

export default DeviceList