import React from 'react'
import { Route } from 'react-router-dom'
import * as Constants from '../../../assets/helpers/constants'
import Login from './login'
import Recovery from './recovery'
import ResetPassword from './resetPassword'
import UserDetails from './userDetails'
import AddExperience from './addExperience'
import DeviceList from './showDeviceList'
import LicenceDetails from './licenceDetails'
import MediaDetails from './mediaDetails'
import UsersList from './settingsUsersList'
import EmailConfig from './settingsEmailConfig'
import SoftwareRelease from './settingsSoftwareRelease'
import UsageReports from './settingsReports'
import DeviceDetails from './deviceDetails'
import PreviewFile from './previewFile'
import BuyLicenses from './buyLicenses'
import PartnerRegistration from './partnerRegistration'
import UploadFiles from './uploadFilesPanel'
import MarketPlaceUserDetails from './marketPlaceUserInfo'
import MarketPlaceDiscoverUserDetails from './marketPlaceDiscoverUserInfo'
import DownloadComposerView from './DownloadComposerView'
import UploadTutorials from './settingsTutotialsUpload'
import UpdateExperience from './updateExperience'
import AddStorage from './addStorage'
import Changepassword from './changePassword'

class RightSidePanel extends React.Component {
    componentDidMount() {
        if (localStorage.getItem("userDetails") !== null) {
            this.props.history.push("/Contents/DashBoard");
        }
    }

    render() {
        return (
            <div>
                {this.props.UserDetailsInfo.length > 0 ? (<UserDetails  {...this.props} />) : null}
                <Route exact path={Constants.Login} component={() => <Login {...this.props} />} />
                <Route exact path={Constants.Recovery} component={() => <Recovery {...this.props} />} />
                <Route exact path={Constants.PartnerRegistration} component={() => <PartnerRegistration {...this.props} />} />
                <Route exact path={Constants.ResetPassword + "/*/*"} component={() => <ResetPassword {...this.props} />} />
                <Route exact path={Constants.AddExperience + "/*"} component={() => <AddExperience {...this.props} />} />
                <Route exact path={Constants.DeviceList + "/*"} component={() => <DeviceList {...this.props} />} />
                <Route exact path={Constants.LicenseDetails + "/*"} component={() => <LicenceDetails {...this.props} />} />
                <Route exact path={Constants.MediaManagement + "/*/*/*"} component={() => <MediaDetails {...this.props} />} />
                <Route exact path={Constants.SettingsUserManagement} component={() => <UsersList {...this.props} />} />
                <Route exact path={Constants.SettingsEmailConfig} component={() => <EmailConfig {...this.props} />} />
                <Route exact path={Constants.SettingsSoftwareRelease} component={() => <SoftwareRelease {...this.props} />} />
                <Route exact path={Constants.SettingsUsageReports} component={() => <UsageReports {...this.props} />} />
                <Route exact path={Constants.SettingsChangePassword} component={() => <Changepassword {...this.props} />} />
                <Route exact path={Constants.IndividualDeviceDetails + "/*"} component={() => <DeviceDetails {...this.props} />} />
                <Route exact path={Constants.PreviewFile + "/*"} component={() => <PreviewFile {...this.props} />} />
                <Route exact path={Constants.BuyLicenses} component={() => <BuyLicenses {...this.props} />} />
                <Route exact path={Constants.UploadFiles} component={() => <UploadFiles {...this.props} />} />
                <Route exact path={Constants.Discover+"/*/*"} component={() => <MarketPlaceDiscoverUserDetails {...this.props} />} />
                <Route exact path={[Constants.MyProjects+"/*",Constants.People+"/*",Constants.DownloadComposer+"/*",Constants.MarketPlace,Constants.MyProjects,Constants.People,Constants.Discover,Constants.Tutorials,Constants.UploadNew]} component={() => <MarketPlaceUserDetails {...this.props} />} />
                <Route exact path={Constants.DownloadComposerView} component={() => <DownloadComposerView {...this.props} />} />
                <Route exact path={Constants.SettingsUploadTutorials} component={() => <UploadTutorials {...this.props} />} />
                <Route exact path={Constants.UpdateExperience+"/*"} component={() => <UpdateExperience {...this.props} />} />
                <Route exact path={Constants.SettingsAddStorage} component={() => <AddStorage {...this.props} />} />
            </div>
        )
    }
}

export default RightSidePanel