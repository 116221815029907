import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import * as Constants from './assets/helpers/constants'
import Contents from './components/Content/Contents'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/MediaQueries.css'
import './assets/css/fonts.css'
import './assets/css/App.css'
import Forum from './components/Forum';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  setLoader = (value) => {
    this.setState({
      isLoading: value
    })
  }

  render() {
    return (
      <HashRouter>
        <div className='Mobile'>
          <h3>
            Please use the devices that have minimum resolution of 1366*768
          </h3>
        </div>
        <div className='App'>
          <Switch >
            <Route exact path={Constants.ResetPassword + "/*"} component={() => <Contents pageType="ResetPassword" />} />
            <Route exact path={Constants.Login} component={() => <Contents pageType="Login" />} />
            <Route exact path={Constants.Recovery} component={() => <Contents pageType="Recovery" />} />
            <Route exact path={Constants.PartnerRegistration} component={() => <Contents pageType="Registration" />} />
            <Route path={Constants.Contents} component={() => <Contents pageType="Normal" />} />
            <Route exact path={Constants.InitialPath} component={() => <Contents pageType="Normal" />} />
            <Route path={[Constants.AdminDashboard, Constants.AdminContents]} component={() => <Contents pageType="Normal" />} />
            <Route path={Constants.Forum} component={() => <Forum />} />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}

export default App;
