import React from 'react'
import '../../../assets/css/UserDetails.css'
import * as Constants from '../../../assets/helpers/constants'
import withOutNotification from '../../../assets/images/Bell.png'
import withNotification from '../../../assets/images/BellNotify.png'
import userIcon from '../../../assets/images/ProfileIcon.png'
import CautionImg from '../../../assets/images/Caution-02.png'

class AdminUserDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasNotifications: true,
            isNotificationPanel: false,
            userDetails: []
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            var userDetails = JSON.parse(localStorage.getItem("userDetails"))
            this.setState({
                userDetails: userDetails[0]
            })
        }else{
            this.props.history.push(Constants.Login)
        }
    }

    toggleNotification = () => {
        this.setState({
            isNotificationPanel: !this.state.isNotificationPanel
        })
    }

    navigate = () => {
        this.props.history.push(Constants.SettingsDashboard)
    }

    render() {
        return (
            < div className="adminUserDetails userDetails p-0" >
                {
                    this.state.isNotificationPanel ?
                        (<div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="notificationPanel w-100 text-center">
                                        <img src={withNotification} alt="Notification" />
                                        <span className="text-center notificationTitle">Notification</span>
                                        <span className="text-right closeBtn" onClick={this.toggleNotification}>X</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row mt-4 helpSection">
                                <div className="col-3 pr-0 text-left">
                                    <img src={CautionImg} className="helpIcons" alt="CautionImg" />
                                </div>
                                <div className="col-7 pr-0 text-left">
                                    <p className="helpTitle mb-2">Uploading Experience</p>
                                    <p className="helpSubTitle">Lorem ipsum dolor sit amet, consetetur</p>
                                </div>
                                <div className="col-2 text-center clearBtn">
                                    <p>Clear</p>
                                </div>
                            </div>

                        </div>
                        ) :
                        (<div>
                            <div className="row">
                                <div className="col-12 p-0">
                                    {/* <label className="searchDiv"><i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                        <input type="text" placeholder="Search keyword" />
                                    </label> */}
                                </div>

                            </div>
                            <div className="row userInformation mt-4">
                                <div className="col-8">
                                    <p className="greetings mb-3">Hello,</p>
                                    <h4 className="userName">{this.state.userDetails.Name}!</h4>
                                    <p className="userRole">Role: {this.state.userDetails.Role}</p>
                                </div>
                                <div className="col-4 text-center">
                                    <img src={userIcon} alt="User Icon" className="userIcon" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 w-100 p-0 hrDiv">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 p-0">
                                    <div className="notificationBox">
                                        {this.state.hasNotifications ? <img className="withNotification" src={withNotification} alt="Notification" onClick={this.toggleNotification} /> : <img src={withOutNotification} alt="Notification" />}
                                    </div>
                                    <label>
                                        <span className="notificationText">Notification</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row notificationsDiv">
                                <div className="col-12">
                                    <div className="row mt-4 helpSection">
                                        <div className="col-3 pr-0 text-left">
                                            <img src={CautionImg} className="helpIcons" alt="CautionImg" />
                                        </div>
                                        <div className="col-7 pr-0 text-left">
                                            <p className="helpTitle mb-2">License Expiry</p>
                                            <p className="helpSubTitle">Lorem ipsum dolor sit amet, consetetur</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="clearText">Clear</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4 helpSection">
                                        <div className="col-3 pr-0 text-left">
                                            <img src={CautionImg} className="helpIcons" alt="CautionImg" />
                                        </div>
                                        <div className="col-7 pr-0 text-left">
                                            <p className="helpTitle mb-2">License Expiry</p>
                                            <p className="helpSubTitle">Lorem ipsum dolor sit amet, consetetur</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="clearText">Clear</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4 helpSection">
                                        <div className="col-3 pr-0 text-left">
                                            <img src={CautionImg} className="helpIcons" alt="CautionImg" />
                                        </div>
                                        <div className="col-7 pr-0 text-left">
                                            <p className="helpTitle mb-2">License Expiry</p>
                                            <p className="helpSubTitle">Lorem ipsum dolor sit amet, consetetur</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="clearText">Clear</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4 helpSection">
                                        <div className="col-3 pr-0 text-left">
                                            <img src={CautionImg} className="helpIcons" alt="CautionImg" />
                                        </div>
                                        <div className="col-7 pr-0 text-left">
                                            <p className="helpTitle mb-2">License Expiry</p>
                                            <p className="helpSubTitle">Lorem ipsum dolor sit amet, consetetur</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="clearText">Clear</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4 helpSection">
                                        <div className="col-3 pr-0 text-left">
                                            <img src={CautionImg} className="helpIcons" alt="CautionImg" />
                                        </div>
                                        <div className="col-7 pr-0 text-left">
                                            <p className="helpTitle mb-2">License Expiry</p>
                                            <p className="helpSubTitle">Lorem ipsum dolor sit amet, consetetur</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="clearText">Clear</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>)
                }
                <hr className="footerDivHR" />
                <div className="row helpSection footerDiv">
                    <div className="col-12 text-center">
                        <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminUserDetails