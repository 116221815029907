import React from 'react'

function SidePanelFooter() {
    return (
        <div>
            <hr className="footerDivHR" />
            <div className="row helpSection footerDiv">
                <div className="col-12 text-center">
                    <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default SidePanelFooter