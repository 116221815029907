import React from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios';
import LoaderComp from '../../../Loader'
import Swal from 'sweetalert2'

import * as Constants from '../../../../assets/helpers/constants'
import BackIcon from '../../../../assets/images/Untitled-1-10.png'
import HeartIcon from '../../../../assets/images/Group 420@2x.png'
import HeartIconDown from '../../../../assets/images/Path 412@2x.png'

import BookmarkIcon from '../../../../assets/images/Group 422@2x.png'
import BookmarkIconDown from '../../../../assets/images/Group 422.png'

export default class Discover extends React.Component {
    constructor() {
        super()
        this.state = {
            projects: [],
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getProjects()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    likeProject = (projectId, devisionId) => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/likeProject/${this.props.UserInfo.UserID}/${projectId}`).then((result) => {
            if (result.data.status) {
                const parent = document.getElementById(devisionId);
                const image = parent.querySelector('.heartIconDiv .imagesection')
                image.innerHTML = ""
                let newImage = document.createElement('img')
                newImage.src = HeartIcon
                newImage.alt = "HeartIcon"
                newImage.onclick = () => this.unlikeProject(projectId, devisionId)
                image.appendChild(newImage)
                if (result.data.info !== "Already exists") {
                    const likes = parent.querySelector('.heartIconDiv .count')
                    let likesValue = parseInt(likes.textContent)
                    likes.innerHTML = likesValue + 1
                }
            }
        })
    }

    unlikeProject = (projectId, devisionId) => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/unlikeProject/${this.props.UserInfo.UserID}/${projectId}`).then((result) => {
            if (result.data.status) {
                const parent = document.getElementById(devisionId);
                const image = parent.querySelector('.heartIconDiv .imagesection')
                image.innerHTML = ""
                let newImage = document.createElement('img')
                newImage.src = HeartIconDown
                newImage.alt = "HeartIconDown"
                newImage.onclick = () => this.likeProject(projectId, devisionId)
                image.appendChild(newImage)

                const likes = parent.querySelector('.heartIconDiv .count')
                let likesValue = parseInt(likes.textContent)
                likes.innerHTML = likesValue - 1
            }
        })
    }

    saveProject = (projectId, devisionId) => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/saveProject/${this.props.UserInfo.UserID}/${projectId}`).then((result) => {
            if (result.data.status) {
                const parent = document.getElementById(devisionId);
                const image = parent.querySelector(' .bookmarksection')
                image.innerHTML = ""
                let newImage = document.createElement('img')
                newImage.src = BookmarkIcon
                newImage.alt = "BookmarkIcon"
                newImage.onclick = () => this.unsaveProject(projectId, devisionId)
                image.appendChild(newImage)
                if (result.data.info !== "Already exists") {
                    const likes = parent.querySelector(' .savedcount')
                    let likesValue = parseInt(likes.textContent)
                    likes.innerHTML = likesValue + 1
                }
            }
        })
    }

    unsaveProject = (projectId, devisionId) => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/unsaveProject/${this.props.UserInfo.UserID}/${projectId}`).then((result) => {
            if (result.data.status) {
                const parent = document.getElementById(devisionId);
                const image = parent.querySelector('.bookmarksection')
                image.innerHTML = ""
                let newImage = document.createElement('img')
                newImage.src = BookmarkIconDown
                newImage.alt = "BookmarkIconDown"
                newImage.onclick = () => this.saveProject(projectId, devisionId)
                image.appendChild(newImage)

                const likes = parent.querySelector('.savedcount')
                let likesValue = parseInt(likes.textContent)
                likes.innerHTML = likesValue - 1
            }
        })
    }

    getProjects = () => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/MarketPlace/getDiscoveryProjects/${this.props.UserInfo.UserID}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        projects: result.data.info,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Error in fetching details.Please try later",
                            showConfirmButton: false,
                            timer: 3000
                        })
                    })
                }
            })
        })
    }

    Navigate = (id, createdBy) => {
        this.props.incrementViews(id)
        this.props.history.push(Constants.Discover + "/" + id + "/" + createdBy)
    }

    render() {
        return (
            <div className="discoverProjectsDiv">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="row pl-3">
                    <div className="col-1">
                        <Link to={Constants.MarketPlace} onClick={() => this.props.setLink(null)}><img src={BackIcon} alt="BackIcon" className="backIcon" /><p className="backBtnText">Back</p></Link>
                    </div>
                </div>
                <div className="row projectsContent">
                    <div className="col-12">
                        <div className="row">
                            {this.state.projects.length > 0 ? (
                                this.state.projects.map((project, index) => {
                                    return (
                                        // existsId
                                        <div className="col-3 individualProj" id={"proj_" + index} key={index} >
                                            <img className="mainImg" onClick={() => this.Navigate(project.Id, project.Created_By)} src={project.Thumbnail} alt="ProjectIcons" />
                                            {/* <img className="mainImg"  src={project.Thumbnail} alt="ProjectIcons" /> */}
                                            <label>
                                                <p className="projectTitle"> {project.Name}</p>
                                            </label>
                                            <label className="bookmarkIconDiv" >
                                                {project.existsSaved ? (
                                                    <span className="bookmarksection">
                                                        <img src={BookmarkIcon} alt="BookmarkIcon" onClick={() => this.unsaveProject(project.Id, "proj_" + index)} />
                                                    </span>
                                                ) : (
                                                    <span className="bookmarksection">
                                                        <img src={BookmarkIconDown} alt="BookmarkIcon" onClick={() => this.saveProject(project.Id, "proj_" + index)} />
                                                    </span>
                                                )}

                                                <br />
                                                <span className="savedcount">{project.SavedCount}</span>
                                            </label>

                                            <label className="heartIconDiv" >
                                                {project.existsLike ? (
                                                    <span className="imagesection">
                                                        <img src={HeartIcon} alt="HeartIcon" onClick={() => this.unlikeProject(project.Id, "proj_" + index)} />
                                                    </span>

                                                ) : (
                                                    <span className="imagesection">
                                                        <img src={HeartIconDown} alt="HeartIcon" onClick={() => this.likeProject(project.Id, "proj_" + index)} />
                                                    </span>
                                                )}
                                                <br />
                                                <span className="count">{project.Likes}</span>
                                            </label>
                                        </div>
                                    )
                                })
                            ) : null}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}