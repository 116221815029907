import React from 'react'
import { Link } from 'react-router-dom'
import * as Constants from '../../../../assets/helpers/constants'
import Axios from 'axios';
import Swal from 'sweetalert2'
import LoaderComp from '../../../Loader'
import { ProgressBar } from 'react-bootstrap';

import BackIcon from '../../../../assets/images/Untitled-1-10.png'

import ImageUp from '../../../../assets/images/Group 1037.png'
import ImageDown from '../../../../assets/images/Group 1041.png'
import VideoUp from '../../../../assets/images/Group 1038.png'
import VideoDown from '../../../../assets/images/Group 897.png'
import ExpUp from '../../../../assets/images/Group 1039.png'
import ExpDown from '../../../../assets/images/Group 1026.png'
import ProjectUp from '../../../../assets/images/Group 1040.png'
import ProjectDown from '../../../../assets/images/Group 1042.png'


let source;

export default class UploadNew extends React.Component {
    constructor() {
        super()
        this.state = {
            isImage: true,
            isVideo: false,
            isExp: false,
            isProject: false,
            images: [],
            videos: [],
            experiencefile: null,
            projectFile: null,
            Title: "",
            Description: "",
            isLoading: false,
            percentage: 0,
            isDisabled: false,
            thumbnail: null,
            isProjectDetailsView: false
        }
    }

    componentDidMount() {
        if (this.props.UserInfo === undefined) {
            this.props.history.push(Constants.Login)
        }
    }

    openView = (value) => {
        this.setState({
            isImage: value === "isImage" ? true : false,
            isVideo: value === "isVideo" ? true : false,
            isExp: value === "isExp" ? true : false,
            isProject: value === "isProject" ? true : false,
        })
    }

    uploadImages = (e) => {
        this.setState({
            images: e.target.files
        })
    }

    uploadVideos = (e) => {
        this.setState({
            videos: e.target.files
        })
    }

    uploadExp = (e) => {
        var fileName = e.target.value
        var extension = fileName.split('.').pop();
        if (extension !== 'exp') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please upload only <br /> experience file",
                showConfirmButton: false,
                timer: 3000
            })
            this.setState({
                experiencefile: null
            })
        } else {
            this.setState({
                experiencefile: e.target.files[0]
            })
        }
    }

    uploadThumbnailImage = (e) => {
        if (e.target.files.length > 0) {
            this.setState({
                thumbnail: e.target.files[0]
            })
        } else {
            this.setState({
                thumbnail: null
            })
        }
    }

    uploadProject = (e) => {
        var fileName = e.target.value
        var extension = fileName.split('.').pop();
        if (extension !== 'prj') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please upload only <br /> Project file",
                showConfirmButton: false,
                timer: 3000
            })
            this.setState({
                projectFile: null
            })
        } else {
            this.setState({
                projectFile: e.target.files[0]
            })
        }
    }

    setValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    nextScreen = () => {
        this.setState({
            isProjectDetailsView: true
        })
    }

    submitProjectdetails = () => {
        this.setState({
            isDisabled: true
        }, () => {
            this.openView("")

            source = Axios.CancelToken.source();
            const formData = new FormData();
            formData.append('experienceFile', this.state.experiencefile);
            formData.append('projectFile', this.state.projectFile);
            formData.append('thumbnail', this.state.thumbnail);
            for (let index = 0; index < this.state.images.length; index++) {
                const element = this.state.images[index];
                formData.append('image' + index, element)

            }
            for (let index = 0; index < this.state.videos.length; index++) {
                const element = this.state.videos[index];
                formData.append('video' + index, element)

            }

            formData.append('title', this.state.Title);
            formData.append('description', this.state.Description);
            formData.append('userId', this.props.UserInfo.UserID);
            formData.append('companyName', this.props.UserInfo.CompanyName)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            var options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent
                    let percentage = Math.floor(loaded * 100 / total)
                    if (percentage < 100) {
                        this.setState({
                            percentage: percentage
                        })
                    }
                },
                cancelToken: source.token
            }

            Axios.post(Constants.BASE_URL + `api/MarketPlace/createProject`, formData, options, config).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Project Uploaded Successfully",
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.setState({
                        isDisabled: false,
                        percentage: 100
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                percentage: 0
                            })
                        }, 1000)
                        this.props.setLink('projects')
                        this.props.history.push(Constants.MyProjects);
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Error in uploading the project.Please try again",
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.setState({
                        isDisabled: false,
                        percentage: 0
                    }, () => {
                        this.openView('isImage')
                    })
                }
            })
        })
    }

    cancelUpload = () => {
        if (source) {
            source.cancel("On click Cancel");
            this.setState({
                percentage: 0,
                isDisabled: false,

            }, () => {
                this.openView('isImage')
            })
        }
    }

    render() {
        return (
            <div className="uploadNewProject">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="row pl-3">
                    <div className="col-1">
                        <Link to={Constants.MarketPlace} onClick={() => this.props.setLink(null)}><img src={BackIcon} alt="BackIcon" className="backIcon" /><p className="backBtnText">Back</p></Link>
                    </div>
                </div>
                <div className="row uploadContainer ml-3 mr-3 p-0">
                    <div className="col-12">
                        <div className="row ">
                            <div className="col-12 text-center mt-3">
                                <p className="uploadNewTitle mt-3">Start Uploading Your Projects</p>
                            </div>
                            {this.state.isProjectDetailsView
                                ? (
                                    <div className="col-12 mt-3 text-center">
                                        <div className="row">
                                            <div className="mt-3 uploadProjectSection uploadProjectSection2 text-center col-12">
                                                <div className="row">
                                                    <div className="col-9">
                                                        <label htmlFor="projectTitle">Project Title</label>
                                                        <input type="text" id="projectTitle" onChange={(e) => this.setValue("Title", e.target.value)} />
                                                        <label htmlFor="projectTitle">Project Description</label>
                                                        <textarea rows="5" cols="42" onChange={(e) => this.setValue("Description", e.target.value)}></textarea>
                                                    </div>
                                                    <div className="col-3 pt-4">
                                                        <input id="projectFileUpload1" className="hide" type="file" accept="image/png, image/jpeg, image/jpg" onChange={this.uploadThumbnailImage} placeholder="Upload Project File" />
                                                        <label htmlFor="projectFileUpload1">Project Thumbnail <div className="thumbnailDiv"></div> </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button className="btn nextBtn" type="button" onClick={this.submitProjectdetails} disabled={this.state.isDisabled}>Upload</button>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div className="col-12 mt-3 text-center">
                                        <div className="row">
                                            <div className="offset-2 col-2 text-center">
                                                <div className="mt-5 col-12 text-center">
                                                    <input id="imageFiles" className="hide" type="file" accept="image/png, image/jpeg, image/jpg" onChange={this.uploadImages} placeholder="Upload Project Images" multiple />
                                                    <label htmlFor="imageFiles">{this.state.images.length > 0 ? (<img src={ImageUp} alt="ImageUp" />) : (<img src={ImageDown} alt="ImageDown" />)}
                                                    </label>
                                                </div>
                                                <p>Images</p>
                                            </div>
                                            <div className="col-2 text-center">
                                                <div className="mt-5 col-12 text-center">
                                                    <input id="videoFiles" className="hide" type="file" accept="video/mp4,video/x-m4v,video/*" onChange={this.uploadVideos} placeholder="Upload Project Images" multiple />
                                                    <label htmlFor="videoFiles">
                                                        {this.state.videos.length > 0 ? (<img src={VideoUp} alt="ImageUp" />) : (<img src={VideoDown} alt="ImageDown" />)}
                                                    </label>
                                                </div>
                                                <p>Videos</p>
                                            </div>
                                            <div className="col-2 text-center">
                                                <div className="mt-5 col-12 text-center">
                                                    <input id="experienceFile" className="hide" type="file" accept="*.exp" onChange={this.uploadExp} placeholder="Upload Experience File" />
                                                    <label htmlFor="experienceFile">
                                                        {this.state.experiencefile !== null ? (<img src={ExpUp} alt="ImageUp" />) : (<img src={ExpDown} alt="ImageDown" />)}
                                                    </label>
                                                </div>
                                                <p>Experience</p>
                                            </div>
                                            <div className="col-2 text-center">
                                                <div className="mt-5 col-12 text-center">
                                                    <input id="projectFileUpload" className="hide" type="file" accept="*.prj" onChange={this.uploadProject} placeholder="Upload Project File" />
                                                    <label htmlFor="projectFileUpload">
                                                        {this.state.projectFile !== null ? (<img src={ProjectUp} alt="ImageUp" />) : (<img src={ProjectDown} alt="ImageDown" />)}
                                                    </label>
                                                </div>
                                                <p>Project Files</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {this.state.images.length > 0 && this.state.experiencefile !== null && this.state.projectFile !== null && (
                                                <div className="col-12 text-center">
                                                    <button className="btn nextBtn" type="button" onClick={this.nextScreen} disabled={this.state.isDisabled}>Next</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            <div className="col-12 mt-5">
                                {this.state.percentage > 0 ? (
                                    <ProgressBar now={this.state.percentage} active={"true"} label={`${this.state.percentage}%`} />
                                ) : null}
                                <br />
                                {this.state.percentage > 0 ?
                                    <button type="button" onClick={this.cancelUpload} className="CancelRequestButton">Cancel Upload</button>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}