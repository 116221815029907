import React from 'react';
import Axios from 'axios'
import { withRouter, Route } from 'react-router-dom'
import * as Constants from '../../assets/helpers/constants'
import RightSidePanel from '../SidePanel/UserSidePanel/rightSidePanel'
import LeftSidePanel from '../SidePanel/UserSidePanel/leftSidePanel'
import Header from '../Header'
import MainDashboard from './dashboard/mainDashboard'
import LicenseDashboard from './dashboard/licenseDashboard'
import ExperienceDashboard from './dashboard/experienceDashboard'
import DeviceDashboard from './dashboard/deviceDashboard'
import SettingsDashboard from './dashboard/settingsDashboard'
import MediaManagement from './dashboard/mediaManagement'
import FileManager from './dashboard/fileManager'
import LoaderComp from '../Loader'
import Swal from 'sweetalert2'

// Admin Dashboard Pages
import AdminDashboard from './AdminDashboard/dashboard'
import AdminLeftSidePanel from '../SidePanel/AdminSidePanel/AdminLeftSidePanel'
import AdminRightSidePanel from '../SidePanel/AdminSidePanel/AdminRightSidePanel'
import AdminLicenseDashboard from './AdminDashboard/AdminLicenseDashboard';

// Market Place Pages
import MarketPlaceDashboard from './dashboard/MarketPlace/dashboard'


var myTimer;
var reminderTimerValue;
var checkCred;

class Contents extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            showBackDrop: (props.pageType === "Login" || props.pageType === "Recovery" || props.pageType === "Registration" || props.pageType === "ResetPassword") ? true : false,
            experienceList: [],
            filteredExperienceList: [],
            UserInfo: {},
            UserDetailsInfo: [],
            companyName: null,
            companyId: null,
            files: [],
            foldersFiles: [],
            filteredMMFiles: [],
            filteredMMFolders: [],
            location: null,
            selectedLicenses: [],
            selectedDevices: [],
            expName: null,
            folderName: null,
            projectInfo: {
                projectsDetails: null,
                experienceDetails: null,
                images: [],
                videos: []
            },
            fileManagerDetails: {
                jpegFiles: [],
                pngFiles: [],
                pdfFiles: [],
                xmlFiles: [],
                foldersFiles: [],
                otherFormats: [],
                files: [],
                filteredFiles: [],
                location: null,
                companyName: null
            },
            deviceDashboardInfo: {
                devicesList: null,
                isComposer: false,
                isPlayer: true,
                filteredDevices: [],
                searchDevices: [],
                deviceCompanyId: null,
                searchValue: ""
            },
            quickStats: {
                totalLicenses: 0,
                sixMonthsrenewal: 0,
                totalResellers: 0,
                totalCustomers: 0,
                threeMonthsrenewal: 0,
            },
            activeLink: null,
            licenceInfo: {
                isComposer: false,
                isPlayer: true,
                licensesList: null,
                filteredLicenses: [],
                defaultCompany: "",
                companyId: null,
                companyName: "",
                assigned: 0,
                unassigned: 0,
                expired: 0,
                searchedLicenses: [],
                searchValue: ""
            }
        }
    }

    componentDidMount() {
        if (this.props.pageType !== "Login" && this.props.pageType !== "Recovery" && this.props.pageType !== "ResetPassword" && this.props.pageType !== "Registration") {
            if (localStorage.getItem("userDetails") === null) {
                this.props.history.push('/Login');
            } else {
                let userDetails = JSON.parse(localStorage.getItem("userDetails"))
                if (userDetails[0].Role === 'Admin') {
                    this.props.history.push(Constants.AdminDashboard);
                } else {
                    if (this.props.location.pathname.includes("/Contents/") && this.props.location.pathname.length > 15) {
                        this.props.history.push(this.props.location.pathname);
                    }
                }
                this.setState({
                    UserInfo: userDetails[0],
                    UserDetailsInfo: userDetails
                }, () => {
                    if (this.props.location.pathname === "/Admin" || (this.props.location.pathname === "/Admin/")) {
                        this.props.history.push('/Admin/DashBoard');
                    }
                    if (this.props.location.pathname === "/Contents" || (this.props.location.pathname === "/Contents/" && this.props.location.pathname.length === 10)) {
                        this.props.history.push('/Contents/DashBoard');
                    }
                })
            }
        }
        this.checkPath()
    }

    componentWillUnmount() {
        clearTimeout(myTimer);
        clearTimeout(reminderTimerValue);
    }

    inactivityTime = () => {
        var logoutTimer = 1800000
        var remainderTimer = 1500000
        var credentialsCheckTimer = 15000
        window.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onkeydown = resetTimer;
        document.onkeyup = resetTimer;

        function resetTimer() {
            if (localStorage.getItem("userDetails") !== null) {
                clearTimeout(myTimer);
                clearTimeout(reminderTimerValue);
                // myTimer = setTimeout(logout, 1800000);// 30 minutes it will log out the session
                // reminderTimerValue = setTimeout(reminderTimer, 1500000); // 25 min it will trigger popup
                myTimer = setTimeout(logout, logoutTimer);// 20 sec it will log out the session 
                reminderTimerValue = setTimeout(reminderTimer, remainderTimer); // 15s it will trigger popup
                checkCred = setTimeout(checkUserCred, credentialsCheckTimer);
            }

        }

        function checkUserCred() {
            if (localStorage.getItem("userDetails") === null) {
                if (!(window.location.href.includes(Constants.Login)) && !(window.location.href.includes(Constants.Recovery)) && !(window.location.href.includes(Constants.ResetPassword)) && !(window.location.href.includes(Constants.PartnerRegistration))) {
                    window.location.href = window.location.origin
                }
            }
        }

        function reminderTimer() {
            Swal.fire({
                title: 'User Session will end in 5 minutes if no activity is performed ',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    resetTimer()
                }
            })
        }

        function logout() {
            clearTimeout(myTimer);
            clearTimeout(reminderTimerValue);
            clearTimeout(checkCred);
            localStorage.removeItem('userDetails')
            localStorage.removeItem('rememberme')
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Logging Out User. Please login again !!',
                showConfirmButton: false,
                timer: 2000
            }).then((result) => {
                window.location.href = window.location.origin
            })

        }
    };

    checkPath = (id = null) => {
        this.setState({
            activeLink: id
        }, () => {
            let activeLink = ""
            if (this.props.location.pathname.includes(Constants.ExperienceDashboard) || this.props.location.pathname.includes(Constants.MediaManagement) || this.props.location.pathname.includes(Constants.AddExperience) || this.props.location.pathname.includes(Constants.DeviceList)) {
                activeLink = 'experience'
            } else if (this.props.location.pathname.includes(Constants.LicenseDashboard) || this.props.location.pathname.includes(Constants.LicenseDetails) || this.props.location.pathname.includes(Constants.BuyLicenses)) {
                activeLink = 'license'
            } else if (this.props.location.pathname.includes(Constants.DeviceDashboard) || this.props.location.pathname.includes(Constants.IndividualDeviceDetails)) {
                activeLink = 'device'
            } else if (this.props.location.pathname.includes(Constants.SettingsDashboard) || this.props.location.pathname.includes(Constants.SettingsUserManagement) || this.props.location.pathname.includes(Constants.SettingsEmailConfig) || this.props.location.pathname.includes(Constants.SettingsUsageReports) || this.props.location.pathname.includes(Constants.SettingsSoftwareRelease) || this.props.location.pathname.includes(Constants.SettingsChangePassword)) {
                activeLink = 'settings'
            } else if (this.props.location.pathname.includes(Constants.FileManager) || this.props.location.pathname.includes(Constants.PreviewFile) || this.props.location.pathname.includes(Constants.UploadFiles)) {
                activeLink = 'filemanager'
            } else if (this.props.location.pathname.includes(Constants.MarketPlace)) {
                activeLink = 'marketplace'
            } else {
                activeLink = 'dashboard'
            }
            this.setState({
                activeLink: activeLink
            })
        })
    }

    getExperiences = (company = this.state.companyName + "_" + this.state.companyId) => {
        this.setState({
            isLoading: true,
            experienceList: [],
            filteredExperienceList: []
        }, () => {
            var x = company.split("_")
            Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getExperiencs/${x[1]}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        experienceList: result.data.info,
                        filteredExperienceList: result.data.info,
                        companyName: x[0],
                        companyId: x[1],
                        isLoading: false
                    })
                } else {
                    this.setState({
                        experienceList: [],
                        filteredExperienceList: [],
                        companyName: x[0],
                        companyId: x[1],
                        isLoading: false
                    })
                }
            })
        })

    }

    searchExperiences = (value) => {
        let experiences = this.state.experienceList
        let newArray = []
        newArray = experiences.filter(function (item) {
            return item.ExperienceName.toLowerCase().includes(value.toLowerCase());
        });
        this.setState({
            filteredExperienceList: newArray
        })
    }

    getFileDetails = (folderName = this.state.folderName, expName = this.state.expName, companyName = this.state.companyName) => {
        this.setState({
            isLoading: true
        }, () => {
            let data = {
                expName: expName,
                location: "/" + folderName,
                companyName: companyName
            }

            if (folderName === "others") {
                Axios.post(Constants.BASE_URL + 'api/mediaManagement/getFileDetailsofMedia', data).then((result) => {
                    if (result.data.status && result.data.info.length > 0) {
                        this.setState({
                            files: result.data.info,
                            filteredMMFiles: result.data.info,
                            filteredFiles: result.data.info,
                            expName: expName,
                            folderName: folderName,
                            isLoading: false,
                            foldersFiles: [],
                            filteredMMFolders: []
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                        })
                    }
                })
            } else {
                Axios.post(Constants.BASE_URL + 'api/mediaManagement/getFileDetailsofFolder', data).then((result) => {
                    if (result.data.status && result.data.info.length > 0) {
                        this.setState({
                            files: result.data.info,
                            filteredMMFiles: result.data.info,
                            filteredFiles: result.data.info,
                            expName: expName,
                            folderName: folderName,
                            isLoading: false,
                            foldersFiles: [],
                            filteredMMFolders: []
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                        })
                    }
                })
            }
        })
    }

    filterMediaFiles = (value) => {
        let existingFilesArray = this.state.files
        let existingFoldersArray = this.state.foldersFiles
        let newFilesArray = []
        let newFoldersArray = []
        newFilesArray = existingFilesArray.filter(function (item) {
            return item.Name.toLowerCase().includes(value.toLowerCase());
        });
        newFoldersArray = existingFoldersArray.filter(function (item) {
            return item.Name.toLowerCase().includes(value.toLowerCase());
        });
        this.setState({
            filteredMMFiles: newFilesArray,
            filteredMMFolders: newFoldersArray
        })
    }

    getFolderDetails = (location = this.state.location) => {
        this.setState({
            isLoading: true
        }, () => {
            let data = {
                "location": location
            }
            Axios.post(Constants.BASE_URL + 'api/mediaManagement/getMediaofExperience', data).then((result) => {
                if (result.data.status) {
                    let response = result.data.info
                    this.setState({
                        foldersFiles: response,
                        filteredMMFolders: response,
                        files: [],
                        filteredMMFiles: [],
                        location: location,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        foldersFiles: [],
                        filteredMMFolders: [],
                        files: [],
                        filteredMMFiles: []
                    })
                }
            })
        })

    }

    getDevices = (CompanyName, CompanyId) => {
        this.setState((prevState) => ({
            selectedDevices: [],
            isLoading: true,
            deviceDashboardInfo: {
                ...prevState.deviceDashboardInfo,
                devicesList: null,
                inactiveDevices: 0,
                activeDevices: 0,
                deviceCompanyId: CompanyId,
                filteredDevices: [],
                searchDevices: [],
                searchValue: ""
            }
        }), () => {
            Axios.get(Constants.BASE_URL + `api/DeviceManagement/getDevices/${CompanyId}`).then((result) => {
                if (result.data.status) {
                    var activeDevices = result.data.info.filter((devices) => {
                        return devices.deviceStatus === 1
                    })
                    var inactiveDevices = result.data.info.filter((devices) => {
                        return devices.deviceStatus !== 1
                    })
                    this.setState((prevState) => ({
                        deviceDashboardInfo: {
                            ...prevState.deviceDashboardInfo,

                            devicesList: result.data.info,
                            inactiveDevices: inactiveDevices.length,
                            activeDevices: activeDevices.length,
                            filteredDevices: result.data.info,
                            searchDevices: result.data.info
                        },
                        isLoading: false
                    }), () => {
                        this.changeDevices("Player")
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        deviceDashboardInfo: {
                            devicesList: [],
                            filteredDevices: [],
                            searchDevices: [],
                            inactiveDevices: 0,
                            activeDevices: 0,
                            deviceCompanyId: null
                        },
                    })
                }
            })
        })
    }

    selectDevices = (details) => {
        if (details.selectedCount > 0) {
            var array = [];
            details.selectedRows.forEach(element => {
                let id = element.deviceId
                var obj = {
                    id: id,
                    companyId: this.state.deviceDashboardInfo.deviceCompanyId,
                    type: element.deviceType
                }
                var index = array.findIndex(x => x.id === id)
                if (index !== -1) {
                    array.splice(index, 1);
                } else {
                    array.push(obj);
                }
            })

            this.setState({
                selectedDevices: array
            }, () => {
                if (array.length > 0) {
                    let stringifiedArray = JSON.stringify(array)
                    this.props.history.push(Constants.IndividualDeviceDetails + "/" + stringifiedArray)
                } else {
                    this.props.history.push(Constants.DeviceDashboard)
                }
            });
        } else {
            this.setState({
                selectedDevices: []
            }, () => {
                this.props.history.push(Constants.DeviceDashboard)
            });
        }
    }

    changeDevices = (key) => {
        this.setState(prevState => ({
            selectDevices: [],
            deviceDashboardInfo: {
                ...prevState.deviceDashboardInfo,
                filteredDevices: [],
                searchDevices: []
            }
        }), () => {
            let licenses = this.state.deviceDashboardInfo.devicesList
            let newArray = []
            newArray = licenses.filter(function (item) {
                return item.deviceType === key;
            });

            this.setState(prevStateValue => ({
                deviceDashboardInfo: {
                    ...prevStateValue.deviceDashboardInfo,
                    filteredDevices: newArray,
                    searchDevices: newArray,
                    isComposer: key === "Composer" ? true : false,
                    isPlayer: key === "Player" ? true : false,
                    searchValue: ""
                }
            }))
            this.props.history.push(Constants.DeviceDashboard)
        })
    }

    searchDevices = (value) => {
        let licenses = this.state.deviceDashboardInfo.filteredDevices
        let newArray = []
        newArray = licenses.filter(function (item) {
            return item.deviceName.toLowerCase().includes(value.toLowerCase());
        });
        this.setState(prevState => ({
            deviceDashboardInfo: {
                ...prevState.deviceDashboardInfo,
                searchDevices: newArray,
                searchValue: value
            }
        })
        )
    }

    selectedLicense = (details) => {
        if (details.selectedCount > 0) {
            var array = [];
            details.selectedRows.forEach(element => {
                let id = element.Id
                let type = element.License_Type
                var obj = {
                    id: id,
                    type: type,
                    companyId: this.state.licenceInfo.companyId
                }
                var index = array.findIndex(x => x.id === id)
                if (index !== -1) {
                    array.splice(index, 1);
                } else {
                    array.push(obj);
                }
                this.setState({
                    selectedLicenses: array
                }, () => {
                    if (array.length > 0) {
                        let stringifiedArray = JSON.stringify(array)
                        this.props.history.push(Constants.LicenseDetails + "/" + stringifiedArray);
                    } else {
                        this.props.history.push(Constants.LicenseDashboard)
                    }
                });
            });
        } else {
            this.setState({
                selectedLicenses: []
            }, () => {
                this.props.history.push(Constants.LicenseDashboard)
            });
        }
    }

    getLicenses = (value) => {
        this.setState(prevState => ({
            selectedLicenses: [],
            licenceInfo: {
                licensesList: null,
                filteredLicenses: [],
                searchLicenses: [],
                defaultCompany: null,
                companyId: null,
                companyName: null,
                assigned: 0,
                unassigned: 0,
                expired: 0,
            }
        }), () => {
            var x = value.split("_")
            Axios.get(Constants.BASE_URL + `api/LicenseManagement/getLicenseList/${x[1]}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        licenceInfo: {
                            licensesList: result.data.info.licensesList,
                            filteredLicenses: result.data.info.licensesList,
                            searchLicenses: result.data.info.licensesList,
                            defaultCompany: value,
                            companyId: x[1],
                            companyName: x[0],
                            assigned: result.data.info.assigned,
                            unassigned: result.data.info.unassigned,
                            expired: result.data.info.expired,
                        }
                    }, () => {
                        this.changeLicenses("Player")
                    })
                }
            })
        })
    }

    searchLicenses = (value) => {
        let licenses = this.state.licenceInfo.filteredLicenses
        let newArray = []
        newArray = licenses.filter(function (item) {
            return item.licenseKey.toLowerCase().includes(value.toLowerCase());
        });
        this.setState(prevState => ({
            selectedLicenses: [],
            licenceInfo: {
                ...prevState.licenceInfo,
                searchLicenses: newArray,
                searchValue: value
            }
        })
        )
    }

    changeLicenses = (key) => {
        this.setState(prevState => ({
            selectedLicenses: [],
            licenceInfo: {
                ...prevState.licenceInfo,
                filteredLicenses: [],
                searchLicenses: [],
                searchValue: ""
            }
        }), () => {
            let licenses = this.state.licenceInfo.licensesList
            let newArray = []
            newArray = licenses.filter(function (item) {
                return item.License_Type === key;
            });

            this.setState(prevStateValue => ({
                licenceInfo: {
                    ...prevStateValue.licenceInfo,
                    filteredLicenses: newArray,
                    searchLicenses: newArray,
                    isComposer: key === "Composer" ? true : false,
                    isPlayer: key === "Player" ? true : false
                }
            }))
            this.props.history.push(Constants.LicenseDashboard)
        })
    }

    getFileManagerFolderDetails = (location = this.state.location) => {
        this.setState({
            isLoading: true,
            fileManagerDetails: {
                files: [],
                filteredFiles: []
            }
        }, () => {
            let data = {
                "location": location
            }
            Axios.post(Constants.BASE_URL + 'api/mediaManagement/getMediaofCompany', data).then((result) => {
                if (result.data.status) {
                    let response = result.data.info
                    this.setState({
                        fileManagerDetails: {
                            files: response,
                            filteredFiles: response,
                            location: location
                        },
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        files: [],
                        filteredFiles: [],
                    })
                }
            })
        })
    }

    searchMedia = (value) => {
        let intialFiles = this.state.fileManagerDetails.files
        let latestFiles = this.state.fileManagerDetails.files

        if (value !== "") {
            latestFiles = intialFiles.filter((media) => {
                return media.name.toLowerCase().includes(value.toLowerCase())
            })
        }
        this.setState(prevState => ({
            fileManagerDetails: {
                ...prevState.fileManagerDetails,
                filteredFiles: latestFiles
            }
        }))
    }

    // Admin Dashboard Funcitons 
    getQuickStats = () => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/AdminDashboard/getQuickStats`).then((result) => {
                const data = result.data.info
                this.setState({
                    quickStats: {
                        totalLicenses: data.totalLicenses,
                        sixMonthsrenewal: data.sixMonthsrenewal,
                        totalResellers: data.totalResellers,
                        totalCustomers: data.totalCustomers,
                        threeMonthsrenewal: data.threeMonthsrenewal,
                        isLoading: false
                    }
                })
            })
        })
        this.setState({
            isLoading: false
        })
    }

    getProjectDetails = () => {
        let projectId = null
        let pathDetails = this.props.location.pathname.split("/")

        let userDetails = JSON.parse(localStorage.getItem("userDetails"))
        let userId = userDetails[0].UserID

        if (this.props.location.pathname.includes(Constants.Discover)) {
            projectId = pathDetails[pathDetails.length - 2]
        } else {
            projectId = pathDetails[pathDetails.length - 1]
        }
        Axios.get(Constants.BASE_URL + `api/MarketPlace/getProjectDetails/${projectId}/${userId}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    projectInfo: {
                        projectsDetails: result.data.info.projectInfo[0] !== undefined ? result.data.info.projectInfo[0] : null,
                        experienceDetails: result.data.info.experienceDetails[0] !== undefined ? result.data.info.experienceDetails[0] : null,
                        images: result.data.info.images,
                        videos: result.data.info.videos
                    }
                })
            } else {
                this.setState({
                    projectInfo: {
                        projectsDetails: null,
                        experienceDetails: null,
                        images: [],
                        videos: []
                    }
                })
            }
        })
    }

    incrementViews = (id) => {
        Axios.get(Constants.BASE_URL + `api/MarketPlace/incrementViews/${id}`)
    }

    getMediaFromDb = (companyName, experienceName) => {
        Axios.get(Constants.BASE_URL + `api/mediaManagement/getFolderDetailsFromDB/${companyName}/${experienceName}`).then((result) => {
            if (result.data.status) {
                let response = result.data.info
                this.setState({
                    foldersFiles: response,
                    filteredMMFolders: response,
                    files: [],
                    filteredMMFiles: [],
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false,
                    foldersFiles: [],
                    filteredMMFolders: [],
                    files: [],
                    filteredMMFiles: []
                })
            }
        })

    }

    render() {
        return (
            <div className="contents" >
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                < div className="container-fluid" >
                    {
                        this.state.showBackDrop ? (
                            <div className="row">
                                <div className="col-12">
                                    <div className="backdropDiv"></div>
                                </div>
                            </div>
                        ) : null
                    }

                    {this.state.UserInfo.Role !== "Admin" ?
                        (
                            <div className="row userDashboard">
                                <div className="col-md-9 p-0">
                                    <LeftSidePanel checkPath={this.checkPath} activeLink={this.state.activeLink} UserInfo={this.state.UserInfo} closeFunc={this.closeLeftSidePanels} {...this.props} />
                                    <div className="middleContent pr-4">
                                        <Header UserInfo={this.state.UserInfo} {...this.props} />
                                        <Route exact path={Constants.MainDashboard} component={() => <MainDashboard UserInfo={this.state.UserInfo} {...this.props} />} />
                                        <Route path={Constants.ExperienceDashboard} render={() => <ExperienceDashboard UserInfo={this.state.UserInfo} searchExperiences={this.searchExperiences} filteredExperienceList={this.state.filteredExperienceList} experienceList={this.state.experienceList} getExperiences={this.getExperiences} {...this.props} />} />
                                        <Route path={Constants.MediaManagement} render={() => <MediaManagement getMediaFromDb={this.getMediaFromDb} UserInfo={this.state.UserInfo} filterMediaFiles={this.filterMediaFiles} filteredMMFolders={this.state.filteredMMFolders} filteredMMFiles={this.state.filteredMMFiles} foldersFiles={this.state.foldersFiles} getFolderDetails={this.getFolderDetails} getFileDetails={this.getFileDetails} filesList={this.state.files} {...this.props} />} />
                                        <Route path={Constants.LicenseDashboard} render={() => <LicenseDashboard searchLicenses={this.searchLicenses} selectedLicense={this.selectedLicense} changeLicenses={this.changeLicenses} getLicenses={this.getLicenses} licenceInfo={this.state.licenceInfo} UserInfo={this.state.UserInfo} {...this.props} />} />
                                        <Route path={Constants.DeviceDashboard} render={() => <DeviceDashboard searchDevices={this.searchDevices} changeDevices={this.changeDevices} deviceDashboardInfo={this.state.deviceDashboardInfo} selectDevices={this.selectDevices} UserInfo={this.state.UserInfo} getDevices={this.getDevices} {...this.props} />} />
                                        <Route path={Constants.SettingsDashboard} render={() => <SettingsDashboard UserInfo={this.state.UserInfo} {...this.props} />} />
                                        <Route path={Constants.FileManager} render={() => <FileManager searchMedia={this.searchMedia} UserInfo={this.state.UserInfo} details={this.state.fileManagerDetails} getFileManagerFolderDetails={this.getFileManagerFolderDetails} filesList={this.state.files} {...this.props} />} />

                                        {/* Market Place Routes */}
                                        <Route path={Constants.MarketPlace} render={() => <MarketPlaceDashboard UserInfo={this.state.UserInfo} incrementViews={this.incrementViews} projectInfo={this.state.projectInfo} getProjectDetails={this.getProjectDetails} {...this.props} />} />
                                    </div>
                                </div>
                                <div className="col-md-3 wrapper p-0">
                                    <RightSidePanel inactivityTime={this.inactivityTime} UserDetailsInfo={this.state.UserDetailsInfo} incrementViews={this.incrementViews} getProjectDetails={this.getProjectDetails} getLicenses={this.getLicenses} licenceInfo={this.state.licenceInfo} checkPath={this.checkPath} UserInfo={this.state.UserInfo} getDevices={this.getDevices} getFileDetails={() => this.getFileDetails()} companyName={this.state.companyName} experienceList={this.state.experienceList} filteredExperienceList={this.state.filteredExperienceList} getExperiences={this.getExperiences} getFileManagerFolderDetails={this.getFileManagerFolderDetails} {...this.props} />
                                </div>
                            </div>
                        ) :
                        (
                            <div className="row adminDashboard">
                                <div className="col-md-9 p-0">
                                    <AdminLeftSidePanel closeFunc={this.closeLeftSidePanels} {...this.props} UserInfo={this.state.UserInfo} />
                                    <div className="middleContent pr-4">
                                        <Header UserInfo={this.state.UserInfo} {...this.props} />
                                        <Route path={Constants.AdminDashboard} render={() => <AdminDashboard getQuickStats={this.getQuickStats} quickStats={this.state.quickStats} UserInfo={this.state.UserInfo} {...this.props} />} />
                                        <Route path={Constants.AdminLicenseDashboard} render={() => <AdminLicenseDashboard UserInfo={this.state.UserInfo} {...this.props} />} />
                                    </div>
                                </div>
                                <div className="col-md-3 wrapper p-0">
                                    <AdminRightSidePanel inactivityTime={this.inactivityTime} UserInfo={this.state.UserInfo} getLicenses={this.getLicenses} getQuickStats={this.getQuickStats} {...this.props} />
                                </div>
                            </div>)
                    }
                </div >
            </div >
        )
    }
}

export default withRouter(Contents)