import React from 'react'
import Axios from 'axios';
import * as Constants from '../../../assets/helpers/constants'
import ProfileDup from '../../../assets/images/Group 1045.svg'
import '../../../assets/css/MarketPlace.css'
import Swal from 'sweetalert2'

class DownloadComposerView extends React.Component {
    constructor() {
        super()
        this.state = {
            isFirstpage: true,
            profilePic: null,
            mobileNo: null,
            address: "",
            country: "",
            state: "",
            jobTitle: "",
            designation: "",
            creatingFor: "",
            whichIndustry: "",
            isClientProject: "",
            aboutUs: ""
        }
    }

    componentDidMount() {
        if (this.props.UserInfo === undefined) {
            this.props.history.push(Constants.Login)
        }
    }

    setValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    setImage = (key, value) => {
        this.setState({
            [key]: value
        }, () => {
            var reader = new FileReader();
            let fileDetails = value
            const sourceDiv = document.getElementById("preview");
            sourceDiv.innerHTML = ""
            if (fileDetails.type === 'image/png' || fileDetails.type === 'image/jpg' || fileDetails.type === 'image/jpeg') {
                reader.onload = function (e) {
                    var img = document.createElement("img");
                    img.title = fileDetails.name;
                    img.src = e.target.result;
                    sourceDiv.appendChild(img);
                };
                reader.readAsDataURL(value);
            } else {

            }
        })

    }

    changeView = () => {
        this.setState({
            isFirstpage: false
        })
    }

    submitDetails = () => {
        let data = new FormData()
        data.append("profilePic", this.state.profilePic)
        data.append("mobileNo", this.state.mobileNo)
        data.append("address", this.state.address)
        data.append("country", this.state.country)
        data.append("state", this.state.state)
        data.append("jobTitle", this.state.jobTitle)
        data.append("designation", this.state.designation)
        data.append("creatingFor", this.state.creatingFor)
        data.append("whichIndustry", this.state.whichIndustry)
        data.append("isClientProject", this.state.isClientProject)
        data.append("aboutUs", this.state.aboutUs)
        data.append("userId", this.props.UserInfo.UserID)

        Axios.post(Constants.BASE_URL + `api/MarketPlace/submitUserDetails`, data).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Sucessfully added the profile',
                    showConfirmButton: false,
                    timer: 1000
                })
                window.open(
                    result.data.link,
                    '_blank' // <- This is what makes it open in a new window.
                );
                this.props.history.push(Constants.MarketPlace)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Unable to fetch pricing details. Please contact Admin',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="row downloadComposerView">
                    <div className="titleDiv col-12">
                        <p className="title">SIGN IN</p>
                        {this.state.isFirstpage ? (<p className="subTitle">Let us know more about you</p>) : (<p className="subTitle">Just few steps and your ready to go.</p>)}
                    </div>
                    {this.state.isFirstpage ? (
                        <>
                            <div className="col-12 text-center mt-4">
                                <input type="file" id="imgupload" onChange={(e) => this.setImage("profilePic", e.target.files[0])} />
                                {this.state.profilePic ? <label htmlFor='imgupload'> <div id="preview" htmlFor='imgupload'></div></label> :
                                    <div>
                                        <label htmlFor='imgupload'>  <img src={ProfileDup} alt="profilePic" /></label>
                                        <p className="uplaodProfilePic mt-1">Upload Profile picture</p>
                                    </div>}
                            </div>
                            <div className="col-12">
                                <input type="number" placeholder="Mobile" onChange={(e) => this.setValue("mobileNo", e.target.value)} />
                                <textarea placeholder="Address" rows="3" cols="40" onChange={(e) => this.setValue("address", e.target.value)}></textarea>
                                <input type="text" placeholder="Country" onChange={(e) => this.setValue("country", e.target.value)} />
                                <input type="text" placeholder="State" onChange={(e) => this.setValue("state", e.target.value)} />
                                <input type="text" placeholder="Job title" onChange={(e) => this.setValue("jobTitle", e.target.value)} />
                                <input type="text" placeholder="Designation" onChange={(e) => this.setValue("designation", e.target.value)} />
                                <button type="button" className="nextBtn" onClick={this.changeView}>Next</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-12  mt-4">
                                <input type="text" placeholder="What are you creating?" onChange={(e) => this.setValue("creatingFor", e.target.value)} />
                                <input type="text" placeholder="Which Industry?" onChange={(e) => this.setValue("whichIndustry", e.target.value)} />
                                <input type="text" placeholder="Is client project?" onChange={(e) => this.setValue("isClientProject", e.target.value)} />
                                <input type="text" placeholder="Where did you hear about us?" onChange={(e) => this.setValue("aboutUs", e.target.value)} />
                                <button type="button" className="nextBtn" onClick={this.submitDetails}>Done</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default DownloadComposerView